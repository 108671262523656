import React, { Component } from 'react';

import { connect } from 'react-redux';

import "./academyCourse.scss";

export class AcademyCourseClass extends Component {

    render() {

        let { acadamyDetail } = this.props

        let {
            noOfAuthor = 0,
            noOfCertificatesIssued = 0,
            noOfCertifyingInstitute = 0,
            noOfUsers = 0,
            noOfFpoUser = 0,
            viewedIn24hrs = 0
        } = acadamyDetail

        return (
            <div className="card academyCourse">
                <div className="card-body">
                    <h5 className="card-title fs-24 font-Gilroy-Bold  text-black-18">Academy</h5>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-success">
                                <label className="card-heading d-flex">Number of users who viewed the course</label>
                                <label className="count m-0">{noOfUsers}</label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="alert alert-success">
                                <label className="card-heading d-flex">Number of users from FPO</label>
                                <label className="count m-0">{noOfFpoUser}</label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="alert alert-success">
                                <label className="card-heading d-flex">Number of course viewers in last 24 hours</label>
                                <label className="count m-0">{viewedIn24hrs}</label>
                            </div>
                        </div>
                    </div>
                    <h5 className="card-title fs-24 font-Gilroy-Bold  text-black-18">Course</h5>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-success">
                                <label className="card-heading d-flex">Number of certificates issued</label>
                                <label className="count m-0">{noOfCertificatesIssued}</label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="alert alert-success">
                                <label className="card-heading d-flex">Number of certifiying institutes </label>
                                <label className="count m-0">{noOfCertifyingInstitute}</label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="alert alert-success">
                                <label className="card-heading d-flex">Number of authors</label>
                                <label className="count m-0">{noOfAuthor}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export const AcademyCourse = connect(null, null)(AcademyCourseClass)