import React, { Component } from "react";
import "./style.scss";

import { NormalButton } from "component/common";

import SimpleReactValidator from "simple-react-validator";

// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fileUpload } from "action/CourseAct";

import {
  downloadGradingToolExcel,
  uploadGradingToolExcel,
} from "action/FPOact";

const upload = require("assets/svg/upload.svg");

export class GradingUploadClass extends Component {
  state = {
    csvUrl: "",
    gradingfile: "",
    fileName: "",
    isLoading: false,
  };

  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleGradingFile = (e) => {
    //upload grading file
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ gradingfile: "", fileName: "" }, () => {
        let { gradingfile, fileName } = this.state;
        gradingfile = file;
        fileName = file.name;
        this.setState({
          gradingfile,
          fileName,
        });
      });
    }
  };

  //Lifecycles
  componentDidMount() {
    //Get upload file
    this.props.downloadGradingToolExcel().then(({ data }) => {
      this.setState({
        csvUrl: data,
      });
    });
  }

  handleSubmit = () => {
    if (this.validator.allValid()) {
      let { gradingfile } = this.state;

      const formData = new FormData();
      formData.append("file", gradingfile);
      this.setState({ isLoading: true });
      this.props
        .uploadGradingToolExcel(formData)
        .then(() => {
          this.setState({
            isLoading: false,
            fileName: "",
            gradingfile: "",
          });
          this.validator.hideMessages();
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { csvUrl, gradingfile, fileName, isLoading } = this.state;

    return (
      <div className="add-grading-area">
        <div className="add-grading">
          <div className="container">
            <div className="add-grading-form-container pt-4">
              <div>
                <p className="fs-16 line-height-37 text-black">
                  Please download excel template for Grading Tool {""}
                  <a
                    className="text-green-18 fs-16 line-height-37 cursor-pointer text-underline"
                    href={csvUrl}
                    download
                  >
                    load Here
                  </a>
                </p>
                <p className="fs-16 line-height-37 text-black">
                  {
                    "After you fill up the template, please upload the file below. Please do not change any column names"
                  }
                </p>
              </div>
              <div className="d-flex justify-content-center pt-5 mt-2 pb-4">
                <div>
                  <div className="fs-16 line-height-19 text-black-32 mb-2 pb-1 font-weight-bold">
                    Excel upload
                  </div>
                  <div className="add-grading-container d-flex justify-content-center align-items-center">
                    <input
                      type="file"
                      accept=".xlsx"
                      name="gradingfile"
                      onChange={this.handleGradingFile}
                    />
                    {gradingfile !== "" ? (
                      <> {fileName} </>
                    ) : (
                      <>
                        <img className="mr-2" src={upload} alt="upload" />
                        {"Upload files"}
                      </>
                    )}
                  </div>
                  {this.validator.message(
                    "grading file",
                    gradingfile,
                    "required",
                    { className: "text-danger" }
                  )}
                </div>
              </div>
              <div className="mx-auto w-100 mt-5">
                {isLoading ? (
                  <button className="btn mx-auto fs-14 line-height-16 mainbg-btn">
                    {"Uploading"}
                    <div className="spinner-border ml-2 spinner-border-sm">
                      {" "}
                    </div>
                  </button>
                ) : (
                  <NormalButton
                    label="Upload"
                    mainbg={true}
                    className="mx-auto fs-14 line-height-16"
                    onClick={() => this.handleSubmit()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
      downloadGradingToolExcel,
      uploadGradingToolExcel,
    },
    dispatch
  );
};

export const GradingUpload = connect(
  null,
  mapDispatchToProps
)(GradingUploadClass);
