import React, { Component, Fragment } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import RoboCall from "./RoboCall";
import { SmsHistory } from "./Sms";

class RoboCallAndSms extends Component {
  state = {
    activeTab: "1",
  };
  toggle = (tab) => {
    let { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    let { activeTab } = this.state;
    return (
      <Fragment>
        <div className="common-navlink">
          <div className="filled-tabs">
            <div className="tabs-block">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Robocall
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    SMS
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <RoboCall />
          </TabPane>
          <TabPane tabId="2">
            <SmsHistory />
          </TabPane>
        </TabContent>
      </Fragment>
    );
  }
}

export default RoboCallAndSms;
