import React, { Component } from "react";
import { ComplianceCalenderAdd } from "component/ComplianceCalender";
import { connect } from "react-redux";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";
class AddTaskInCalenderClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <ComplianceCalenderAdd id={this.props.match.params.id} />;
  }
}

export const AddTaskInCalender = connect(null, null)(AddTaskInCalenderClass);
