import React, { Component } from "react";
import "./navbar.scss";
import { logout } from "service/utilities";
import nav_user from "assets/images/default-profile-img.png";
import { history } from "service/helpers";
export class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      search: "none",
      active: false,
    };
  }

  //handle dropdown
  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener(
        "click",
        this.handleOutsideClickForProfile,
        false
      );
    } else {
      document.removeEventListener(
        "click",
        this.handleOutsideClickForProfile,
        false
      );
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  //handle dropdown when clicked outside
  handleOutsideClickForProfile = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  //handle logout
  handleLogout = () => {
    logout();
  };

  render() {
    let { active } = this.state;

    return (
      <>
        <div className="navbar fixed-top p-3">
          <div className="d-flex justify-content-end w-100 align-items-center">
            <div className="search-field">
              <input
                className="form-control "
                type="text"
                placeholder="search..."
              />
            </div>
            {/* <div className="mr-4 pr-3">
              <i className="icon-search fs-20"></i>
            </div>
            <div className="mr-4 pr-3">
              <i className="icon-bell fs-20"></i>
            </div> */}
            <div
              className="cursor-pointer profile profile-align profile-dropdown mr-3"
              onClick={this.handleDropdown}
            >
              <img
                className="rounded-circle profile-image"
                src={nav_user}
                alt="user"
              />
              <ul className={`dropdown ${active ? "active" : ""}`}>
                <li
                  onClick={() => history.push("/admin/change-password")}
                  className="cursor-pointer"
                >
                  {"Change password"}
                </li>
                <li
                  onClick={() => this.handleLogout()}
                  className="cursor-pointer"
                >
                  {"Logout"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
