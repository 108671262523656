import React, { Component } from "react";
import { connect } from "react-redux";
import { CommonPageLoader } from "component/common";
import { Modal, ModalBody } from "reactstrap";
import { getFilePathDownload } from "service/api";
import moment from "moment";

export class ViewBodPopupClass extends Component {
  render() {
    let {
      className = "modal-dialog-centered fpo-popups",
      isOpen = true,
      toggle,
      isLoading = false,
      bodDetail,
    } = this.props;
    let {
      profileImageUrl = "",
      firstName = "",
      lastName = "",
      gender = "",
      dob = "",
      mobileNumber = "",
      hasSmartPhone = "",
      AppointedOn = "",
      din = "",
      educationmaster = {},
      address = "",
      identityProofName = "",
      addressProofName = "",
      addressProofUrl = "",
      identityProofUrl = "",
    } = bodDetail;

    let { qualificationType = "" } = educationmaster ? educationmaster : {};

    return (
      <>
        <Modal isOpen={isOpen} toggle={toggle} className={className} size="lg">
          <ModalBody className="p-0">
            <div className="d-flex justify-content-between pb-2">
              <h5 className="px-0 pb-1 text-green-18 fs-26 font-Gilroy-Bold">
                BOD Details
              </h5>
              <i
                className="icon-close fs-20 cursor-pointer"
                onClick={toggle}
              ></i>
            </div>
            {!isLoading ? (
              <div className="py-4 px-3">
                <div className="row">
                  <div className="col-3 mx-auto">
                    <div className="profile-area">
                      <div className="profile-pic">
                        <img src={profileImageUrl} className="shadow" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Name :"}
                        </p>
                      </div>
                      <div className="col-8">
                        <p className="text-black-50 fs-16">
                          {firstName} {lastName}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Gender :"}
                        </p>
                      </div>
                      <div className="col-8">
                        <p className="text-black-50 fs-16">{gender}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"DOB :"}
                        </p>
                      </div>
                      <div className="col-8">
                        <p className="text-black-50 fs-16">
                          {moment(dob).format("DD MMMM YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Phone :"}
                        </p>
                      </div>
                      <div className="col-8">
                        <p className="text-black-50 fs-16">{mobileNumber}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Smart Phone :"}
                        </p>
                      </div>
                      <div className="col-8">
                        <p className="text-black-50 fs-16">
                          {hasSmartPhone === true ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Education{" "}
                          <span className="text-nowrap">
                            {"qualification :"}
                          </span>
                        </p>
                      </div>
                      <div className="col-8">
                        <p className="text-black-50 fs-16">
                          {qualificationType}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"DIN :"}
                        </p>
                      </div>
                      <div className="col-8">
                        <p className="text-black-50 fs-16">{din}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Address :{" "}
                        </p>
                      </div>
                      <div className="col-8">
                        <p className="text-black-50 fs-16">{address}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Identity proof :"}
                        </p>
                      </div>
                      <div className="col-8">
                        <a
                          className="fs-16 text-green-18 text-underline cursor-pointer mb-3"
                          href={
                            identityProofName &&
                            getFilePathDownload + identityProofUrl
                          }
                          download
                        >
                          {"Download"}
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {" Address proof :"}
                        </p>
                      </div>
                      <div className="col-8">
                        <a
                          className="fs-16 text-green-18 text-underline cursor-pointer mb-3"
                          href={
                            addressProofName &&
                            getFilePathDownload + addressProofUrl
                          }
                          download
                        >
                          {"Download"}
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Appointed on :"}
                        </p>
                      </div>
                      <div className="col-8">
                        <p className="text-black-50 fs-16">
                          {moment(AppointedOn).format("DD MMMM YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/* Loader */}
                <CommonPageLoader />
              </>
            )}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

let component = ViewBodPopupClass;

export const ViewBodPopup = connect(null, null)(component);
