import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import "./style.scss";
import moment from "moment";
import { bindActionCreators } from "redux";
import { getCategoryList } from "action/CourseAct";

export class BlogPreviewPopupClass extends Component {
  state = {
    categoryOptions: [],
  };

  _getCategoryDetails = () => {
    this.props.getCategoryList().then((data) => {
      let categoryList = [];
      data.map((list) => {
        categoryList.push({
          label: list.categoryName,
          value: list.courseCategoryId,
        });
      });
      this.setState({ categoryOptions: categoryList });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isOpen) {
        this._getCategoryDetails();
      }
    }
  }

  render() {
    let {
      className = "modal-dialog-centered blog-popup",
      isOpen = true,
      toggle,
      formFields,
      toggleEvent = {},
      isListPage = false,
    } = this.props;

    let {
      link = "",
      author = "-",
      tags = "-",
      shortDescription = "",
      title = "",
      description = "",
      category = "",
      publishDate = "",
      blogImageFileUrl = "",
      categoryId = "",
      isSaved = false,
      isShared = false,
      isLiked = false,
      isDisliked = false,
      isDownloaded = false,
    } = formFields;

    let {
      save = 0,
      share = 0,
      like = 0,
      dislike = 0,
      download = 0,
    } = toggleEvent;

    let { categoryOptions } = this.state;

    let categoryNameArray = [],
      categoryName = "";

    categoryNameArray =
      categoryOptions.length !== 0
        ? categoryOptions.filter(
            (s) => s.value == category || s.value === categoryId
          )
        : [];

    categoryName =
      categoryNameArray.length !== 0 ? categoryNameArray[0].label : "";

    return (
      <>
        <Modal isOpen={isOpen} toggle={toggle} className={className} size="lg">
          <ModalBody className="p-0">
            <div className="d-flex justify-content-beween w-100">
              <div className="w-100">
                <h5 className="text-green-18 fs-22 fw-700 line-height-28">
                  Blog details
                </h5>
              </div>
              <i
                className="icon-close fs-16 cursor-pointer"
                onClick={toggle}
              ></i>
            </div>
            <div className="blog-content">
              <div className="row">
                <div className="col-12">
                  <h5 className="fs-20 fw-700 line-height-34 text-black-1c">
                    {title}
                  </h5>
                  <h5
                    className="fs-16 line-height-27 text-black-50"
                    dangerouslySetInnerHTML={{ __html: shortDescription }}
                  ></h5>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <img
                    className="w-100"
                    src={blogImageFileUrl}
                    alt="blog-image"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5 className="fs-20 fw-700 line-height-34 text-green-18">
                    {categoryName}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p
                    className="fs-16 line-height-27 text-black-50 blog_content"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                  {link !== null ? (
                    <div className="my-3">
                      <p className="fs-14 line-height-27 text-green-18 fw-600">
                        Please go through the link for more information{" "}
                        <a
                          className="text-black-50 fs-14 text-underline"
                          href={
                            link.includes("http" || "https")
                              ? link
                              : `https://${link}`
                          }
                          target="_blank"
                        >
                          {link}
                        </a>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row d-flex align-items-center">
                <div className="col-6 pb-3">
                  <div className="row">
                    <div className="col-5 my-auto">
                      <p className="text-black-50 fs-18 fw-600 mb-0">
                        {"Publish date :"}
                      </p>
                    </div>
                    <div className="col-7 my-auto">
                      <p className="fs-16 fw-500 text-black-50 mb-0">
                        {moment(publishDate).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 pb-3">
                  <div className="row">
                    <div className="col-5 my-auto">
                      <p className="text-black-50 fs-18 fw-600 mb-0">
                        {"Author :"}
                      </p>
                    </div>
                    <div className="col-7 my-auto">
                      <p className="fs-16 fw-500 text-black-50 mb-0">
                        {author}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 pb-3">
                  <div className="row">
                    <div className="col-5 my-auto">
                      <p className="text-black-50 fs-18 fw-600 mb-0">
                        {"Tags :"}
                      </p>
                    </div>
                    <div className="col-7 my-auto">
                      <p className="fs-16 fw-500 text-black-50 mb-0">{tags}</p>
                    </div>
                  </div>
                </div>
              </div>
              {isListPage ? (
                <div className="d-flex pb-3 align-items-center">
                  <p className="text-black-50 fs-18 fw-600 mb-0 pr-3">
                    {"Views able to :"}
                  </p>
                  {!isSaved &&
                  !isShared &&
                  !isLiked &&
                  !isDisliked &&
                  !isDownloaded ? (
                    <p className="fs-16 fw-500 text-black-50 mb-0">{"-"}</p>
                  ) : (
                    <p className="fs-16 fw-500 text-black-50 mb-0">
                      {isSaved ? "Save" : ""}
                      {isSaved && isShared ? ", " : ""}
                      {isShared ? "Share" : ""}
                      {isLiked && isShared ? ", " : ""}
                      {isLiked ? "Like" : ""}
                      {isDisliked && isLiked ? ", " : ""}
                      {isDisliked ? "Dislike" : ""}
                      {isDownloaded ? ", " : ""}
                      {isDownloaded ? "Download" : ""}
                    </p>
                  )}
                </div>
              ) : (
                <div className="d-flex pb-3 align-items-center">
                  <p className="text-black-50 fs-18 fw-600 mb-0 pr-3">
                    {"Views able to :"}
                  </p>
                  {save === 0 &&
                  share === 0 &&
                  like === 0 &&
                  dislike === 0 &&
                  download === 0 ? (
                    <p className="fs-16 fw-500 text-black-50 mb-0">{"-"}</p>
                  ) : (
                    <p className="fs-16 fw-500 text-black-50 mb-0">
                      {save === 1 ? "Save" : ""}
                      {save === 1 && share === 1 ? ", " : ""}
                      {share === 1 ? "Share" : ""}
                      {like === 1 && share === 1 ? ", " : ""}
                      {like === 1 ? "Like" : ""}
                      {dislike === 1 && like === 1 ? ", " : ""}
                      {dislike === 1 ? "Dislike" : ""}
                      {download === 1 ? ", " : ""}
                      {download === 1 ? "Download" : ""}
                    </p>
                  )}
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCategoryList,
    },
    dispatch
  );
};

let component = BlogPreviewPopupClass;

export const BlogPreviewPopup = connect(null, mapDispatchToProps)(component);
