import moment from "moment";
import cryptLib from "@skavinvarnan/cryptlib";
export const addQuery = (dataObject, apiObject) => {
  if (!dataObject) {
    return "";
  }

  const keys = [
    "page",
    "limit",
    "search",
    "filter",
    "filterType",
    "sortBy",
    "filterBy",
    "type",
    "fpoId",
    "docId",
    "filterValue",
    "userMasterId",
    "program",
    "state",
    "district",
    "startDate",
    "endDate",
    "id",
    "stateId",
    "stateHead",
    "rmName",
    "stateHeadName",
    "fpoIds",
    "userId",
    "rmId",
    "reportId"
  ];

  keys.map((key) => {
    if (dataObject.hasOwnProperty(key) && typeof dataObject[key] != "object") {
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key] };
      }
    } else {
      dataObject[key] &&
        Object.keys(dataObject[key]).map((keyName) => {
          if (apiObject.query.hasOwnProperty(keyName)) {
            apiObject.addQuery = {
              key: keyName,
              payload: dataObject[key][keyName],
            };
          }
        });
    }
  });
};

export const scrollTop = (id = "root", selector = "getElementById") => {
  var contentPageEle = document[selector](id);

  if (selector != "getElementById") {
    contentPageEle = contentPageEle[0];
  }

  contentPageEle &&
    contentPageEle.scrollTo({
      top: 0,
      behavior: "smooth",
    });
};

export const generateQuery = (query) => {
  let url = "";

  if (query.hasOwnProperty("url_id")) {
    url = `/${query.url_id}`;
  }

  return (
    url +
    Object.keys(query).reduce((accumulator, key, index) => {
      if (
        query[key] == "" ||
        query[key] == null ||
        key == "url_id" ||
        (query[key] !== null && query[key].toString().trim() == "")
      ) {
        return accumulator;
      } else {
        return accumulator + `${index != 0 ? "&" : "?"}${key}=${query[key]}`;
      }
    }, "")
  );
};

export const getFormatDate = (date) => {
  return moment(date).format("DD MMM,hh:mm a");
};
export const getFormatDateOnly = (date) => {
  let newDate = moment(date).format("DD MM YYYY");

  if (newDate === "Invalid date") {
    return moment.unix(date).format("DD MM YYYY");
  } else {
    return newDate;
  }
};

export const getSNo = ({ totalPages, page, chunk }, index) => {
  return parseInt((page - 1) * chunk) + index + 1;
};

export const displayImg = (file) => {
  if (typeof file === "object") {
    return URL.createObjectURL(file);
  } else {
    return file;
  }
};

export const getToken = () => {
  return localStorage.getItem("AuthToken")
    ? localStorage.getItem("AuthToken")
    : "";
};

export const removeFeatureDate = (current, paramDate, paramAction) => {
  return current.isBefore(moment(new Date()).subtract(paramDate, paramAction));
};

export const letterAvatar = (name, size) => {
  name = name || "";
  size = size || 60;

  var colours = [
      "#1abc9c",
      "#2ecc71",
      "#3498db",
      "#9b59b6",
      "#34495e",
      "#16a085",
      "#27ae60",
      "#2980b9",
      "#8e44ad",
      "#2c3e50",
      "#f1c40f",
      "#e67e22",
      "#e74c3c",
      "#ecf0f1",
      "#95a5a6",
      "#f39c12",
      "#d35400",
      "#c0392b",
      "#bdc3c7",
      "#7f8c8d",
    ],
    nameSplit = String(name).toUpperCase().split(" "),
    initials,
    charIndex,
    colourIndex,
    canvas,
    context,
    dataURI;

  if (nameSplit.length === 1) {
    initials = nameSplit[0] ? nameSplit[0].charAt(0) : "?";
  } else {
    initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
  }

  if (window.devicePixelRatio) {
    size = size * window.devicePixelRatio;
  }

  charIndex = (initials === "?" ? 72 : initials.charCodeAt(0)) - 64;
  colourIndex = charIndex % 20;
  canvas = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  context = canvas.getContext("2d");

  context.fillStyle = colours[colourIndex - 1];
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.font = Math.round(canvas.width / 2) + "px Arial";
  context.textAlign = "center";
  context.fillStyle = "#FFF";
  context.fillText(initials, size / 2, size / 1.5);

  dataURI = canvas.toDataURL();
  canvas = null;

  return dataURI;
};

export const getDescriptionData = (data, charLength) => {
  if (data?.length < charLength) {
    return data;
  } else {
    let detail = data?.substring(0, charLength - 1) + "...";
    return detail;
  }
};

export const decryptData = (data) => {
  try {
    const decryptedString = cryptLib.decryptCipherTextWithRandomIV(
      data,
      "passworddecryptionkey"
    );
    return decryptedString;
  } catch {
    return data;
  }
};

export const encryptData = (data) => {
  try {
    const encryptedString = cryptLib.encryptPlainTextWithRandomIV(
      data,
      "passworddecryptionkey"
    );
    return encryptedString;
  } catch {
    return data;
  }
};

export const getSubAdmin = () => {
  let status = localStorage.getItem("isSubAdmin")
    ? JSON.parse(localStorage.getItem("isSubAdmin"))
    : false;
  return status;
};

export const numberFormatChange = (number) => {
  const num = Math.round(number);
  if (isNaN(num)) return num;
  const convertNumber = num.toLocaleString("en-IN", {
    currency: "INR",
    maximumFractionDigits: 0,
  });
  return convertNumber;
};

export const numDifferentiation = (val) => {
  if (val >= 10000000) val = (val / 10000000).toFixed(2) + " crores";
  else if (val >= 100000) val = (val / 100000).toFixed(2) + " lakhs";
  // else if (val >= 1000) val = (val / 1000).toFixed(2) + " thousands";
  return val;
};

export const dateDiff = (date) => {
  let currentDate = moment([
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  ]);

  let endDate = moment([
    moment(date).year(),
    moment(date).month(),
    moment(date).date(),
  ]);

  return currentDate.diff(endDate, "days");
};
