import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFpoBasicDetailsById } from "action/FPOact";
import staffImg from "assets/svg/member_view/staff.svg";
import memberImg from "assets/svg/member_view/members.svg";
import bodImg from "assets/svg/member_view/bod.svg";
import profileImage from "assets/images/default-profile-img.png";
import moment from "moment";
export class BasicDetailsofFPOClass extends Component {
  state = {
    fpoDetails: {},
  };

  _getDetails = () => {
    let { getFpoBasicDetailsById, id } = this.props;
    getFpoBasicDetailsById(id).then((data) => {
      this.setState({ fpoDetails: data.ProfileDetails });
    });
  };

  componentDidMount() {
    this._getDetails();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab === "1") {
        this._getDetails();
      }
    }
  }

  render() {
    let { fpoDetails } = this.state;

    let {
      membersCount = 0,
      staffCount = 0,
      bodsCount = 0,
      districtNames = {},
      mobileNumber = "",
      fpoName = "",
      emailId = "",
      userDetail = {},
      fpoLogoURL = "",
      fpolastupdatedtime = {},
    } = fpoDetails;

    let { lastUpdatedTime = "" } =
      fpolastupdatedtime !== null && fpolastupdatedtime
        ? fpolastupdatedtime
        : {};

    return (
      <div className="basic-details">
        <h5 className="text-green-18 fs-20 line-height-25 font-weight-bold mb-3">
          Basic details
        </h5>
        <h4 className="mb-4 pb-2 fs-26 text-black line-height-33 font-weight-bold">
          {fpoName}
        </h4>
        <div className="d-flex align-items-center mb-5">
          <div className="row w-100">
            <div className="col-5">
              <div className="d-flex align-items-center h-100">
                <div>
                  <img
                    src={fpoLogoURL ? fpoLogoURL : profileImage}
                    alt="logo"
                    className="fpo-logo"
                  />
                </div>
                <div className="d-flex flex-column ml-3">
                  <p className="text-black-1c fs-24 line-height-30 mb-1 fw-600 word-wrap">
                    {userDetail ? userDetail.userName : "-"}
                  </p>
                  <p className="fs-18 line-height-24 mb-0">
                    {districtNames ? districtNames.districtName : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-7">
              <div className="row">
                <div className="col-10">
                  <div className="fpo-list-card">
                    <div className="row">
                      <div className="col-4 py-3 px-4 margin-right-green">
                        <p className="mb-1 text-green-18 fs-28 line-height-31 fw-600">
                          {membersCount}
                        </p>
                        <div className="d-flex align-items-center">
                          <img
                            className="count-logo mr-2"
                            src={memberImg}
                            alt=""
                          />
                          <p className="mb-0 fs-15 line-height-18 text-black-50">
                            Members
                          </p>
                        </div>
                      </div>
                      <div className="col-4 py-3 px-4 margin-right-green">
                        <p className="mb-1 text-green-18 fs-28 line-height-31 fw-600">
                          {bodsCount}
                        </p>
                        <div className="d-flex align-items-center">
                          <img
                            className="count-logo mr-2"
                            src={bodImg}
                            alt=""
                          />
                          <p className="mb-0 fs-15 line-height-18 text-black-50">
                            BOD
                          </p>
                        </div>
                      </div>
                      <div className="col-4 py-3 px-4">
                        <p className="mb-1 text-green-18 fs-28 line-height-31 fw-600">
                          {staffCount}
                        </p>
                        <div className="d-flex align-items-center">
                          <img
                            className="count-logo mr-2"
                            src={staffImg}
                            alt=""
                          />
                          <p className="mb-0 fs-15 line-height-18 text-black-50">
                            Staff
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-6">
            <div className="row">
              <div className="col-4">
                <p className="mb-0 text-black-4D line-height-20 font-weight-bold">
                  Role title
                </p>
              </div>
              <div className="col-8">
                <p className="mb-0 text-black-4D line-height-19">
                  {userDetail && userDetail.userRoles
                    ? userDetail.userRoles.userRoleName
                    : "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4">
                <p className="mb-0 text-black-4D line-height-20 font-weight-bold">
                  Contact
                </p>
              </div>
              <div className="col-8">
                <p className="mb-0 text-black-4D line-height-19">
                  {mobileNumber ? mobileNumber : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-6">
            <div className="row">
              <div className="col-4">
                <p className="mb-0 text-black-4D line-height-20 font-weight-bold">
                  Email id
                </p>
              </div>
              <div className="col-8">
                <p className="mb-0 text-black-4D line-height-19">
                  {emailId ? emailId : "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4">
                <p className="mb-0 text-black-4D line-height-20 font-weight-bold">
                  Last updated time
                </p>
              </div>
              <div className="col-8">
                <p className="mb-0 text-black-4D line-height-19">
                  {lastUpdatedTime && lastUpdatedTime !== ""
                    ? moment(lastUpdatedTime).format("DD/MM/YYYY LT")
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFpoBasicDetailsById,
    },
    dispatch
  );
};

let component = BasicDetailsofFPOClass;

export const BasicDetailsofFPO = connect(null, mapDispatchToProps)(component);
