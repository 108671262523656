import React, { Component } from "react";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { ExistingFpo } from "../ExistingFpo";
import { NewFpo } from "../NewFpo";
import classnames from "classnames";
import "./style.scss";

export class FpoTabClass extends Component {
  state = {
    activeTab: "1",
    userRoleID: "",
  };

  toggle = (tab) => {
    let { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  componentDidMount() {
    let id = localStorage.getItem("userRoleId");
    this.setState({
      userRoleID: id,
    });
  }

  render() {
    const { activeTab, userRoleID } = this.state;
    return (
      <div className="fpo-list">
        <div className="d-flex justify-content-center">
          {userRoleID == 13 || userRoleID == 14 || userRoleID == 16 ? null : (
            <div className="scroll-x">
              <Nav className="tab-sammunaty">
                <NavItem>
                  <NavLink
                    className={
                      (classnames({ active: activeTab === "1" }), "p-0")
                    }
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    <button
                      className={`btn ${activeTab === "1" ? "active" : ""}`}
                    >
                      FPO List
                    </button>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      (classnames({ active: activeTab === "2" }), "p-0")
                    }
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    <button
                      className={`btn ${activeTab === "2" ? "active" : ""}`}
                    >
                      New Requests
                    </button>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          )}
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <ExistingFpo />
          </TabPane>
          <TabPane tabId="2">
            <NewFpo />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

let component = FpoTabClass;

export const FpoList = connect(null, null)(component);
