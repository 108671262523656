import { forum } from '../service/apiVariables';

import { addQuery } from 'service/helperFunctions';

//create Forum
export const createForum = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...forum.createForum, body })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//update Forum
export const updateForum = (id, body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   let { updateForum } = forum;
   updateForum.id = id;
   return new Promise((resolve, reject) => {
      api({ ...forum.updateForum, body })
         .then(({ data, message }) => {
            resolve(data);
            Toast({ type: 'success', message });
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Forum List
export const getForumList = (query) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      addQuery(query, forum.getForumList);
      api({ ...forum.getForumList })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Forum By Id
export const getForumById = (id) => (dispatch, getState, { api, Toast }) => {
   let { getForumById } = forum;
   getForumById.id = id;
   return new Promise((resolve, reject) => {
      api({ ...forum.getForumById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get forum comments
export const getForumComments = (id, query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   let { getForumComments } = forum;
   getForumComments.id = id ? id : '';
   return new Promise((resolve, reject) => {
      addQuery(query, forum.getForumComments);

      api({ ...forum.getForumComments, id })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Category List
export const getCategoryList = (id) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...forum.list, id })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//delete Forum By Id
export const deleteForumById = (id) => (dispatch, getState, { api, Toast }) => {
   let { deleteForumById } = forum;
   deleteForumById.id = id;
   return new Promise((resolve, reject) => {
      api({ ...forum.deleteForumById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
