import React, { Component } from "react";

import { connect } from "react-redux";

import communityMenuGreen from "assets/svg/community_green.svg";

import "./countInforOverAll.scss";

export class CountInfoOverAllClass extends Component {
  render() {
    let { dashboardDetails } = this.props;
    let {
      agriProducts = 0,
      membership = 0,
      noOfFpos = 0,
      coverages = 0,
    } = dashboardDetails;
    return (
      <div className="row">
        <div className="col mb-4">
          <div className="dashboard-small-card">
            <div className="d-flex justify-content-between align-items-end">
              <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">
                {noOfFpos}
              </p>
              <img src={communityMenuGreen} height="53" width="54" alt="fpo" />
            </div>
            <div>
              <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">
                Number of FPOs
              </p>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="dashboard-small-card">
            <div className="d-flex justify-content-between align-items-end">
              <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">
                {agriProducts}
              </p>
              <img src={communityMenuGreen} height="53" width="54" alt="fpo" />
            </div>
            <div>
              <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">
                Agri products
              </p>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="dashboard-small-card">
            <div className="d-flex justify-content-between align-items-end">
              <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">
                {coverages}
              </p>
              <img src={communityMenuGreen} height="53" width="54" alt="fpo" />
            </div>
            <div>
              <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">
                Coverages
              </p>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="dashboard-small-card">
            <div className="d-flex justify-content-between align-items-end">
              <p className="mb-0 fs-24 font-Gilroy-Bold line-height-28 text-green-18">
                {membership}
              </p>
              <img src={communityMenuGreen} height="53" width="54" alt="fpo" />
            </div>
            <div>
              <p className="mb-0 mt-1 fs-16 font-Gilroy line-height-19 text-black-50 ">
                Membership
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const CountInfoOverAll = connect(null, null)(CountInfoOverAllClass);
