import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FpoList } from "component/Fpo"

export class FPOlistClass extends Component {

    render() {
        return (
            <div className="fpolist">
                <FpoList />
            </div>
        );
    }
}

export const FPOlist = connect(null, null)(FPOlistClass)
