import React, { Component } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { TableWrapper, CommonPageLoader, DeletePopup } from 'component/common';

import moment from 'moment';

import { history } from "service/helpers";

import { CoursePreviewPopup } from "../CoursePreviewPopup"

import { getCategoryDetail } from 'action/CourseAct';

import { getCourseList, deleteCourseById, getPublishedCourseList } from "action/CourseAct"
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';

import classnames from 'classnames';

export class CourseClass extends Component {
    state = {
        headerDetails: [{
            label: 'Course id',
        }, {
            label: 'Course title',
        }, {
            label: 'Certifying institute',
        }, {
            label: 'Difficulty level',
        }, {
            label: 'Category',
        }, {
            label: 'Publication date',
        }, {
            label: 'Action',
        }],
        pageMeta: {},
        savedCourse: [],
        publishedCourse: [],
        isloading: false,
        activeTab: '1',
        isDeletePopup: false,
        courseId: '',
        ispreviewPopup: false,
        courseData: {},
        isPopupdataLoading: false
    };

    //Lifecylces
    componentDidMount = () => {

        this._getCourseList(1)

    }

    //get course list
    _getCourseList = (page) => {

        this.setState({ isloading: true })

        let { filter, filterType, searchValue, sortBy } = this.props

        let { activeTab } = this.state

        if (activeTab == 1) {

            this.props.getPublishedCourseList({ page, filter, filterType, search: searchValue, sortBy }).then((data) => {
                let results = data.results
                this.setState({
                    pageMeta: data.pageMeta,
                    publishedCourse: results,
                    isloading: false
                })
            })

        } else {
            this.props.getCourseList({ page, filter, filterType, search: searchValue, sortBy }).then((data) => {
                let results = data.results
                this.setState({
                    pageMeta: data.pageMeta,
                    savedCourse: results,
                    isloading: false
                })
            })
        }
    }

    //handle page change
    handlePageChange = (page) => {
        //page change
        this._getCourseList(page)
    }

    //lifecycles
    componentDidUpdate(prevProps) {
        if (this.props.activeTab == 1) {
            this.props.filter !== prevProps.filter && this._getCourseList(1);
            this.props.searchValue !== prevProps.searchValue && this._getCourseList(1);
            this.props.sortBy !== prevProps.sortBy && this._getCourseList(1);
        }
    }

    //handle delete course
    handleDelete = () => {
        let { courseId } = this.state
        this.props.deleteCourseById({ courseId: parseInt(courseId) }).then(() => {
            this.setState({ courseId: '' })
            this._getCourseList(1)
            this.toggleDelete('isDeletePopup')
        });
    };

    toggle = (tab) => {
        let { activeTab } = this.state
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, () => {
                this._getCourseList(1)
            });
        }
    }

    toggleDelete = (name, id) => {
        this.setState((prevState) => ({
            [name]: !prevState[name],
            courseId: id
        }));
    };

    togglePreview = (name, courseId = '') => {
        let { ispreviewPopup } = this.state
        this.setState({
            ispreviewPopup: !ispreviewPopup
        }, () => {
            if (!ispreviewPopup) {
                this._getCategoryDetail(courseId)
            }
        });
    };

    _getCategoryDetail = (courseId) => {
        this.setState({ isPopupdataLoading: true }, () => {
            this.props.getCategoryDetail(courseId).then((data) => {
                this.setState({
                    courseData: data,
                    isPopupdataLoading: false
                })
            })
        })
    }

    render() {
        const {
            headerDetails,
            pageMeta = {},
            publishedCourse,
            isloading,
            activeTab,
            savedCourse,
            isDeletePopup,
            courseData,
            ispreviewPopup,
            isPopupdataLoading
        } = this.state;

        return (
            <div className="course-management-inner-tabs">
                <div className="d-flex justify-content-center">
                    <Nav className="tab-sammunaty">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                            >

                                <button
                                    className={`btn ${
                                        activeTab === '1'
                                            ? 'active'
                                            : ''
                                        }`}
                                > Published </button>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                            >
                                <button
                                    className={`btn ${
                                        activeTab === '2'
                                            ? 'active'
                                            : ''
                                        }`}
                                > Draft </button>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">

                        {isloading ?
                            <>
                                {/* Loader */}
                                <CommonPageLoader />
                            </>
                            :
                            <div className="table-container w-100 mt-4 pt-1">
                                {publishedCourse.length != 0 ?
                                    <TableWrapper
                                        headerDetails={headerDetails}
                                        queryHandler={this.handlePageChange}
                                        pageMeta={pageMeta}
                                    >
                                        {publishedCourse.map((item, index) => {

                                            let {
                                                courseId,
                                                title,
                                                certifyingInstitutions,
                                                difficultyLevel,
                                                category,
                                                releaseDate
                                            } = item

                                            return (
                                               <React.Fragment key={index}>
                      <tr>
                                                    <td><div className="d-flex align-items-center h-100">{courseId}</div></td>
                                                    <td><div className="d-flex align-items-center h-100">{title}</div></td>
                                                    <td><div className="d-flex align-items-center h-100">{certifyingInstitutions.instituteName}</div></td>
                                                    <td><div className="d-flex align-items-center h-100">{difficultyLevel}</div></td>
                                                    <td><div className="d-flex align-items-center h-100">{category.categoryName}</div></td>
                                                    <td><div className="d-flex align-items-center h-100">{moment(releaseDate).format('DD MMM YYYY')}</div></td>
                                                    <td><div className="d-flex align-items-center h-100">
                                                        <i className='icon-eye fs-20 cursor-pointer pr-3'
                                                            onClick={() => this.togglePreview('ispreviewPopup', courseId)} ></i>
                                                        <i className="icon-edit fs-20 cursor-pointer"
                                                            onClick={() => { history.push(`addCourse/courseDetail/${courseId}`) }}
                                                        ></i>


                                                        <i
                                                            className='icon-Delete fs-20 pl-3 cursor-pointer'
                                                            onClick={() => {
                                                                this.toggleDelete('isDeletePopup', courseId)
                                                            }}
                                                        ></i>
                                                    </div></td>
                                                </tr>
                                                <tr className="trans-bg">
                        <td className="empty-height" height="12"></td>
                      </tr>
                    </React.Fragment>
                                            )
                                        })}
                                    </TableWrapper>
                                    :
                                    <div className="text-center fs-18 text-black py-5">No records found !!!</div>
                                }
                            </div>

                        }
                    </TabPane>
                    <TabPane tabId="2">

                        {isloading ?
                            <>
                                {/* Loader */}
                                <CommonPageLoader />
                            </>
                            :
                            <div className="table-container w-100 mt-4 pt-1">
                                {savedCourse.length != 0 ?
                                    <TableWrapper
                                        headerDetails={headerDetails}
                                        queryHandler={this.handlePageChange}
                                        pageMeta={pageMeta}
                                    >
                                        {savedCourse.map((item, index) => {

                                            let {
                                                courseId,
                                                title,
                                                certifyingInstitutions,
                                                difficultyLevel,
                                                category,
                                                releaseDate
                                            } = item

                                            return (
                                              <React.Fragment key={index}>
                                                <tr>
                                                  <td>
                                                    <div className="d-flex align-items-center h-100">
                                                      {courseId}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center h-100">
                                                      {title}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center h-100">
                                                      {
                                                        certifyingInstitutions.instituteName
                                                      }
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center h-100">
                                                      {difficultyLevel}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center h-100">
                                                      {category.categoryName}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center h-100">
                                                      {moment(
                                                        releaseDate
                                                      ).format("DD MMM YYYY")}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="d-flex align-items-center h-100">
                                                      <i
                                                        className="icon-eye fs-20 cursor-pointer pr-3"
                                                        onClick={() =>
                                                          this.togglePreview(
                                                            "ispreviewPopup",
                                                            courseId
                                                          )
                                                        }
                                                      ></i>
                                                      <i
                                                        className="icon-edit fs-20 cursor-pointer"
                                                        onClick={() => {
                                                          history.push(
                                                            `addCourse/courseDetail/${courseId}`
                                                          );
                                                        }}
                                                      ></i>
                                                      <i
                                                        className="icon-Delete fs-20 pl-3 cursor-pointer"
                                                        onClick={() => {
                                                          this.toggleDelete(
                                                            "isDeletePopup",
                                                            courseId
                                                          );
                                                        }}
                                                      ></i>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr className="trans-bg">
                                                  <td
                                                    className="empty-height"
                                                    height="12"
                                                  ></td>
                                                </tr>
                                              </React.Fragment>
                                            );
                                        })}
                                    </TableWrapper>
                                    :
                                    <div className="text-center fs-18 text-black py-5">No records found !!!</div>
                                }
                            </div>

                        }
                    </TabPane>
                </TabContent>
                <DeletePopup
                    isOpen={isDeletePopup}
                    toggle={() => this.toggleDelete('isDeletePopup')}
                    handleChange={() => this.handleDelete()}
                />
                <CoursePreviewPopup
                    isOpen={ispreviewPopup}
                    toggle={() => this.togglePreview('ispreviewPopup')}
                    formFields={courseData}
                    isPopupdataLoading={isPopupdataLoading}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getCourseList,
        deleteCourseById,
        getPublishedCourseList,
        getCategoryDetail
    }, dispatch)
}


let component = CourseClass;

export const Course = connect(null, mapDispatchToProps)(component)