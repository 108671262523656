import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
   NormalSelect,
   NormalInput,
   NormalTextarea,
   NormalButton,
   NormalEditor,
} from '../../../common';

import { history } from 'service/helpers';

import { getDescriptionData } from 'service/helperFunctions';

import { fileUpload } from 'action/CourseAct';

import SimpleReactValidator from 'simple-react-validator';

import { Toast } from 'service/toast';

import {
   createForum,
   updateForum,
   getForumById,
   getCategoryList,
} from 'action/forumAct';

import './addForm.scss';

export class AddForumClass extends Component {
   state = {
      formFields: {
         title: '',
         content: '',
         forumCategoryId: '',
         images: [],
         slug: '',
         metaTitle: '',
      },
      allCategoryList: [],
      isImageUploading: false,
   };

   //Validation
   validator = new SimpleReactValidator({
      element: (message) => (
         <span className='error-message text-danger fs-16 pb-3'>{message}</span>
      ),
   });

   componentDidMount() {
      let { id } = this.props;
      if (id) {
         this._getForumById(id);
      }
      this.props.getCategoryList().then(({ data }) => {
         let list = data.map(({ id, name }) => ({ value: id, label: name }));
         this.setState({ allCategoryList: list });
      });
   }

   _getForumById = (id) => {
      let { getForumById } = this.props;
      getForumById(id).then((data) => {
         this.setState({
            formFields: {
               title: data.title,
               content: data.content,
               forumCategoryId: data.forumCategoryId,
               images: data.images,
            },
         });
      });
   };

   uploadSingleFile = (e) => {
      //upload image
      let file = e.target.files[0];
      if (file !== undefined) {
         let { formFields } = this.state;
         formFields.images = [];
         this.setState({ formFields }, () => {
            let fileData = [];
            fileData.push({ alt: 'Uploading ....', url: file });
            formFields.images = fileData;
            this.setState({ isImageUploading: true });

            const config = {
               onUploadProgress: function (progressEvent) {
                  var percentCompleted = Math.round(
                     (progressEvent.loaded * 100) / progressEvent.total
                  );
                  document.getElementById(
                     'image-upload'
                  ).innerText = `Uploading ${percentCompleted}%`;
               },
            };

            const formData = new FormData();
            formData.append('file', file);

            this.props.fileUpload(formData, config).then((data) => {
               if (data !== undefined) {
                  let uploadedFile = [];
                  uploadedFile.push({
                     alt: data[0].fileName,
                     url: data[0].fileUrl,
                  });
                  formFields.images = uploadedFile;
                  this.setState({
                     formFields,
                     isImageUploading: false,
                  });
               } else {
                  formFields.images = [];
                  this.setState({
                     formFields,
                     isImageUploading: false,
                  });
               }
            });
            this.setState({
               formFields,
            });
         });
      }
   };

   handleInput = ({ target: { name, value } }) => {
      let { formFields } = this.state;

      formFields[name] = value;

      this.setState({
         formFields,
      });
   };
   onEditorChange = (editor) => {
      let { formFields } = this.state;
      formFields.content = editor.getData();
      this.setState({
         formFields,
      });
   };
   handleSubmit = () => {
      let { formFields, isImageUploading } = this.state;

      let { id } = this.props;

      if (this.validator.allValid()) {
         if (!isImageUploading) {
            formFields.metaTitle = formFields.title;
            formFields.slug = formFields.title.replace(' ', '-');
            if (id) {
               this.props.updateForum(id, formFields).then(() => {
                  Toast({
                     type: 'success',
                     message: 'Forum updated successfully',
                  });
                  this.validator.hideMessages();
                  history.push('/admin/forums');
               });
            } else {
               this.props.createForum(formFields).then(() => {
                  Toast({
                     type: 'success',
                     message: 'Forum added successfully',
                  });
                  this.validator.hideMessages();
                  history.push('/admin/forums');
               });
            }
         } else {
            Toast({ type: 'error', message: 'Please wait Image is uploading' });
         }
      } else {
         this.validator.showMessages();
         this.forceUpdate();
      }
   };

   render() {
      let { formFields, isImageUploading, allCategoryList } = this.state;

      let { title, content, forumCategoryId, images } = formFields;

      let { id } = this.props;
      return (
         <>
            <div className='row'>
               <div className='col-md-12'>
                  <div className='card'>
                     <div className='card-body'>
                        <h5 className='card-title mb-0'>Forum details</h5>
                        <div className='row'>
                           <div className='col-md-12'>
                              <NormalInput
                                 label='Name of the forum'
                                 placeholder='Enter here'
                                 type='text'
                                 value={title}
                                 name='title'
                                 required={true}
                                 onChange={this.handleInput}
                              />
                              {this.validator.message(
                                 'title',
                                 title,
                                 'required|max:150'
                              )}
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col-md-12'>
                              {/* <NormalTextarea
                                 label='Description'
                                 onChange={this.handleInput}
                                 value={content}
                                 placeholder='Enter here'
                                 name='content'
                                 required={true}
                              /> */}
                              <NormalEditor
                                 label='Description'
                                 data={content}
                                 onChange={this.onEditorChange}
                                 required={true}
                              />
                              {this.validator.message(
                                 'content',
                                 content,
                                 'required|max:400'
                              )}
                           </div>
                        </div>
                        <div className='row'></div>
                        <div className='row'>
                           <div className='col-6'>
                              <NormalSelect
                                 label='Category'
                                 placeholder='Select'
                                 arrow={true}
                                 value={forumCategoryId}
                                 options={allCategoryList}
                                 name='forumCategoryId'
                                 handleChange={this.handleInput}
                                 required={true}
                              />
                              {this.validator.message(
                                 'forumCategoryId',
                                 forumCategoryId,
                                 'required'
                              )}
                           </div>
                           <div className='col-6'>
                              <div className='fs-17 text-black font-Gilroy pb-3 mt-4'>
                                 {'Upload image'}
                                 <span className='text-danger pl-2 fs-18'>
                                    *
                                 </span>
                              </div>
                              <div className='d-flex align-items-center w-100 btn-file-input'>
                                 <button className=''>
                                    <input
                                       type='file'
                                       className='h-100'
                                       accept='image/*'
                                       onChange={this.uploadSingleFile}
                                    ></input>
                                    {isImageUploading ? (
                                       <div
                                          id='image-upload'
                                          className='d-flex align-items-center'
                                       ></div>
                                    ) : images.length === 0 ? (
                                       'Upload'
                                    ) : (
                                             getDescriptionData(images[0].alt, 20)
                                          )}
                                 </button>
                                 {images.length != 0 && !isImageUploading ? (
                                    <div className='ml-4'>
                                       <img
                                          src={images[0].url}
                                          alt=''
                                          className='upload-image-preview'
                                       />
                                    </div>
                                 ) : (
                                       ''
                                    )}
                              </div>
                              {this.validator.message(
                                 'image',
                                 images[0],
                                 'required'
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className='d-flex align-items-center add-buttons w-100'>
               <div className='ml-auto d-flex'>
                  <NormalButton
                     label='Cancel'
                     outline={true}
                     className='px-4 mr-2 border-none'
                     onClick={() => history.goBack()}
                  />
                  <NormalButton
                     label={id ? 'Update' : 'Publish'}
                     mainbg={true}
                     className='px-4'
                     onClick={() => this.handleSubmit()}
                  />
               </div>
            </div>
         </>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         fileUpload,
         createForum,
         updateForum,
         getForumById,
         getCategoryList,
      },
      dispatch
   );
};

let component = AddForumClass;

export const AddForum = connect(null, mapDispatchToProps)(component);
