import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import './index.scss';
export const CustomDate = (props) => {
   useEffect(() => { }, [props.list]);
   return (
      <div className='custom-date customDatePickerWidth'>
         <DatePicker
            disabledKeyboardNavigation
            renderCustomHeader={({
               date,
               decreaseMonth,
               increaseMonth,
               prevMonthButtonDisabled,
               nextMonthButtonDisabled,
            }) => (
                  <div className="d-flex justify-content-center align-items-center">
                     <button
                        onClick={() => {
                           decreaseMonth();
                        }}
                        disabled={prevMonthButtonDisabled}
                     >
                        <i className="icon-keyboard-arrow-left"></i>
                     </button>
                     <p className='month-year mb-0'>
                        {moment(date).format('MMMM YYYY')}
                     </p>

                     <button
                        onClick={() => {
                           increaseMonth();
                        }}
                        disabled={nextMonthButtonDisabled}
                     >
                        <i className="icon-keyboard-arrow-right"></i>
                     </button>
                  </div>
               )}
            inline
            disabled={true}
            highlightDates={[...props.list]}
         />
      </div>
   );
};
