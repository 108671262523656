import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Highcharts from "highcharts";
import * as Exporting from "highcharts/modules/exporting";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import {
  getDashboardGraphDetails,
  getDashboardBusinessStateHeadDetails,
  getgetRMListDetails,
} from "action/dashboard";
import { allState, getDistrictApiwithState } from "action/FPOact";
import { debounceFunction } from "service/debounce";
import {
  NormalSelect,
  NormalDate,
  InputSearch,
  NormalButton,
} from "component/common";
import { MultiSelect } from "react-multi-select-component";
NoDataToDisplay(Highcharts);
Exporting(Highcharts);

Highcharts.setOptions({
  lang: {
    noData: "No data found",
  },
  noData: {
    style: {
      fontWeight: "bold",
      fontSize: "16px",
    },
  },
});

const programLists = [
  { label: "LHFPO", value: "LHFPO" },
  { label: "BMGF", value: "BMGF" },
  { label: "FPOnEXT", value: "FPOnEXT" },
  { label: "FPO 360", value: "FPO_360" },
  { label: "FPO Bazaar", value: "FPO_Bazaar" },
  // { label: "Sam IPL", value: "Sam IPL" },
];

const programListsOne = [
  { label: "LHFPO", value: "LHFPO" },
  { label: "BMGF", value: "BMGF" },
  { label: "FPOnEXT", value: "FPOnEXT" },
  { label: "FPO 360", value: "FPO_360" },
];

const DashboardGraphClass = (props) => {
  const {
    getDashboardGraphDetailsApi,
    allStateApi,
    getDashboardBusinessStateHeadDetailsApi,
    getgetRMListDetailsApi,
    homeActiveTab,
  } = props;
  const trueCondtion = true;
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [stateHeadOptions, setStateHeadOptions] = useState([]);
  const [rmNameOptions, setRMNameOptions] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [stateID, setStateID] = useState("");
  let [selectedStateHead, setSelectedStateHead] = useState("");
  const [loggedInID, setloggedInID] = useState("");
  const [selected, setSelected] = useState([]);
  const [sortingData, setSortingData] = useState({
    program: "",
    state: "",
    stateHead: "",
    rmName: "",
    district: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    let id = localStorage.getItem("userRoleId");
    setloggedInID(id);
  }, []);

  const stateApiCall = useCallback(() => {
    allStateApi().then((data) => {
      let stateData = [{ label: "All", value: "All" }];
      data.forEach((list) => {
        stateData.push({
          label: list.stateName,
          value: list.stateId,
        });
        setStateOptions([...stateData]);
      });
    });
  }, [allStateApi]);

  useEffect(() => {
    if (homeActiveTab === "Trend Graphs") {
      stateApiCall();
    }
  }, [stateApiCall, homeActiveTab]);

  const getStateHead = useCallback(
    (value) => {
      if (value === "All") {
        value = "";
      }
      let body = {
        stateId: value !== undefined ? value : "",
      };
      getDashboardBusinessStateHeadDetailsApi(body).then((data) => {
        let stateHeadData = data.data.map((list) => ({
          label: list.stateHead,
          value: list.stateHead,
        }));
        setStateHeadOptions([{ label: "All", value: "All" }, ...stateHeadData]);
      });
    },
    [getDashboardBusinessStateHeadDetailsApi]
  );

  useEffect(() => {
    if (homeActiveTab === "Trend Graphs" && loggedInID !== "13") {
      getStateHead();
    }
  }, [getStateHead, homeActiveTab, loggedInID]);

  const getRMListMethod = useCallback(
    (value) => {
      if (value === "All") {
        value = "";
      }
      if (selectedStateHead === "All" && sortingData?.state === "") {
        selectedStateHead = "";
      } else if (selectedStateHead === "All" && sortingData?.state !== "") {
        value = value || stateID;
      } else if (sortingData?.state !== "" && selectedStateHead !== "") {
        value = value || stateID;
        selectedStateHead = "";
      }
      let body = {
        stateId: value !== undefined ? value : "",
        stateHeadName: selectedStateHead !== "" ? selectedStateHead : "",
      };
      if (selectedStateHead === "All") {
        body.stateHeadName = "";
      }
      getgetRMListDetailsApi(body).then((data) => {
        let rmData = data.data.map((list) => ({
          label: list.rmName,
          value: list.rmName,
        }));
        setRMNameOptions([{ label: "All", value: "All" }, ...rmData]);
      });
    },
    [getgetRMListDetailsApi, selectedStateHead, stateID]
  );

  useEffect(() => {
    if (homeActiveTab === "Trend Graphs") {
      getRMListMethod();
    }
  }, [getRMListMethod, homeActiveTab]);

  const handleInput = (e) => {
    const { name, value } = e.target || e || {};
    if (name === "state") {
      if (loggedInID !== "13") {
        sortingData.stateHead = "";
        sortingData.rmName = "";
        setSortingData(
          { ...sortingData, [name]: value },
          // getDistrictApiwithStateMethod(value),
          getStateHead(value),
          getRMListMethod(value)
        );
        for (let i = 0; i < stateOptions?.length; i++) {
          if (value === stateOptions[i].value.toString()) {
            setSelectedState(stateOptions[i].label);
            setStateID(stateOptions[i].value);
            getRMListMethod(stateOptions[i].value);
          }
        }
      }
    }
    if (name === "stateHead") {
      if (value === "All") {
        sortingData.rmName = "All";
      }
      setSelectedStateHead(value);
    }
    setSortingData({ ...sortingData, [name]: value });
  };

  const handleInputChange = ({ target: { value } }) => {
    setSearchData(value);
    debounceFunction(() => SearchFunction(value), 1000);
  };

  useEffect(() => {
    if (
      sortingData?.startDate === null ||
      sortingData?.startDate === "" ||
      sortingData?.startDate === undefined
    ) {
      sortingData.startDate = "";
      sortingData.endDate = "";
    }
  }, [sortingData.startDate, sortingData.endDate]);

  useEffect(() => {
    if (sortingData?.endDate === null) {
      sortingData.endDate = "";
    }
  }, [sortingData.endDate]);

  useEffect(() => {
    for (let i = 0; i < stateOptions?.length; i++) {
      if (parseInt(sortingData?.state) === stateOptions[i].value) {
        setSelectedState(stateOptions[i].label);
        sortingData.state = stateOptions[i].value;
      }
    }
  }, [stateOptions, sortingData.state]);

  const graphApiCall = useCallback(
    (value) => {
      let body = {
        program:
          loggedInID === "13"
            ? JSON.stringify(["FPO_Bazaar"])
            : sortingData?.program !== ""
            ? sortingData?.program
            : "",
        state: sortingData?.state !== "" ? sortingData?.state : "",
        stateHead: sortingData?.stateHead !== "" ? sortingData?.stateHead : "",
        rmName: sortingData?.rmName !== "" ? sortingData?.rmName : "",
        startDate:
          sortingData?.startDate !== ""
            ? moment(sortingData?.startDate).format("YYYY-MM-DD")
            : "",
        endDate:
          sortingData?.endDate !== ""
            ? moment(sortingData?.endDate).format("YYYY-MM-DD")
            : "",
        search: value === undefined ? "" : value,
      };
      if (sortingData?.program === "All") {
        body.program = "";
      }
      if (sortingData?.state === "All") {
        body.state = "";
        body.stateHead = "";
        body.rmName = "";
      }
      if (sortingData?.district === "All") {
        body.district = "";
      }
      if (sortingData?.stateHead === "All") {
        if (sortingData?.state === "") {
          body.stateHead = "";
          body.rmName = "";
        } else if (sortingData?.state !== "") {
          body.stateHead = "";
        }
      }
      if (sortingData?.rmName === "All") {
        body.rmName = "";
      }
      if (loggedInID !== "13") {
        if (sortingData?.program?.length > 0) {
          body.program = JSON.stringify(body.program);
        } else {
          body.program = "";
        }
      }
      getDashboardGraphDetailsApi(body).then((data) => {
        if (trueCondtion) {
          let fpoTrendData = data?.data?.fpoTrandGraph;
          let { xAxis = {}, series = [] } = TrendGraph;
          xAxis.categories = [];
          series[0].data = [];
          fpoTrendData.map((list) => {
            xAxis.categories.push(moment(list.date).format("MMM DD"));
            series[0].data.push(list.noOfFpos);
          });
          Highcharts.chart("trendGraphContainer", TrendGraph);
        }

        if (trueCondtion) {
          let memberData = data?.data?.memberTrandGraph;
          let { xAxis = {}, series = [] } = MemberGraph;
          xAxis.categories = [];
          series[0].data = [];
          memberData.map((list) => {
            xAxis.categories.push(moment(list.date).format("MMM DD"));
            series[0].data.push(list.noOfMembers);
          });
          Highcharts.chart("memberGraphContainer", MemberGraph);
        }

        if (trueCondtion) {
          let figData = data?.data?.fig;
          let { xAxis = {}, series = [] } = FIGGraph;
          xAxis.categories = [];
          series[0].data = [];
          figData.map((list) => {
            xAxis.categories.push(moment(list.date).format("MMM DD"));
            series[0].data.push(list.noOfFpos);
          });
          Highcharts.chart("FIGGraphContainer", FIGGraph);
        }

        if (trueCondtion) {
          let stateData = data?.data?.stateWise;
          let { xAxis = {}, series = [] } = StateGraph;
          xAxis.categories = [];
          series[0].data = [];
          stateData.map((list) => {
            xAxis.categories.push(list.stateName);
            series[0].data.push(list.totalMember);
          });
          let newData = series[0].data.filter((list) => list !== 0);
          if (newData?.length === 0) {
            xAxis.categories = [];
            series[0].data = [];
          }
          Highcharts.chart("stateGraphContainer", StateGraph);
        }
      });
    },
    [getDashboardGraphDetailsApi, sortingData, sortingData.state, loggedInID]
  );

  useEffect(() => {
    if (homeActiveTab === "Trend Graphs") {
      graphApiCall();
    }
  }, [graphApiCall, homeActiveTab]);

  const SearchFunction = (value) => {
    graphApiCall(value);
    // homeApiCall(1, value);
    // memberApiCall(1, value);
    // figApiCall(1, value);
  };

  const graphStartDate = moment(sortingData?.startDate).format("MMM DD");
  const graphEndDate = moment(sortingData?.endDate).format("MMM DD");

  const TrendGraph = {
    title: {
      text: `FPO Trend graph ${
        loggedInID === "13"
          ? " - FPO Bazaar"
          : sortingData?.program?.length > 0
          ? ` - ${sortingData?.program}`
          : ""
      }   ${selectedState !== "" ? ` - ${selectedState}` : ""} 
       ${selectedDistrict !== undefined ? ` - ${selectedDistrict}` : ""} 
      ${
        graphStartDate !== "Invalid date" && graphEndDate === "Invalid date"
          ? ` - ${graphStartDate}`
          : graphStartDate !== "Invalid date" && graphEndDate !== "Invalid date"
          ? ` - (${graphStartDate} - ${graphEndDate})`
          : ""
      }`,
      style: {
        color: "#189C5C",
        font: "bold 20px Gilroy-Bold",
      },
      align: "left",
      margin: 40,
    },
    tooltip: {
      formatter: function () {
        var content = "";
        content +=
          this.x +
          "<br>" +
          "FPO count" +
          ":  " +
          '<span style="color:black;font-weight:bold">' +
          this.y +
          "</b>" +
          "</span>";
        return content;
      },
    },
    series: [
      {
        color: "#FDC300",
        data: [],
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    xAxis: {
      categories: [],
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#000000",
          font: "12px Gilroy",
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#000000",
          font: "12px Gilroy",
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  const MemberGraph = {
    title: {
      text: `Member Trend graph ${
        loggedInID === "13"
          ? " - FPO Bazaar"
          : sortingData?.program?.length > 0
          ? ` - ${sortingData?.program}`
          : ""
      }   ${selectedState !== "" ? ` - ${selectedState}` : ""} 
       ${selectedDistrict !== undefined ? ` - ${selectedDistrict}` : ""} 
      ${
        graphStartDate !== "Invalid date" && graphEndDate === "Invalid date"
          ? ` - ${graphStartDate}`
          : graphStartDate !== "Invalid date" && graphEndDate !== "Invalid date"
          ? ` - (${graphStartDate} - ${graphEndDate})`
          : ""
      }`,
      style: {
        color: "#189C5C",
        font: "bold 20px Gilroy-Bold",
      },
      align: "left",
      margin: 40,
    },
    tooltip: {
      formatter: function () {
        var content = "";
        content +=
          this.x +
          "<br>" +
          "Member count" +
          ":  " +
          '<span style="color:black;font-weight:bold">' +
          this.y +
          "</b>" +
          "</span>";
        return content;
      },
    },
    series: [
      {
        color: "#FDC300",
        data: [],
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    xAxis: {
      categories: [],
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#000000",
          font: "12px Gilroy",
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#000000",
          font: "12px Gilroy",
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  const FIGGraph = {
    title: {
      text: `FIG Trend graph ${
        loggedInID === "13"
          ? " - FPO Bazaar"
          : sortingData?.program?.length > 0
          ? ` - ${sortingData?.program}`
          : ""
      }   ${selectedState !== "" ? ` - ${selectedState}` : ""} 
       ${selectedDistrict !== undefined ? ` - ${selectedDistrict}` : ""} 
      ${
        graphStartDate !== "Invalid date" && graphEndDate === "Invalid date"
          ? ` - ${graphStartDate}`
          : graphStartDate !== "Invalid date" && graphEndDate !== "Invalid date"
          ? ` - (${graphStartDate} - ${graphEndDate})`
          : ""
      }`,
      style: {
        color: "#189C5C",
        font: "bold 20px Gilroy-Bold",
      },
      align: "left",
      margin: 40,
    },
    tooltip: {
      formatter: function () {
        var content = "";
        content +=
          this.x +
          "<br>" +
          "FIG count" +
          ":  " +
          '<span style="color:black;font-weight:bold">' +
          this.y +
          "</b>" +
          "</span>";
        return content;
      },
    },
    series: [
      {
        color: "#FDC300",
        data: [],
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    xAxis: {
      categories: [],
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#000000",
          font: "12px Gilroy",
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#000000",
          font: "12px Gilroy",
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  const StateGraph = {
    title: {
      text: `State Wise Member count ${
        loggedInID === "13"
          ? " - FPO Bazaar"
          : sortingData?.program?.length > 0
          ? ` - ${sortingData?.program}`
          : ""
      }   ${selectedState !== "" ? ` - ${selectedState}` : ""} 
       ${selectedDistrict !== undefined ? ` - ${selectedDistrict}` : ""} 
      ${
        graphStartDate !== "Invalid date" && graphEndDate === "Invalid date"
          ? ` - ${graphStartDate}`
          : graphStartDate !== "Invalid date" && graphEndDate !== "Invalid date"
          ? ` - (${graphStartDate} - ${graphEndDate})`
          : ""
      }`,
      style: {
        color: "#189C5C",
        font: "bold 20px Gilroy-Bold",
      },
      align: "left",
      margin: 40,
    },
    tooltip: {
      formatter: function () {
        var content = "";
        content +=
          this.x +
          "<br>" +
          "Member count" +
          ":  " +
          '<span style="color:black;font-weight:bold">' +
          this.y +
          "</b>" +
          "</span>";
        return content;
      },
    },
    series: [
      {
        color: "#FDC300",
        data: [],
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    chart: {
      defaultSeriesType: "bar",
      type: "column",
    },
    xAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#000000",
          font: "12px Gilroy",
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#000000",
          font: "12px Gilroy",
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  const resetFilters = () => {
    setSortingData({
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
      search: "",
    });
    setSelected([]);
    setSelectedState("");
    getStateHead("");
    getRMListMethod("");
    setSelectedStateHead("");
  };

  useEffect(() => {
    let prodramValue = [];
    selected.forEach((val) => prodramValue.push(val.value));
    setSortingData((prevState) => {
      return {
        ...prevState,
        program: prodramValue,
      };
    });
  }, [selected]);

  return (
    <React.Fragment>
      <div className="dashboard-home dashboard-graph">
        <div className="d-flex align-items-start justify-content-between mt-5 flex-wrap w-100">
          {loggedInID === "13" ? null : (
            <div className="d-flex flex-column">
              <div className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20">
                Program
              </div>
              <MultiSelect
                options={loggedInID === "14" ? programListsOne : programLists}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Select",
                  allItemsAreSelected: "All",
                }}
              />
            </div>
          )}
          <NormalSelect
            label="State"
            placeholder="Select"
            name="state"
            value={sortingData?.state}
            options={stateOptions}
            handleChange={handleInput}
          />
          {loggedInID !== "13" && (
            <NormalSelect
              label="State Head"
              placeholder="Select"
              name="stateHead"
              value={sortingData?.stateHead}
              options={stateHeadOptions}
              handleChange={handleInput}
            />
          )}
          <NormalSelect
            label="RM"
            placeholder="Select"
            name="rmName"
            value={sortingData?.rmName}
            options={rmNameOptions}
            handleChange={handleInput}
          />
          <NormalDate
            name="startDate"
            placeholder="YYYY-MM-DD"
            mindate={false}
            maxdate={false}
            timeInput={false}
            label={"Date Range"}
            value={sortingData?.startDate}
            onChange={handleInput}
            isIcon={true}
            className="date-class form-control w-100"
          />
          <NormalDate
            name="endDate"
            placeholder="YYYY-MM-DD"
            mindate={false}
            maxdate={false}
            timeInput={false}
            value={sortingData?.endDate}
            onChange={handleInput}
            isIcon={true}
            disabled={
              sortingData?.startDate === "" ||
              sortingData?.startDate === undefined
            }
            className="date-class form-control w-100 mt-32"
          />
        </div>
        <div className="inside-tab">
          <InputSearch
            placeholder={"Search by FPO"}
            value={searchData}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="reset-button mb-3">
          <NormalButton
            label="Reset"
            mainbg={true}
            onClick={() => {
              resetFilters();
            }}
          />
        </div>
        <div className="row">
          <div className="col-md-6 mb-5">
            <div id="trendGraphContainer"></div>
          </div>
          <div className="col-md-6 mb-5">
            <div id="memberGraphContainer"></div>
          </div>
          <div className="col-md-6 mb-5">
            <div id="FIGGraphContainer"></div>
          </div>
          <div className="col-md-6 mb-5">
            <div id="stateGraphContainer"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDashboardGraphDetailsApi: getDashboardGraphDetails,
      allStateApi: allState,
      getDistrictApiwithStateApi: getDistrictApiwithState,
      getDashboardBusinessStateHeadDetailsApi:
        getDashboardBusinessStateHeadDetails,
      getgetRMListDetailsApi: getgetRMListDetails,
    },
    dispatch
  );
};

let component = DashboardGraphClass;
export const DashboardGraph = connect(
  null,
  mapDispatchToProps
)(withRouter(component));
