import React, { Component } from "react";
import "./uploadcomponent.scss";
import { Modal, ModalBody } from "reactstrap";

import { getFilePathDownload } from "service/api";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getDescriptionData } from "service/helperFunctions";

import moment from "moment";

import {
  getCompanyKycBulkdownload,
  getFinancialBulkdownload,
  getDirectdocBulkdownload,
  getcreditdataBulkdownload,
  getstatutoryBulkdownload,
} from "action/FPOact";

const pdf = require("assets/svg/Documents/pdf.svg");
const eye = require("assets/svg/Documents/eye.svg");
const download = require("assets/svg/Documents/download.svg");
const close = require("assets/svg/Documents/close.svg");
const zoom_in = require("assets/svg/Documents/zoom_in.svg");
const zoom_out = require("assets/svg/Documents/zoom_out.svg");

const arrow_left = require("assets/svg/Documents/arrow-left.svg");
const arrow_right = require("assets/svg/Documents/arrow-right.svg");

export class UploadComponentClass extends Component {
  constructor(props) {
    super();
    this.state = {
      modal: false,
      zoom: 1,
      documents: [],
      currentIndex: 1,
      csvUrl: "",
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps != this.props) {
      this.setState({ documents: this.props.documents });
    }
  }

  toggle = (name) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  componentDidMount() {
    if (this.props.documents !== undefined) {
      this.setState({ documents: this.props.documents });
    }
  }

  toggleTrue = () => {
    this.setState({ modal: true });
  };

  toggleFalse = () => {
    this.setState({ modal: false });
  };

  handleZoomIn = () => {
    let zoomVal = this.state.zoom;
    if (zoomVal < 3) {
      this.setState({
        zoom: zoomVal + 1,
      });
    }
  };

  handleZoomOut = () => {
    let zoomVal = this.state.zoom;
    if (zoomVal > 1) {
      this.setState({
        zoom: zoomVal - 1,
      });
    }
  };

  handlePagination = (direction) => {
    let { currentIndex } = this.state;
    let page = currentIndex;
    let currentPage = eval(`${page} ${direction} ${1}`);
    this.setState({ currentIndex: currentPage });
  };

  getCsvUrl = (documentId) => {
    let { activeTab, fpoId } = this.props;
    let { getDownloadAction } = "";
    if (activeTab == 1) {
      getDownloadAction = this.props.getCompanyKycBulkdownload;
    } else if (activeTab == 2) {
      getDownloadAction = this.props.getstatutoryBulkdownload;
    } else if (activeTab == 3) {
      getDownloadAction = this.props.getDirectdocBulkdownload;
    } else if (activeTab == 4) {
      getDownloadAction = this.props.getFinancialBulkdownload;
    } else {
      getDownloadAction = this.props.getcreditdataBulkdownload;
    }

    if (getDownloadAction !== "") {
      let query = {
        fpoId: fpoId,
        docId: documentId,
      };
      getDownloadAction(query).then((data) => {
        this.setState(
          {
            csvUrl:
              "https://portal.fpogateway.com/fpomgmt/api/v1/download/zip?zipFileName=" +
              data.zipFileName,
          },
          () => {
            document.getElementById("csv-download").click();
            this.setState({ csvUrl: "" });
          }
        );
      });
    }
  };

  render() {
    let { currentIndex, documents, csvUrl } = this.state;

    let { id, activeTab, documentDetail = {} } = this.props;

    let { documentType = "" } = documentDetail;

    return (
      <div className="upload-component d-flex justify-content-center align-items-center ">
        {documents.length == 0 ? (
          <div className="d-flex flex-column align-items-center w-100 h-100 justify-content-center">
            <div className="upload-component-title fs-18">No files here</div>
          </div>
        ) : (
          <div className="w-100 h-100 p-3 rounded d-flex justify-content-between">
            <div className="h-100 d-flex flex-column align-items-center justify-content-center">
              <div>
                {documents.map((list, index) => {
                  return (
                    currentIndex == index + 1 && (
                      <React.Fragment key={index}>
                        <p className="mb-1 text-center font-weight-bold fs-14">
                          {list.reportName !== null
                            ? getDescriptionData(list.reportName, 20)
                            : ""}
                        </p>
                        {activeTab == 4 || activeTab == 5 ? (
                          <>
                            {/* audited financials */}
                            {documentType === "audited_financials" ? (
                              <p className="mb-1 text-center font-weight-bold fs-12">
                                {list.financialFyYear !== null
                                  ? list.financialFyYear
                                  : ""}
                              </p>
                            ) : null}
                            {/* provisional financials */}
                            {documentType === "provisional_financials" ? (
                              <p className="mb-1 text-center font-weight-bold fs-12">
                                {list.financialAsOnDate !== null
                                  ? moment(list.financialAsOnDate).format(
                                      "DD MMMM YYYY"
                                    )
                                  : null}
                              </p>
                            ) : null}
                            {/* debtors aing, top, customers, top suppliers, borrowing profile ,creditor ageing*/}
                            {documentType === "debtors_ageing" ||
                            documentType === "top_customers" ||
                            documentType === "top_suppliers" ||
                            documentType === "borrowing_profile" ||
                            documentType === "creditor_ageing" ? (
                              <p className="mb-1 text-center font-weight-bold fs-12">
                                {list.reportAsOnDate !== null
                                  ? moment(list.reportAsOnDate).format(
                                      "DD MMMM YYYY"
                                    )
                                  : ""}
                              </p>
                            ) : null}
                            {/* Bank statement */}
                            {documentType === "bank_statement" ? (
                              <p className="mb-1 text-center font-weight-bold fs-12">
                                {list.reportFromDate !== null ? (
                                  <>
                                    {moment(list.reportFromDate).format(
                                      "DD MMMM YYYY"
                                    )}{" "}
                                    -{" "}
                                  </>
                                ) : null}
                                {list.reportToDate !== null
                                  ? moment(list.reportToDate).format(
                                      "DD MMMM YYYY"
                                    )
                                  : ""}
                              </p>
                            ) : null}
                            {/* Gst returns */}
                            {documentType === "gst_returns" ? (
                              <p className="mb-1 text-center font-weight-bold fs-12">
                                {list.reportFyYear !== null ? (
                                  <>{list.reportFyYear} to </>
                                ) : null}
                                {list.reportQuarter !== null
                                  ? list.reportQuarter
                                  : ""}
                              </p>
                            ) : null}
                            {/* it returns */}
                            {documentType === "it_returns" ? (
                              <p className="mb-1 text-center font-weight-bold fs-12">
                                {list.reportFyYear !== null
                                  ? list.reportFyYear
                                  : null}
                              </p>
                            ) : null}
                            {/* sales report, purchase report */}
                            {documentType === "sales_report" ||
                            documentType === "purchase_report" ? (
                              <p className="mb-1 text-center font-weight-bold fs-12">
                                {list.reportStartMonth !== null ? (
                                  <>{list.reportStartMonth}, </>
                                ) : null}
                                {list.reportStartYear !== null ? (
                                  <>{list.reportStartYear} to </>
                                ) : null}
                                {list.reportEndMonth !== null ? (
                                  <>{list.reportEndMonth}, </>
                                ) : null}
                                {list.reportEndYear !== null
                                  ? list.reportEndYear
                                  : null}
                              </p>
                            ) : null}
                          </>
                        ) : null}
                        <div className="d-flex justify-content-center">
                          {list.documentName.includes("pdf") ? (
                            <img
                              className="uploaded-img-area"
                              src={pdf}
                              alt="pdf"
                            />
                          ) : (
                            <img
                              className="uploaded-img-area rounded"
                              src={list.documentPath}
                              alt="file"
                            />
                          )}
                        </div>
                      </React.Fragment>
                    )
                  );
                })}
              </div>
              <div>
                {documents.length > 1 ? (
                  <div className="d-flex transform-up">
                    {currentIndex !== 1 ? (
                      <button
                        className="previous-button justify-content-center align-items-center d-flex mr-2"
                        disabled={currentIndex == 1}
                        onClick={(e) =>
                          currentIndex != 1 && this.handlePagination("-")
                        }
                      >
                        <img src={arrow_left} alt="arrow_left" />
                      </button>
                    ) : null}
                    {documents.length !== currentIndex ? (
                      <button
                        className="next-button justify-content-center align-items-center d-flex "
                        disabled={documents.length == currentIndex}
                        onClick={() =>
                          currentIndex != documents.length &&
                          this.handlePagination("+")
                        }
                      >
                        <img src={arrow_right} alt="arrow_left" />
                      </button>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex flex-column justify-content-between align-items-end">
              <div className="d-flex flex-column">
                <img
                  onClick={this.toggleTrue}
                  className="mb-3 cursor-pointer"
                  src={eye}
                  alt="eye"
                />
                {documents.length !== 0 ? (
                  <>
                    {csvUrl === "" ? (
                      <span
                        onClick={() => this.getCsvUrl(id)}
                        className="cursor-pointer"
                      >
                        <img className="mb-3" src={download} alt="download" />
                      </span>
                    ) : (
                      <a
                        href={csvUrl}
                        id="csv-download"
                        download
                        className="text-decoration-none"
                      >
                        <img className="mb-3" src={download} alt="download" />
                      </a>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        )}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleFalse}
          scrollable={true}
          size="lg"
        >
          <ModalBody>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <div>
                  {documents.map(
                    (list, index) =>
                      currentIndex == index + 1 && (
                        <div className="d-flex justify-content-center img-view">
                          {list.documentName.includes("pdf") ? (
                            <iframe
                              className="pdf-view"
                              id="iframepdf"
                              src={list.documentPath}
                            ></iframe>
                          ) : (
                            <img
                              className={
                                this.state.zoom === 1
                                  ? "shadow rounded zoom-1"
                                  : this.state.zoom === 2
                                  ? "shadow rounded zoom-2 "
                                  : "shadow rounded zoom-3 "
                              }
                              src={list.documentPath}
                              alt="file"
                            />
                          )}
                        </div>
                      )
                  )}
                </div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img
                  onClick={this.toggleFalse}
                  className="mb-3 cursor-pointer"
                  src={close}
                  alt="close"
                />
                <img
                  onClick={this.handleZoomOut}
                  className="mb-3"
                  src={zoom_out}
                  alt="zoom_out"
                />
                <img
                  onClick={this.handleZoomIn}
                  className="mb-3"
                  src={zoom_in}
                  alt="zoom_in"
                />
                {documents.length !== 0 ? (
                  <a
                    href={
                      getFilePathDownload +
                      documents[currentIndex - 1].documentUrl
                    }
                    download
                  >
                    <img
                      onClick={this.toggleFalse}
                      className="mb-3"
                      src={download}
                      alt="download"
                    />
                  </a>
                ) : null}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCompanyKycBulkdownload,
      getFinancialBulkdownload,
      getDirectdocBulkdownload,
      getcreditdataBulkdownload,
      getstatutoryBulkdownload,
    },
    dispatch
  );
};
let component = UploadComponentClass;

export const UploadComponent = connect(null, mapDispatchToProps)(component);
