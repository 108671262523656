import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { CommonPageLoader } from "component/common"
import certifying from 'assets/images/certifying-institute.png'
import "./style.scss"
import moment from "moment"
import { PracticeFileCard } from "./PracticeFileCard"
import { getDescriptionData } from "service/helperFunctions"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

export class CoursePreviewPopupClass extends Component {

    constructor() {
        super();
        SwiperCore.use([Navigation]);
    }

    render() {
        let {
            className = 'modal-dialog-centered course-popup',
            isOpen = true,
            toggle,
            isPopupdataLoading = false,
            formFields
        } = this.props;

        let {
            title = '',
            description = '',
            difficultyLevel = '',
            certifyingInstitutions = {},
            category = {},
            Author = {},
            practices = [],
            sections = [],
            thumbnailFileUrl = '',
            skillsCovered = '',
            thumbnailFileName = '',
            releaseDate = ''
        } = formFields

        let { instituteName = '' } = certifyingInstitutions

        let { categoryName = '' } = category

        const params = {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: false,
            draggable: true,
            breakpoints: {
                500: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 1,
                }
            },
            navigation: {
                nextEl: '#academy-next',
                prevEl: '#academy-prev',
            },
        }

        return (
            <>
                <Modal isOpen={isOpen} toggle={toggle} className={className} size="lg">
                    <ModalBody className='p-0'>
                        <div className='d-flex justify-content-beween w-100'>
                            <div className="w-100">
                                <h5 className="text-green-18 fs-22 fw-700 line-height-28">Course details</h5>
                            </div>
                            <i className='icon-close fs-16 cursor-pointer' onClick={toggle} ></i>
                        </div>
                        {!isPopupdataLoading ?
                            <div className="course-content-area">
                                <div className="course-thumbnail py-3">
                                    <img src={thumbnailFileUrl} alt={thumbnailFileName} className="w-100" />
                                </div>
                                {practices.length !== 0 ?
                                    <div className="row mb-3">
                                        <div className='col-sm-12'>
                                            <div className="practice-card">
                                                <div className="d-flex w-100">
                                                    {practices.length > 1 ?
                                                        <div className="d-flex align-items-center">
                                                            <i id="academy-prev" className="icon-keyboard-arrow-left fs-30"></i>
                                                        </div>
                                                        : ''}
                                                    <Swiper {...params} className="w-100">
                                                        {practices.map((list, index) => (
                                                            <SwiperSlide key={index} >
                                                                <PracticeFileCard practiceFile={list} key={index} />
                                                            </SwiperSlide >
                                                        ))}
                                                    </Swiper>
                                                    {practices.length > 1 ?
                                                        <div className="d-flex align-items-center">
                                                            <i id="academy-next" className="icon-keyboard-arrow-right fs-30"></i>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                <div className="row">
                                    <div className="col-9">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex">
                                                <h5 className="fs-20 fw-700 line-height-34 text-green-18">{categoryName}</h5>
                                                <p className="difficulty-level-text pl-3 mb-0 mt-1">
                                                    {difficultyLevel}
                                                </p>
                                            </div>
                                            <h4>{title}</h4>
                                        </div>
                                        <p dangerouslySetInnerHTML={{ __html: description }} ></p>
                                    </div>
                                    <div className="col-3 d-flex align-items-start justify-content-center">
                                        <div className='cours-certifying'>
                                            <img src={certifying} />
                                            <p>{certifyingInstitutions && instituteName}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="name-card">
                                        <div className="pick-letter color">
                                            {Author && Author.authorName ? Author.authorName.substring(0, 1) : ''}
                                        </div>
                                        <div className="fs-16 fw-600 line-height-27 text-black">{Author && Author.authorName ? Author.authorName : ''}</div>
                                    </div>
                                    <div className="">
                                        <p className="text-black-50 fw-600 mb-0">
                                            {'Publish date : '}{moment(releaseDate).format('DD/MM/YYYY')}
                                        </p>
                                    </div>
                                </div>
                                <hr />
                                <div className='courses-detail-description'>
                                    <h4>Skills</h4>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: getDescriptionData(skillsCovered, 125),
                                        }}
                                    ></p>
                                </div>
                                <hr />
                                {sections.length !== 0 ?

                                    <div className="row up-next-section">
                                        <div className="col-12">
                                            <h4 className="text-black-50 fs-20 fw-700 line-height-31 mb-0">Sections</h4>
                                            <div className="row">
                                                {sections.map((data, index) => {
                                                    let { title, chapters = [] } = data
                                                    return (
                                                        <div className="col-6 h-100">
                                                            <div className="bg-white">
                                                                <div key={index} className="up-next-card px-3">
                                                                    <h5 className="fs-18 text-black-50 fw-700 line-height-30 pt-3 text-center">Section - {index + 1}</h5>
                                                                    <div className="row align-items-center">
                                                                        <div className="col-sm-4">
                                                                            <div className="up-next-img-thumb play-center">
                                                                                <img src={thumbnailFileUrl} alt="" srcSet="" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-8">
                                                                            <div className="up-next-content">
                                                                                <h4 className="text-black">{title}</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="courses-video-list m-1">
                                                                    <h5 className="fs-18 text-green-18 fw-700 line-height-30">Chapters</h5>
                                                                    {chapters.map((data, index) => {
                                                                        let { title } = data
                                                                        return (
                                                                            <div key={index} className={`list-of-videos`}>
                                                                                <div className="video-title">{title}</div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                            :
                            <CommonPageLoader />
                        }
                    </ModalBody>
                </Modal >
            </>
        );
    }
}

let component = CoursePreviewPopupClass;

export const CoursePreviewPopup = connect(null, null)(component);