import React, { Component } from "react";
import { Navbar } from "component/common/navbar";
import Sidebar from "component/common/Sidebar";
import { logout } from "service/utilities";
import "assets/scss/layouts/AdminLayout.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { history } from "service/helpers";

export class AdminLayout extends Component {
  state = {
    profilemenu: false,
    menuOpenClass: false,
    authToken: "",
  };

  componentDidMount = () => {
    let getLocation = history.location.pathname
      .split("/")
      .includes("role-mangement");
    this.tokenValidation();
    if (getLocation === false || history.location.pathname === null) {
      localStorage.removeItem("mappingId");
    }
  };

  componentDidUpdate() {
    let getLocation = history.location.pathname
      .split("/")
      .includes("role-mangement");
    if (getLocation === false || history.location.pathname === null) {
      localStorage.removeItem("mappingId");
    }
    window.onbeforeunload = function () {
      localStorage.removeItem("mappingId");
    };
  }

  tokenValidation = () => {
    let authToken = localStorage.getItem("AuthToken");
    if (!authToken) {
      logout();
      // history.push("/");
    } else {
      let id = localStorage.getItem("userRoleId");
      // if (id == 13) {
      //   let path = history.location.pathname.split("/");
      //   if (!path.includes("fpoList") && !path.includes("change-password")) {
      //     history.push("/admin/fpoList");
      //   }
      // }
    }
  };

  handleProfileMenu = (event, active) => {
    event.stopPropagation();
    this.setState({
      profilemenu: active,
    });
  };

  handleSidenav = () => {
    let { menuOpenClass } = this.state;

    this.setState({
      menuOpenClass: !menuOpenClass,
    });
  };

  render() {
    let { children } = this.props;
    let { menuOpenClass } = this.state;
    this.tokenValidation();

    return (
      <div id="main-content" onClick={(e) => this.handleProfileMenu(e, false)}>
        <div className="content-wrapper">
          <Sidebar
            menuOpen={menuOpenClass}
            handleSidenav={this.handleSidenav}
          />
          <Navbar
          // changeProfileState={(e, param) => this.handleProfileMenu(e, param)}
          // active={this.state.profilemenu}
          />
          <div className="content">{children}</div>
        </div>
      </div>
    );
  }
}
