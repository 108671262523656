import { FeedbackApi } from 'service/apiVariables';
import { addQuery } from 'service/helperFunctions';

export const getFeedbackList = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   addQuery(query, FeedbackApi.getFeedbackList);
   return new Promise((resolve, reject) => {
      api({ ...FeedbackApi.getFeedbackList })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get Feedback by Id
export const getFeedbackById = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {

   let { getFeedbackById } = FeedbackApi

   getFeedbackById.id = body.id

   return new Promise((resolve, reject) => {
      api({ ...FeedbackApi.getFeedbackById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
