import React, { Component } from "react";

import { ForgotPassword } from "../../component/auth/login/index";
const Logo = require("../../assets/svg/logo.svg");
export class ForgotPass extends Component {
  render() {
    return (
      <>
        <div className=" d-flex align-items-center flex-column">
          <img className="w-75 mb-4 " src={Logo} alt="Logo" height="75" />
          <ForgotPassword />
        </div>
      </>
    );
  }
}
