import React, { useState } from "react";
import Dropzone from "react-dropzone";
// import { api } from "services/api"
import { Toast } from "service/toast";
import "./style.scss";

import uploaderImg from "assets/images/video-upload.png";

export const DragFileUpload = ({
  label = "Upload",
  children,
  className,
  disabled = false,
  handleFileUpload = "",
  acceptFileFormat = ["png", "jpeg", "svg", "jpg", "pdf"],
  type = "image",
  maxSize = 10000000000000,
  handleFileRemove,
  multiButton = false,
  hasImage,
}) => {
  return (
    <Dropzone
      disabled={disabled}
      onDrop={(files, a) => {
        if (files.length) {
          if (files[0].size < 100 * 1024 * 1024) {
            if (!checkFileValidation(files, acceptFileFormat)) {
              Toast({
                type: "error",
                message: `Please upload ${acceptFileFormat.join("/")} File`,
              });

              return "";
            }

            handleFileUpload(files);

            uploadFile(files).then((res) => {
              if (handleFileUpload) {
                handleFileUpload(res.data);
              }
            });
          } else {
            Toast({
              type: "error",
              message: `Please upload ${acceptFileFormat.join(
                "/"
              )} within 100mb`,
            });
          }
        } else {
          Toast({
            type: "error",
            message: `Please upload ${type} within 10mb`,
          });
        }
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <>
          {multiButton ? (
            <div className={`${className} position-relative `}>
              {children}

              {multiButton ? <input {...getInputProps()} /> : <input />}

              {multiButton ? (
                <div
                  className={`overlay-button d-flex position-absolute ${
                    hasImage ? "hasImage" : ""
                  }`}
                >
                  <div
                    {...getRootProps()}
                    className="uploader-content d-flex align-items-center justify-content-center mx-2"
                  >
                    <img src={uploaderImg} />
                    <span>Upload</span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div
              {...getRootProps()}
              className={`${className} position-relative `}
            >
              {children}

              <input {...getInputProps()} />
            </div>
          )}
        </>
      )}
    </Dropzone>
  );
};

const uploadFile = ([file]) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();

    formData.append("key1", file);
  });
};

const checkFileValidation = (files, validationType) => {
  return files.every((file) => {
    let temp = file.name.split(".");

    let type = temp[temp.length - 1];

    return validationType.indexOf(type) != -1;
  });
};
