import React, { Component } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { NormalInput, NormalEditor, NormalButton, CommonPageLoader } from 'component/common';

import { history } from 'service/helpers';

import SimpleReactValidator from 'simple-react-validator';

import './style.scss';

import { addFaq, updateFaq, getFaqById } from 'action/FaqAct';

export class AddFaqClass extends Component {
   state = {
      formFields: {
         answer: '',
         question: '',
      },
      isLoading: false
   }

   //Validation
   validator = new SimpleReactValidator({
      element: (message) => (
         <span className='error-message text-danger fs-16 pb-3'>{message}</span>
      ),
   });

   //Lifecycles
   componentDidMount() {
      let { id } = this.props;
      if (id) {
         this._getFaqDetails(id);
      }
   }

   _getFaqDetails = (id) => {
      //Get Faq Detials
      this.props.getFaqById({ id }).then((data) => {
         this.setState({
            formFields: {
               answer: data.answer,
               question: data.question,
            },
         });
      });
   };

   handleInput = ({ target: { name, value } }) => {
      //handle inputs

      let { formFields } = this.state;

      formFields[name] = value;

      this.setState({
         formFields,
      });
   };

   onEditorChange = (editor) => {
      let { formFields } = this.state;
      formFields.answer = editor.getData();
      this.setState({
         formFields,
      });
   };

   handleSubmit = () => {
      let { formFields } = this.state;

      let { question, answer } = formFields;

      let { id, addFaq, updateFaq } = this.props;

      if (this.validator.allValid()) {
         this.setState({ isLoading: true })
         let body = {
            question: question,
            answer: answer,
            faqId: '',
         };
         if (id) {
            //Update Faq
            body.faqId = id;
            updateFaq(body).then(() => {
               this.validator.hideMessages();
               history.push('/admin/faq');
               this.setState({ isLoading: false })
            }).catch(() => {
               this.setState({ isLoading: false });
            })
         } else {
            //Add faq
            delete body.faqId;
            addFaq(body).then(() => {
               this.validator.hideMessages();
               history.push('/admin/faq');
               this.setState({ isLoading: false })
            }).catch(() => {
               this.setState({ isLoading: false });
            })
         }
      } else {
         this.validator.showMessages();
         this.forceUpdate();
      }
   };

   render() {
      let { formFields, isLoading } = this.state;

      let { answer, question } = formFields;

      let { id } = this.props;

      return (
         <div className='add-video'>
            <div className='d-flex cursor-pointer pt-2 mt-1 mb-4 back-area'
               onClick={() => history.goBack()}
            >
               <i className='icon-arrow-left fs-24'></i>
               <h5 className='heading-20 pl-4'>{id ? 'Edit' : "Add"} FAQ’S</h5>
            </div>
            {isLoading ?
               <div className='add-video-form'>
                  <CommonPageLoader />
               </div>
               :
               <div className='add-video-form'>
                  <h5 className='fs-19 text-black font-Gilroy-Bold line-height-22 mb-0 mt-2'>
                     {'FAQ details'}
                  </h5>
                  <div className='row'>
                     <div className='col-12'>
                        <NormalInput
                           label='Question'
                           type='text'
                           onChange={(e) => this.handleInput(e)}
                           value={question}
                           placeholder='Enter Question'
                           name='question'
                           required={true}
                        />
                        {this.validator.message(
                           'question',
                           question,
                           'required|max:200'
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-12'>
                        <NormalEditor
                           label='Answer'
                           data={answer}
                           onChange={this.onEditorChange}
                           required={true}
                        />
                        {this.validator.message(
                           'answer',
                           answer,
                           'required|max:700'
                        )}
                     </div>
                  </div>
               </div>
            }
            {!isLoading ?
               <div className='d-flex align-items-center add-faq-buttons'>
                  <div className='ml-auto d-flex'>
                     <NormalButton
                        label='Cancel'
                        outline={true}
                        className='px-4 mr-2 border-none'
                        onClick={() => history.goBack()}
                     />
                     <NormalButton
                        label={id ? 'Update' : "Publish"}
                        mainbg={true}
                        className='px-4'
                        onClick={() => this.handleSubmit()}
                     />
                  </div>
               </div>
               : null}
         </div>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         addFaq,
         updateFaq,
         getFaqById,
      },
      dispatch
   );
};

let component = AddFaqClass;

export const AddFaq = connect(null, mapDispatchToProps)(component);
