import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  NormalButton,
  NormalDate,
  NormalSelect,
  NormalInput,
  PasswordStrengthMeter,
} from "component/common";
import { history } from "service/helpers";
import { fileUpload } from "action/CourseAct";
import {
  allState,
  getcropBusinessInfra,
  getPincodeApiwithStateDistrict,
  getDistrictApiwithState,
  addFPO,
  getAllFinancialYearDropdown,
  getSignUpEntityType,
  emailAndNumberValidation,
} from "action/FPOact";
import moment from "moment";
import { Toast } from "service/toast";
import "./style.scss";
import SimpleReactValidator from "simple-react-validator";
import { getDescriptionData } from "service/helperFunctions";
import { SuccessPopUp } from "./successPopUp";
import { debounceFunction } from "service/debounce";

export class AddFpoClass extends Component {
  state = {
    entityId: "",
    entity_value: "",
    fpoName: "",
    resourseInstution: "",
    promotingInstitution: "",
    logoFileName: "",
    logoFile: "",
    resourseInsitituteLogoFile: "",
    resourseInsitituteLogoFileName: "",
    insitituteLogoFile: "",
    insitituteLogoFileName: "",
    teamLogo: "",
    teamLogoFileName: "",
    village: "",
    isTeamLogoFileuploading: false,
    islogoFileUploading: false,
    isresourseInsitituteLogoFileUploading: false,
    isinstitutelogoFileUploading: false,
    emailId: "",
    password: "",
    officialWebsiteLink: "",
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
    state: "",
    district: "",
    pincode: "",
    districtOptions: [],
    stateOption: [],
    pincodeOptions: [],
    foundedDate: "",
    membership: "",
    villageCovered: 0,
    maleMembers: "",
    femaleMembers: "",
    shareCapitals: 0,
    acre: "",
    turnOver: 0,
    companyCin: "",
    FyearID: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    business_activties: [],
    selected_business_activties: [],
    infrastructure_list: [],
    selected_infrastructure: [],
    crops_list: [],
    license_list: [],
    selected_crops: [],
    selected_license: [],
    cropMapping: [],
    allFinancialYear: [],
    isApiUpdating: false,
    isOpen: false,
    entityList: [],
    entityField: false,
    emailField: false,
    numberField: false,
    CINField: false,
    entityValidate: false,
    emailValidate: false,
    numberValidate: false,
    CINValidate: false,
    societyType: "",
    societyTypeOptions: [],
  };
  //validation
  validator = new SimpleReactValidator({
    validators: {
      password: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  //Lifecycles
  componentDidMount = () => {
    let inputTypeNumbers = document.querySelectorAll("input[type=number]");
    inputTypeNumbers.forEach((list) => {
      list.onwheel = function (event) {
        event.target.blur();
      };
    });

    this.props.allState().then((data) => {
      let StateData = [];

      data.map((list) => {
        StateData.push({ value: list.stateId, label: list.stateName });
      });
      this.setState({
        stateOption: StateData,
      });
    });

    this.props.getcropBusinessInfra().then((data) => {
      this.setState({
        business_activties: data.businessList,
        crops_list: data.cropsList,
        infrastructure_list: data.infrastructure,
        license_list: data.licensedropdown,
      });
    });

    let societyTypeData = [
      { value: "pacs", label: "PACS" },
      { value: "dairy", label: "Dairy Society" },
      { value: "veg_fed", label: "Veg Fed Society" },
      { value: "lamps", label: "LAMPS" },
    ];
    this.setState({
      societyTypeOptions: societyTypeData,
    });

    this.getFinancialYear();
    this._getEntityList();
  };

  getFinancialYear() {
    this.props.getAllFinancialYearDropdown().then((data) => {
      let financialData = [];
      data.financialYearMaster.map((list) => {
        financialData.push({ value: list.fymYear, label: list.fymYear });
      });
      this.setState({
        allFinancialYear: financialData,
      });
    });
  }

  _getEntityList = () => {
    this.props.getSignUpEntityType().then((data) => {
      let entityData = [];
      data.map((list) => {
        entityData.push({ value: list.entityId, label: list.entityName });
      });
      this.setState({
        entityList: entityData,
      });
    });
  };

  //upload Logo File
  uploadLogoFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ logoFile: "" }, () => {
        let { logoFile, logoFileName } = this.state;
        logoFileName = "Uploading ....";
        logoFile = file;
        const config = {
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            document.getElementById(
              "logo-file-upload"
            ).innerText = `Uploading ${percentCompleted}%`;
          },
        };
        const formData = new FormData();
        formData.append("file", file);
        this.setState({ islogoFileUploading: true });

        this.props.fileUpload(formData, config).then((data) => {
          logoFile = data[0].fileUrl;
          logoFileName = data[0].fileName;
          this.setState({
            logoFile,
            islogoFileUploading: false,
            logoFileName,
          });
        });
        this.setState({
          logoFileName,
          logoFile,
        });
      });
    }
  };

  //upload Resourse Institution File
  uploadResourseInstitutionFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ resourseInsitituteLogoFile: "" }, () => {
        let { resourseInsitituteLogoFile, resourseInsitituteLogoFileName } =
          this.state;
        resourseInsitituteLogoFileName = "Uploading ....";
        resourseInsitituteLogoFile = file;

        const formData = new FormData();
        formData.append("file", file);
        this.setState({ isresourseInsitituteLogoFileUploading: true });
        const config = {
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            document.getElementById(
              "resourse-logo-file-upload"
            ).innerText = `Uploading ${percentCompleted}%`;
          },
        };
        this.props.fileUpload(formData, config).then((data) => {
          resourseInsitituteLogoFile = data[0].fileUrl;
          resourseInsitituteLogoFileName = data[0].fileName;
          this.setState({
            resourseInsitituteLogoFile,
            isresourseInsitituteLogoFileUploading: false,
            resourseInsitituteLogoFileName,
          });
        });
        this.setState({
          resourseInsitituteLogoFileName,
          resourseInsitituteLogoFile,
        });
      });
    }
  };

  //upload Institution File
  uploadInstitutionFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ insitituteLogoFile: "" }, () => {
        let { insitituteLogoFile, insitituteLogoFileName } = this.state;
        insitituteLogoFileName = "Uploading ....";
        insitituteLogoFile = file;

        const formData = new FormData();
        formData.append("file", file);
        this.setState({ isinstitutelogoFileUploading: true });
        const config = {
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            document.getElementById(
              "institute-logo-file-upload"
            ).innerText = `Uploading ${percentCompleted}%`;
          },
        };
        this.props.fileUpload(formData, config).then((data) => {
          insitituteLogoFile = data[0].fileUrl;
          insitituteLogoFileName = data[0].fileName;
          this.setState({
            insitituteLogoFile,
            isinstitutelogoFileUploading: false,
            insitituteLogoFileName,
          });
        });
        this.setState({
          insitituteLogoFileName,
          insitituteLogoFile,
        });
      });
    }
  };

  //upload Team Logo File
  uploadTeamLogoFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ teamLogo: "" }, () => {
        let { teamLogo, teamLogoFileName } = this.state;
        teamLogoFileName = "Uploading ....";
        teamLogo = file;

        const formData = new FormData();
        formData.append("file", file);
        this.setState({ isTeamLogoFileuploading: true });
        const config = {
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            document.getElementById(
              "team-logo-file-upload"
            ).innerText = `Uploading ${percentCompleted}%`;
          },
        };
        this.props.fileUpload(formData, config).then((data) => {
          teamLogo = data[0].fileUrl;
          teamLogoFileName = data[0].fileName;
          this.setState({
            teamLogoFileName,
            isTeamLogoFileuploading: false,
            teamLogo,
          });
        });
        this.setState({
          teamLogo,
          teamLogoFileName,
        });
      });
    }
  };

  //handle business Activties
  handlebusinessActivties = (crop) => {
    let { selected_business_activties } = this.state;

    if (
      !selected_business_activties.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      selected_business_activties.push(crop);
      this.setState({ selected_business_activties });
    } else {
      let index = selected_business_activties.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selected_business_activties.splice(index, 1);
        });
      }
    }
  };

  //handle crops
  handlecrops = (crop) => {
    let { selected_crops, cropMapping } = this.state;
    if (
      !selected_crops.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      selected_crops.push(crop);
      cropMapping.push({
        cropId: crop,
        varietyOne: "",
        varietyTwo: "",
        varietyThree: "",
      });
      this.setState({
        selected_crops: selected_crops,
        cropMapping: cropMapping,
      });
    } else {
      let index = selected_crops.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selected_crops.splice(index, 1);
          prevState.cropMapping.splice(index, 1);
        });
      }
    }
  };

  //handle Infrasture
  handleInfrasture = (crop) => {
    let { selected_infrastructure } = this.state;
    if (
      !selected_infrastructure.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      selected_infrastructure.push(crop);
      this.setState({ selected_infrastructure }, () => {});
    } else {
      let index = selected_infrastructure.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selected_infrastructure.splice(index, 1);
        });
      }
    }
  };

  handleLicense = (crop) => {
    let { selected_license } = this.state;
    if (
      !selected_license.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      selected_license.push(crop);
      this.setState({ selected_license });
    } else {
      let index = selected_license.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selected_license.splice(index, 1);
        });
      }
    }
  };

  handleCropVariety = (cropId, e) => {
    const { value, name } = e.target;
    const { cropMapping, selected_crops } = this.state;
    let index = cropMapping.findIndex((list) => {
      return list.cropId == cropId;
    });
    cropMapping[index][name] = value;
    this.setState({
      cropMapping: cropMapping,
    });
  };

  getCropIndex = (cropId) => {
    let id = this.state.cropMapping.findIndex((list) => {
      return list.cropId == cropId;
    });
    return id;
  };

  //handle input change
  handleInput = ({ target: { name, value } }) => {
    let { membership, femaleMembers } = this.state;
    if (name === "state") {
      this.setState(
        {
          [name]: value,
          district: "",
          // pincode: "",
        },
        () => {
          this._getDistrictApiwithState(value);
        }
      );
    } else if (name === "district") {
      this.setState(
        {
          [name]: value,
          // pincode: "",
        },
        () => {
          // this._getPincodeApiwithStateDistrict(value);
        }
      );
    } else if (name === "fpoName") {
      this.setState(
        {
          [name]: value,
          emailField: false,
          numberField: false,
          CINField: false,
          entityField: true,
        },
        () => {
          if (value?.length < 3) {
            this.setState({
              entityValidate: false,
            });
          } else {
            debounceFunction(() => {
              if (value) {
                this.validation(value);
              }
            }, 1000);
          }
        }
      );
    } else if (name === "emailId") {
      this.setState(
        {
          [name]: value,
          emailField: true,
          numberField: false,
          CINField: false,
          entityField: false,
        },
        () => {
          if (value === "") {
            this.setState({
              emailValidate: false,
            });
          } else {
            this.validation(value);
          }
        }
      );
    } else if (name === "mobileNumber") {
      this.setState(
        {
          [name]: value,
          emailField: false,
          numberField: true,
          CINField: false,
          entityField: false,
        },
        () => {
          if (value === "" || value?.length <= 9) {
            this.setState({
              numberValidate: false,
            });
          } else {
            this.validation(value);
          }
        }
      );
    } else if (name === "entityId") {
      this.setState({
        [name]: value,
        CINValidate: false,
        entity_value: "",
      });
    } else if (name === "entity_value") {
      this.setState(
        {
          [name]: value,
          emailField: false,
          numberField: false,
          CINField: true,
          entityField: false,
        },
        () => {
          if (value === "") {
            this.setState({
              CINValidate: false,
            });
          } else {
            this.validation(value);
          }
        }
      );
    } else if (name === "membership") {
      this.setState({
        [name]: value,
        maleMembers: (value - femaleMembers).toString(),
      });
    } else if (name === "femaleMembers") {
      this.setState({
        [name]: value,
        maleMembers: (membership - value).toString(),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  //get district list regarding state selected
  _getDistrictApiwithState = (stateId) => {
    this.props.getDistrictApiwithState({ id: stateId }).then((data) => {
      let districtData = [];

      data.map((list) => {
        districtData.push({ value: list.districtId, label: list.districtName });
      });
      this.setState({
        districtOptions: districtData,
      });
    });
  };

  //get pincode list regarding district selected
  _getPincodeApiwithStateDistrict = (districtId) => {
    this.props
      .getPincodeApiwithStateDistrict({ id: districtId })
      .then((data) => {
        let pinocodeData = [];

        data.map((list) => {
          pinocodeData.push({ value: list.pincodeId, label: list.pincode });
        });
        this.setState({
          pincodeOptions: pinocodeData,
        });
      });
  };

  validation = (value) => {
    let body;
    const { entityId } = this.state;
    const { emailField, numberField, CINField, entityField } = this.state;
    let regx = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(value);
    if (value) {
      if (emailField && regx) {
        body = {
          emailId: value,
        };
      } else if (entityField) {
        body = {
          fpoName: value,
        };
      } else if (numberField && value?.length === 10) {
        body = {
          mobileNumber: value,
        };
      } else if (CINField) {
        if (entityId === "2" && value?.length === 21) {
          body = {
            companyCin: value,
          };
        } else if (entityId === "3") {
          body = {
            cooperative_registration_number: value,
          };
        } else if (entityId === "4") {
          body = {
            trust_registration_number: value,
          };
        }
      } else {
        return null;
      }
      this.props.emailAndNumberValidation(body).then((message) => {
        if (message === "success") {
          if (emailField) {
            this.setState({
              emailValidate: false,
            });
          } else if (entityField) {
            this.setState({
              entityValidate: false,
            });
          } else if (numberField) {
            this.setState({
              numberValidate: false,
            });
          } else if (CINField) {
            this.setState({
              CINValidate: false,
            });
          }
        } else {
          if (emailField) {
            let emailMessage = "This email is already registered";
            if (message === emailMessage) {
              this.setState({
                emailValidate: true,
              });
            } else {
              this.setState({
                emailValidate: false,
              });
            }
          } else if (entityField) {
            let entityMessage = "Fpo already exist";
            if (message === entityMessage) {
              this.setState({
                entityValidate: true,
              });
            } else {
              this.setState({
                entityValidate: false,
              });
            }
          } else if (numberField) {
            let numberMessage = "This mobile number is already registered";
            if (message === numberMessage) {
              this.setState({
                numberValidate: true,
              });
            } else {
              this.setState({
                numberValidate: false,
              });
            }
          } else if (CINField) {
            let cinMessage = "This company CIN is already registered";
            let crnMessage =
              "This cooperative registration number is already registered";
            let trnMessage =
              "This trust registration number is already registered";
            if (message === cinMessage || crnMessage || trnMessage) {
              this.setState({
                CINValidate: true,
              });
            } else {
              this.setState({
                CINValidate: false,
              });
            }
          }
        }
      });
    }
  };

  //handle submit
  handleSubmit = () => {
    const { entityId } = this.state;
    if (entityId === "1") {
      this.validator.fields.entity_value = true;
      this.validator.errorMessages.entity_value = null;
      this.validator.fields.cin = true;
      this.validator.errorMessages.cin = null;
      this.validator.fields.cooperative_registration_number = true;
      this.validator.errorMessages.cooperative_registration_number = null;
      this.validator.fields.trust_registration_number = true;
      this.validator.errorMessages.trust_registration_number = null;
      this.validator.fields.societyType = true;
      this.validator.errorMessages.societyType = null;
    }
    if (entityId === "2") {
      this.validator.fields.cooperative_registration_number = true;
      this.validator.errorMessages.cooperative_registration_number = null;
      this.validator.fields.trust_registration_number = true;
      this.validator.errorMessages.trust_registration_number = null;
      this.validator.fields.societyType = true;
      this.validator.errorMessages.societyType = null;
    }
    if (entityId === "3") {
      this.validator.fields.cin = true;
      this.validator.errorMessages.cin = null;
      this.validator.fields.trust_registration_number = true;
      this.validator.errorMessages.trust_registration_number = null;
    }
    if (entityId === "4") {
      this.validator.fields.cin = true;
      this.validator.errorMessages.cin = null;
      this.validator.fields.cooperative_registration_number = true;
      this.validator.errorMessages.cooperative_registration_number = null;
      this.validator.fields.societyType = true;
      this.validator.errorMessages.societyType = null;
    }
    if (
      this.state.entityValidate ||
      this.state.emailValidate ||
      this.state.numberValidate ||
      this.state.CINValidate
    ) {
      return null;
    }
    if (this.validator.allValid()) {
      let {
        entityId,
        entity_value,
        fpoName,
        promotingInstitution,
        resourseInstitution,
        logoFileName,
        logoFile,
        insitituteLogoFileName,
        insitituteLogoFile,
        resourseInsitituteLogoFileName,
        resourseInsitituteLogoFile,
        teamLogoFileName,
        teamLogo,
        officialWebsite,
        instragamLink,
        twitterLink,
        facebookLink,
        village,
        pincode,
        district,
        state,
        foundedDate,
        membership,
        villageCovered,
        femaleMembers,
        maleMembers,
        acre,
        shareCapitals,
        selected_business_activties,
        selected_crops,
        selected_license,
        selected_infrastructure,
        isinstitutelogoFileUploading,
        islogoFileUploading,
        isresourseInsitituteLogoFileUploading,
        isTeamLogoFileuploading,
        firstName,
        lastName,
        emailId,
        password,
        mobileNumber,
        cropMapping,
        turnOver,
        companyCin,
        FyearID,
        stateOption,
        districtOptions,
        societyType,
      } = this.state;

      if (
        !isinstitutelogoFileUploading &&
        !islogoFileUploading &&
        !isresourseInsitituteLogoFileUploading &&
        !isTeamLogoFileuploading
      ) {
        let id = localStorage.getItem("userRoleId");
        let body = {
          entityId: entityId,
          companyCin: entityId === "2" ? entity_value : "",
          cooperative_society: entityId === "3" ? entity_value : "",
          trust_registration_number: entityId === "4" ? entity_value : "",
          societyType: entityId === "3" ? societyType : "",
          fpoName: fpoName,
          femaleMembers: parseInt(femaleMembers),
          maleMembers: parseInt(maleMembers),
          villagesCovered: parseInt(villageCovered),
          shareCapital: parseInt(shareCapitals),
          totalAverage: parseInt(acre),
          turnOver: parseInt(turnOver),
          pincode: parseInt(pincode),
          state: stateOption.find(
            (stateOpt) => stateOpt.value === parseInt(state)
          )?.label,
          stateId: parseInt(state),
          village: village,
          district: districtOptions.find(
            (districtOpt) => districtOpt.value === parseInt(district)
          )?.label,
          districtId: parseInt(district),
          businessIds: selected_business_activties,
          licenseIds: selected_license,
          cropMapping: cropMapping,
          cropIds: selected_crops,
          infrastructureIds: selected_infrastructure,
          password: password,
          emailId: emailId,
          firstName: firstName,
          membershipBase: membership,
          foundedOn: moment(foundedDate).format("YYYY-MM-DD"),
          mobileNumber: mobileNumber,
        };
        if (id == 13) {
          body.isFederation = 1;
        }
        if (lastName != "") {
          body.lastName = lastName;
        }
        if (logoFile != "") {
          body.fpoLogoURL = logoFile;
          body.fpoLogoName = logoFileName;
        }
        if (promotingInstitution != "") {
          body.promotingInstitution = promotingInstitution;
        }
        if (resourseInstitution != "") {
          body.resourcingInstitution = resourseInstitution;
        }
        if (insitituteLogoFile !== "") {
          body.promotingInstitutionLogo = insitituteLogoFile;
          body.promotingInstitutionLogoName = insitituteLogoFileName;
        }
        if (resourseInsitituteLogoFile !== "") {
          body.ResourcingInstitutionLogoURL = resourseInsitituteLogoFile;
          body.ResourcingInstitutionLogoName = resourseInsitituteLogoFileName;
        }
        if (teamLogo !== "") {
          body.teamPhotoName = teamLogoFileName;
          body.teamPhotoURL = teamLogo;
        }
        if (officialWebsite != "") {
          body.officialWebsiteLink = officialWebsite;
        }
        if (facebookLink != "") {
          body.facebookLink = facebookLink;
        }
        if (twitterLink != "") {
          body.twitterLink = twitterLink;
        }
        if (instragamLink != "") {
          body.instagramLink = instragamLink;
        }
        if (FyearID != "") {
          body.FyearID = FyearID;
        }
        if (companyCin != "") {
          body.companyCin = companyCin;
        }
        this.setState({ isApiUpdating: true });
        this.props
          .addFPO(body)
          .then(() => {
            if (id == 13) {
              this.setState({
                isOpen: true,
                isApiUpdating: false,
              });
            } else {
              history.push("/admin/fpoList");
              this.setState({ isApiUpdating: false });
            }
          })
          .catch(() => {
            this.setState({
              isApiUpdating: false,
            });
          });
      } else {
        Toast({ type: "error", message: "Please wait video is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  toggle = () => {
    history.push("/admin/fpoList");
  };

  render() {
    let {
      fpoName,
      resourseInstution,
      promotingInstitution,
      logoFileName,
      logoFile,
      firstName,
      lastName,
      resourseInsitituteLogoFile,
      resourseInsitituteLogoFileName,
      insitituteLogoFileName,
      teamLogo,
      teamLogoFileName,
      village,
      emailId,
      password,
      officialWebsiteLink,
      facebookLink,
      instagramLink,
      twitterLink,
      state,
      district,
      pincode,
      districtOptions,
      stateOption,
      pincodeOptions,
      foundedDate,
      membership,
      villageCovered,
      maleMembers,
      femaleMembers,
      shareCapitals,
      mobileNumber,
      acre,
      insitituteLogoFile,
      islogoFileUploading,
      isresourseInsitituteLogoFileUploading,
      isinstitutelogoFileUploading,
      isTeamLogoFileuploading,
      business_activties = [],
      selected_business_activties = [],
      infrastructure_list = [],
      selected_infrastructure = [],
      crops_list = [],
      selected_crops = [],
      license_list = [],
      selected_license = [],
      cropMapping = [],
      turnOver,
      companyCin,
      allFinancialYear,
      FyearID,
      isApiUpdating,
      isOpen,
      entityList,
      entityId,
      entity_value,
      societyType,
      societyTypeOptions,
    } = this.state;

    return (
      <Fragment>
        <section>
          <div
            className="d-flex cursor-pointer pt-2 mt-1 mb-4 back-area"
            onClick={() => history.push("/admin/fpoList")}
          >
            <i className="icon-arrow-left fs-24"></i>
            <h5 className="heading-20 pl-4">Add new FPO</h5>
          </div>
          <div className="add-fpo">
            <h5 className="fs-19 text-green-18 font-Gilroy-Bold line-height-23">
              {"General details"}
            </h5>
            <div className="row">
              <div className="col-12">
                <NormalSelect
                  label="Entity Type"
                  placeholder="select"
                  arrow={true}
                  value={entityId}
                  options={entityList}
                  name="entityId"
                  handleChange={(e) => this.handleInput(e)}
                  required={true}
                />
                {this.validator.message("entityId", entityId, "required")}
              </div>
              {entityId === "3" ? (
                <div className="col-12">
                  <NormalSelect
                    label="Cooperative society type"
                    placeholder="select"
                    arrow={true}
                    value={societyType}
                    options={societyTypeOptions}
                    name="societyType"
                    handleChange={(e) => this.handleInput(e)}
                    required={true}
                  />
                  {this.validator.message(
                    "societyType",
                    societyType,
                    "required"
                  )}
                </div>
              ) : null}
              {entityId === "2" || entityId === "3" || entityId === "4" ? (
                <div className="col-12">
                  <NormalInput
                    label={
                      (entityId === "2" && "CIN") ||
                      (entityId === "3" && "Cooperative Registration Number") ||
                      (entityId === "4" && "Trust Registration Number")
                    }
                    type="text"
                    onChange={(e) => this.handleInput(e)}
                    value={entity_value}
                    placeholder="Enter here"
                    name="entity_value"
                    maxLength={
                      entityId === "2"
                        ? 21
                        : entityId === "3" || entityId === "4"
                        ? null
                        : null
                    }
                    required={true}
                  />
                  {this.state.CINValidate && (
                    <span className="error-message text-danger fs-14">
                      {entityId === "2"
                        ? "This CIN is already registered. Please try with some other CIN."
                        : entityId === "3"
                        ? "This CRN is already registered. Please try with some other CRN."
                        : entityId === "4"
                        ? "This TRN is already registered. Please try with some other TRN."
                        : null}
                    </span>
                  )}
                  {entityId !== "1"
                    ? this.validator.message(
                        entityId === "2"
                          ? "cin"
                          : entityId === "3"
                          ? "cooperative_registration_number"
                          : entityId === "4"
                          ? "trust_registration_number"
                          : null,
                        entity_value,
                        entityId === "2"
                          ? "required|alpha_num|min:21|max:21"
                          : entityId === "3"
                          ? "required"
                          : entityId === "4"
                          ? "required|alpha_num"
                          : null
                      )
                    : null}
                </div>
              ) : null}
              <div className="col-12">
                <NormalInput
                  label="Entity name"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={fpoName}
                  placeholder="Enter here"
                  name="fpoName"
                  required={true}
                />
                {this.state.entityValidate && (
                  <span className="error-message text-danger fs-14">
                    This Entity name is already registered. Please try with some
                    other Entity name.
                  </span>
                )}
                {this.validator.message("fpoName", fpoName, "required|max:75")}
              </div>
              <div className="col-6">
                <NormalDate
                  value={foundedDate}
                  name="foundedDate"
                  placeholder="DD/MM/YYYY"
                  onChange={(e) => this.handleInput(e)}
                  mindate={false}
                  maxdate={true}
                  timeInput={false}
                  label={"Founded on"}
                  required={true}
                />
                {this.validator.message("founded on", foundedDate, "required")}
              </div>
              <div className="col-6">
                <NormalInput
                  label="Contact name"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={firstName}
                  placeholder="Enter here"
                  name="firstName"
                  required={true}
                />
                {this.validator.message(
                  "contactName",
                  firstName,
                  "required|max:25"
                )}
              </div>
              {/* <div className="col-6">
                <NormalInput
                  label="Contact last name"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={lastName}
                  placeholder="Enter here"
                  name="lastName"
                />
              </div> */}
              <div className="col-6">
                <NormalInput
                  label="Email id"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={emailId}
                  placeholder="Enter here"
                  name="emailId"
                  required={true}
                />
                {this.state.emailValidate && (
                  <span className="error-message text-danger fs-14">
                    This Email ID is already registered. Please try with some
                    other Email ID.
                  </span>
                )}
                {this.validator.message("emailId", emailId, "required|email")}
              </div>
              <div className="col-6">
                <NormalInput
                  label="Contact number"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={mobileNumber}
                  placeholder="Enter here"
                  name="mobileNumber"
                  required={true}
                  maxLength={10}
                />
                {this.state.numberValidate && (
                  <span className="error-message text-danger fs-14">
                    This Contact number is already registered. Please try with
                    some other Contact number.
                  </span>
                )}
                {this.validator.message(
                  "mobileNumber",
                  mobileNumber,
                  "required|phone|min:10|max:10"
                )}
              </div>
              <div className="col-6">
                <NormalInput
                  label="Village"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={village}
                  placeholder="Enter here"
                  name="village"
                  required={true}
                />
                {this.validator.message("village", village, "required|max:75")}
              </div>
              <div className="col-6">
                <NormalSelect
                  label="State"
                  placeholder="select"
                  arrow={true}
                  value={state}
                  options={stateOption}
                  name="state"
                  handleChange={(e) => this.handleInput(e)}
                  required={true}
                />
                {this.validator.message("state", state, "required")}
              </div>
              <div className="col-6">
                <NormalSelect
                  label="District"
                  placeholder="select"
                  arrow={true}
                  value={district}
                  options={districtOptions}
                  disabled={state == "" ? true : false}
                  name="district"
                  handleChange={(e) => this.handleInput(e)}
                  required={true}
                />
                {this.validator.message("district", district, "required")}
              </div>
              <div className="col-6">
                <NormalInput
                  label="Pincode"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={pincode}
                  placeholder="Enter here"
                  name="pincode"
                  maxLength={6}
                  required={true}
                />
                {this.validator.message(
                  "pincode",
                  pincode,
                  "required|numeric|min:6"
                )}
              </div>
              <div className="col-6">
                <NormalInput
                  label="Password"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={password}
                  placeholder="Enter here"
                  name="password"
                  required={true}
                />
                {this.validator.message(
                  "password",
                  password,
                  "required|min:8|max:15|password"
                )}
                <PasswordStrengthMeter data={password} />
              </div>
              <div className="col-6 upload-area">
                <div className="mb-2 mt-4 pt-2">
                  <div className="fs-17 text-black pb-2 mb-1">
                    {"FPO logo"}
                    {/* <span className="text-danger pl-2 fs-18">*</span> */}
                  </div>
                  <div className="d-flex align-items-center w-100 btn-file-input">
                    <div className="d-flex align-items-center justify-content-start w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className=""
                          accept="image/*"
                          onChange={this.uploadLogoFile}
                        ></input>
                        {islogoFileUploading ? (
                          <div
                            id="logo-file-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : logoFileName == "" ? (
                          "Choose file"
                        ) : (
                          getDescriptionData(logoFileName, 10)
                        )}
                      </button>
                      {logoFileName != "" && !islogoFileUploading ? (
                        <div className="ml-4">
                          <img
                            src={logoFile}
                            alt={logoFileName}
                            className="upload-image-preview"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 upload-area">
                <div className="mb-2 mt-4 pt-2">
                  <div className="fs-17 text-black pb-2 mb-1">
                    {"Team photo"}
                  </div>
                  <div className="d-flex align-items-center w-100 btn-file-input">
                    <div className="d-flex align-items-center justify-content-start w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className=""
                          accept="image/*"
                          onChange={this.uploadTeamLogoFile}
                        ></input>
                        {isTeamLogoFileuploading ? (
                          <div
                            id="team-logo-file-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : teamLogoFileName == "" ? (
                          "Choose file"
                        ) : (
                          getDescriptionData(teamLogoFileName, 10)
                        )}
                      </button>
                      {teamLogoFileName != "" && !isTeamLogoFileuploading ? (
                        <div className="ml-4">
                          <img
                            src={teamLogo}
                            alt={teamLogoFileName}
                            className="upload-image-preview"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <NormalInput
                  label="Promoting institution"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={promotingInstitution}
                  placeholder="Enter here"
                  name="promotingInstitution"
                />
                {this.validator.message(
                  "promotingInstitution",
                  promotingInstitution,
                  "max:75"
                )}
              </div>
              <div className="col-6 upload-area">
                <div className="mb-2 mt-4 pt-2">
                  <div className="fs-17 text-black pb-2 mb-1">
                    {"Promoting institution logo"}
                  </div>
                  <div className="d-flex align-items-center w-100 btn-file-input">
                    <div className="d-flex align-items-center justify-content-start w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className=""
                          accept="image/*"
                          onChange={this.uploadInstitutionFile}
                        ></input>
                        {isinstitutelogoFileUploading ? (
                          <div
                            id="institute-logo-file-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : insitituteLogoFileName == "" ? (
                          "Choose file"
                        ) : (
                          getDescriptionData(insitituteLogoFileName, 10)
                        )}
                      </button>
                      {insitituteLogoFileName != "" &&
                      !isinstitutelogoFileUploading ? (
                        <div className="ml-4">
                          <img
                            src={insitituteLogoFile}
                            alt={insitituteLogoFileName}
                            className="upload-image-preview"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <NormalInput
                  label="Resource institution"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={resourseInstution}
                  placeholder="Enter here"
                  name="resourseInstution"
                />
                {this.validator.message(
                  "resourseInstution",
                  resourseInstution,
                  "max:75"
                )}
              </div>
              <div className="col-6 upload-area">
                <div className="mb-2 mt-4 pt-2">
                  <div className="fs-17 text-black pb-2 mb-1">
                    {"Resource institution logo"}
                  </div>
                  <div className="d-flex align-items-center w-100 btn-file-input">
                    <div className="d-flex align-items-center justify-content-start w-100 btn-file-input">
                      <button className="">
                        <input
                          type="file"
                          className=""
                          accept="image/*"
                          onChange={this.uploadResourseInstitutionFile}
                        ></input>
                        {isresourseInsitituteLogoFileUploading ? (
                          <div
                            id="resourse-logo-file-upload"
                            className="d-flex align-items-center"
                          ></div>
                        ) : resourseInsitituteLogoFileName == "" ? (
                          "Choose file"
                        ) : (
                          getDescriptionData(resourseInsitituteLogoFileName, 10)
                        )}
                      </button>
                      {resourseInsitituteLogoFileName != "" &&
                      !isresourseInsitituteLogoFileUploading ? (
                        <div className="ml-4">
                          <img
                            src={resourseInsitituteLogoFile}
                            alt={resourseInsitituteLogoFileName}
                            className="upload-image-preview"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <NormalInput
                  label="Official website"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={officialWebsiteLink}
                  placeholder="Enter here"
                  name="officialWebsiteLink"
                />
              </div>
              <div className="col-6">
                <NormalInput
                  label="Facebook link"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={facebookLink}
                  placeholder="Enter here"
                  name="facebookLink"
                />
              </div>
              <div className="col-6">
                <NormalInput
                  label="Twitter link"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={twitterLink}
                  placeholder="Enter here"
                  name="twitterLink"
                />
              </div>
              <div className="col-6">
                <NormalInput
                  label="Instagram link"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={instagramLink}
                  placeholder="Enter here"
                  name="instagramLink"
                />
              </div>
              <div className="col-6">
                <NormalInput
                  label="Villages covered"
                  type="number"
                  onChange={(e) => this.handleInput(e)}
                  value={villageCovered}
                  placeholder="Enter here"
                  name="villageCovered"
                />
              </div>
              <div className="col-6">
                <NormalInput
                  label="Membership"
                  type="number"
                  onChange={(e) => this.handleInput(e)}
                  value={membership}
                  placeholder="Enter here"
                  name="membership"
                  required={true}
                />
                {this.validator.message(
                  "membership",
                  membership,
                  "required|numeric|max:10"
                )}
              </div>
              <div className="col-6">
                <NormalInput
                  label="Female members"
                  type="number"
                  onChange={(e) => this.handleInput(e)}
                  value={femaleMembers}
                  placeholder="Enter here"
                  name="femaleMembers"
                  required={true}
                />
                {this.validator.message(
                  "femaleMembers",
                  femaleMembers,
                  "required|numeric|max:10"
                )}
              </div>
              <div className="col-6">
                <NormalInput
                  label="Male members"
                  type="number"
                  onChange={(e) => this.handleInput(e)}
                  value={maleMembers}
                  placeholder="Enter here"
                  name="maleMembers"
                  required={true}
                  readOnly={true}
                />
                {this.validator.message(
                  "maleMembers",
                  maleMembers,
                  "required|numeric|max:10"
                )}
              </div>
              <div className="col-6">
                <NormalInput
                  label="Share capital"
                  type="number"
                  onChange={(e) => this.handleInput(e)}
                  value={shareCapitals}
                  placeholder="Enter here"
                  name="shareCapitals"
                />
              </div>
              <div className="col-6">
                <NormalInput
                  label="Total acreage (in acres)"
                  type="number"
                  onChange={(e) => this.handleInput(e)}
                  value={acre}
                  placeholder="Enter here"
                  name="acre"
                  required={true}
                />
                {this.validator.message(
                  "acre",
                  acre,
                  "required|numeric|max:10"
                )}
              </div>
              <div className="col-6">
                <NormalInput
                  label="Turn Over"
                  type="number"
                  onChange={(e) => this.handleInput(e)}
                  value={turnOver}
                  placeholder="Enter here"
                  name="turnOver"
                />
              </div>
              <div className="col-6">
                <NormalSelect
                  label="Financial Year"
                  placeholder="Select"
                  arrow={true}
                  value={FyearID}
                  options={allFinancialYear}
                  name="FyearID"
                  handleChange={(e) => this.handleInput(e)}
                />
              </div>
              {/* <div className="col-6">
                <NormalInput
                  label="Company CIN"
                  type="text"
                  onChange={(e) => this.handleInput(e)}
                  value={companyCin}
                  placeholder="Enter here"
                  name="companyCin"
                />
              </div> */}
            </div>
            {business_activties.length !== 0 ? (
              <div className="row">
                <div className="col-12">
                  <p className="selection-area">Business activities</p>
                  {this.validator.message(
                    "business activities",
                    selected_business_activties,
                    "required|array"
                  )}
                  <div className="flex-wrap d-flex">
                    {business_activties.map((activity, index) => {
                      return (
                        <div
                          key={index}
                          className={`d-flex flex-column ${
                            selected_business_activties.find((s_crop) => {
                              return s_crop === activity.businessId;
                            }) !== undefined
                              ? "activity-selected mb-3"
                              : "activity-container mb-3"
                          } `}
                          onClick={() => {
                            this.handlebusinessActivties(activity.businessId);
                            this.forceUpdate();
                          }}
                        >
                          <img
                            className="crops-container-img"
                            src={activity.businessIconUrl}
                            alt="crops"
                          />
                          <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                            {activity.businessName}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
            {crops_list.length !== 0 ? (
              <div className="row">
                <div className="col-12">
                  <p className="selection-area">Crops</p>
                  {this.validator.message(
                    "crops",
                    selected_crops,
                    "required|array"
                  )}
                  <div className="flex-wrap d-flex">
                    {crops_list.map((crop, index) => {
                      return (
                        <div
                          key={index}
                          className={`d-flex flex-column ${
                            selected_crops.find((s_crop) => {
                              return s_crop === crop.cropId;
                            }) !== undefined
                              ? "activity-selected mr-3 mb-3"
                              : "activity-container mr-3 mb-3"
                          } `}
                        >
                          <div
                            onClick={() => {
                              this.handlecrops(crop.cropId);
                              this.forceUpdate();
                            }}
                          >
                            <img
                              className="crops-container-img"
                              src={crop.cropIconUrl}
                              alt="crops"
                            />
                            <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                              {crop.cropName}
                            </p>
                          </div>
                          <div>
                            <input
                              placeholder="variety 1"
                              name="varietyOne"
                              onChange={this.handleCropVariety.bind(
                                this,
                                crop.cropId
                              )}
                              value={
                                cropMapping[this.getCropIndex(crop.cropId)]
                                  ?.varietyOne
                              }
                            />
                            <input
                              placeholder="variety 2"
                              name="varietyTwo"
                              onChange={this.handleCropVariety.bind(
                                this,
                                crop.cropId
                              )}
                              value={
                                cropMapping[this.getCropIndex(crop.cropId)]
                                  ?.varietyTwo
                              }
                            />
                            <input
                              placeholder="variety 3"
                              name="varietyThree"
                              onChange={this.handleCropVariety.bind(
                                this,
                                crop.cropId
                              )}
                              value={
                                cropMapping[this.getCropIndex(crop.cropId)]
                                  ?.varietyThree
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}

            {infrastructure_list.length !== 0 ? (
              <div className="row">
                <div className="col-12">
                  <p className="selection-area">Infrastructure</p>
                  {this.validator.message(
                    "infrastructure",
                    selected_infrastructure,
                    "required|array"
                  )}
                  <div className="flex-wrap d-flex">
                    {infrastructure_list.map((crop, index) => {
                      return (
                        <div
                          key={index}
                          className={`d-flex flex-column ${
                            selected_infrastructure.find((s_crop) => {
                              return s_crop === crop.infrastructureId;
                            }) !== undefined
                              ? "activity-selected mb-3"
                              : "activity-container mb-3"
                          } `}
                          onClick={() => {
                            this.handleInfrasture(crop.infrastructureId);
                            this.forceUpdate();
                          }}
                        >
                          <img
                            className="crops-container-img"
                            src={crop.InfrastructureIconUrl}
                            alt="crops"
                          />
                          <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                            {crop.infrastructureName}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
            {license_list.length !== 0 ? (
              <div className="row">
                <div className="col-12">
                  <p className="selection-area">License</p>
                  {this.validator.message(
                    "license",
                    selected_license,
                    "required|array"
                  )}
                  <div className="flex-wrap d-flex">
                    {license_list.map((crop, index) => {
                      return (
                        <div
                          key={index}
                          className={`d-flex flex-column ${
                            selected_license.find((s_crop) => {
                              return s_crop === crop.id;
                            }) !== undefined
                              ? "activity-selected mb-3"
                              : "activity-container mb-3"
                          } `}
                          onClick={() => {
                            this.handleLicense(crop.id);
                            this.forceUpdate();
                          }}
                        >
                          <img
                            className="crops-container-img"
                            src={crop.licenseIconUrl}
                            alt="crops"
                          />
                          <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                            {crop.licenseName}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="d-flex align-items-center add-buttons">
            {isApiUpdating ? (
              <div className="ml-auto d-flex">
                <NormalButton
                  label="Cancel"
                  outline={true}
                  className="px-4 mr-2 border-none"
                />
                <NormalButton
                  label={"Save & next"}
                  mainbg={true}
                  className="px-4"
                />
              </div>
            ) : (
              <div className="ml-auto d-flex">
                <NormalButton
                  label="Cancel"
                  outline={true}
                  className="px-4 mr-2 border-none"
                  onClick={() => history.goBack()}
                />
                <NormalButton
                  label={"Save & next"}
                  mainbg={true}
                  className="px-4"
                  onClick={this.handleSubmit}
                />
              </div>
            )}
          </div>
        </section>
        <SuccessPopUp isOpen={isOpen} toggle={this.toggle} />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
      allState,
      getcropBusinessInfra,
      getPincodeApiwithStateDistrict,
      getDistrictApiwithState,
      addFPO,
      getAllFinancialYearDropdown,
      getSignUpEntityType,
      emailAndNumberValidation,
    },
    dispatch
  );
};

export const AddFpo = connect(null, mapDispatchToProps)(AddFpoClass);
