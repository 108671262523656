const routers = [
  {
    path: "/",
    exact: true,
    redirect: "/login",
  },
  {
    component: "AuthLayout",
    path: "/login",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "Login",
        path: "/",
        auth: false,
        exact: true,
      },
      {
        component: "ForgotPass",
        path: "/forgot-pass",
        auth: false,
        exact: true,
      },
      {
        component: "ResetPass",
        path: "/reset-pass",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "AdminLayout",
    path: "/admin",
    auth: true,
    exact: false,
    childrens: [
      {
        component: "Dashboard",
        path: "/dashboard",
        auth: false,
        exact: true,
      },
      {
        component: "RoboCallAndSmsPage",
        path: "/robocall-sms",
        auth: false,
        exact: true,
      },
      {
        component: "AcademyDashboard",
        path: "/academydashboard",
        auth: false,
        exact: true,
      },
      {
        component: "RequestFormList",
        path: "/requestForm",
        auth: false,
        exact: true,
      },
      {
        component: "CourseManagement",
        path: "/courseManagement",
        auth: false,
        exact: true,
      },
      {
        component: "FpoManagement",
        path: "/fpomanagement",
        auth: false,
        exact: true,
      },
      {
        component: "ViewCourse",
        path: "/courseManagement/view/1",
        auth: false,
        exact: true,
      },
      {
        component: "AuthorList",
        path: "/courseManagement/author",
        auth: false,
        exact: true,
      },
      {
        component: "CertifingList",
        path: "/courseManagement/certifingList",
        auth: false,
        exact: true,
      },
      {
        component: "AddCourse",
        path: "/addCourse/courseDetail",
        auth: false,
        exact: true,
      },
      {
        component: "AddCourse",
        path: "/addCourse/courseDetail/:id",
        auth: false,
        exact: true,
      },
      {
        component: "AddCourse",
        path: "/addCourse/section",
        auth: false,
        exact: true,
      },
      {
        component: "AddCourse",
        path: "/addCourse/section/:id",
        auth: false,
        exact: true,
      },
      {
        component: "AddCourse",
        path: "/addCourse/lessons",
        auth: false,
        exact: true,
      },
      {
        component: "AddCourse",
        path: "/addCourse/lessons/:id",
        auth: false,
        exact: true,
      },
      {
        component: "AddCourse",
        path: "/addCourse/quizquestions",
        auth: false,
        exact: true,
      },
      {
        component: "AddCourse",
        path: "/addCourse/quizquestions/:id",
        auth: false,
        exact: true,
      },
      {
        component: "AddVideo",
        path: "/addCourse/addVideo",
        auth: false,
        exact: true,
      },
      {
        component: "AddVideo",
        path: "/addCourse/addVideo/:id",
        auth: false,
        exact: true,
      },
      {
        component: "AddBlog",
        path: "/addCourse/addBlog",
        auth: true,
        exact: true,
      },
      {
        component: "AddBlog",
        path: "/addCourse/addBlog/:id",
        auth: true,
        exact: true,
      },
      {
        component: "AddIntroducingVideos",
        path: "/addCourse/infomativevideo",
        auth: true,
        exact: true,
      },
      {
        component: "AddIntroducingVideos",
        path: "/addCourse/infomativevideo/:id",
        auth: true,
        exact: true,
      },
      {
        component: "ContentManagement",
        path: "/contentManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddContent",
        path: "/addContent",
        auth: false,
        exact: true,
      },
      {
        component: "AddContent",
        path: "/editContent/:id",
        auth: false,
        exact: true,
      },
      {
        component: "AddEvent",
        path: "/addEvent",
        auth: false,
        exact: true,
      },
      {
        component: "AddEvent",
        path: "/editEvent/:id",
        auth: false,
        exact: true,
      },
      {
        component: "AddStories",
        path: "/addstories",
        auth: false,
        exact: true,
      },
      {
        component: "AddStories",
        path: "/editstories/:id",
        auth: false,
        exact: true,
      },
      {
        component: "AddTestimonial",
        path: "/addtestimonial",
        auth: false,
        exact: true,
      },
      {
        component: "AddTestimonial",
        path: "/edittestimonial/:id",
        auth: false,
        exact: true,
      },
      {
        component: "AddReport",
        path: "/addReport",
        auth: false,
        exact: true,
      },
      {
        component: "AddReport",
        path: "/editReport/:id",
        auth: false,
        exact: true,
      },
      {
        component: "Forums",
        path: "/forums",
        auth: false,
        exact: true,
      },
      {
        component: "CreateForum",
        path: "/forums/create",
        auth: false,
        exact: true,
      },
      {
        component: "CreateForum",
        path: "/forums/edit/:id",
        auth: false,
        exact: true,
      },
      {
        component: "Faq",
        path: "/faq",
        auth: false,
        exact: true,
      },
      {
        component: "AddFAQ",
        path: "/faq/addfaq",
        auth: false,
        exact: true,
      },
      {
        component: "AddFAQ",
        path: "/faq/editfaq/:id",
        auth: false,
        exact: true,
      },
      {
        component: "AddTaskInCalender",
        path: "/compliance-calender/add-task",
        auth: false,
        exact: true,
      },
      {
        component: "AddTaskInCalender",
        path: "/compliance-calender/edit-task/:id",
        auth: false,
        exact: true,
      },
      {
        component: "CalenderList",
        path: "/compliance-calender",
        auth: false,
        exact: true,
      },
      {
        component: "Feedback",
        path: "/feedbacks",
        auth: false,
        exact: true,
      },
      {
        component: "FPOlist",
        path: "/fpoList",
        auth: false,
        exact: true,
      },
      {
        component: "AddFPO",
        path: "/fpoList/add",
        auth: false,
        exact: true,
      },
      {
        component: "ViewFPO",
        path: "/fpoList/view/:id",
        auth: false,
        exact: true,
      },
      {
        component: "RoleManagement",
        path: "/role-mangement",
        auth: false,
        exact: true,
      },
      {
        component: "ChangePasswordPostLogin",
        path: "/change-password",
        auth: false,
        exact: true,
      },
    ],
  },
];

export default routers;
