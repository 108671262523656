import React, { Component } from "react";

export class AuthLayout extends Component {
  // constructor() {
  //     super();
  // }

  render() {
    let { children } = this.props;

    return (
      <>
        <div className="center-view-login">
          <div className="card shadow rounded">{children}</div>
        </div>
      </>
    );
  }
}
