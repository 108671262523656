import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CommonPageLoader, NormalButton, NormalDate, NormalSelect } from "component/common";
import { Modal, ModalBody } from "reactstrap";
import Switch from "react-switch";
import SimpleReactValidator from "simple-react-validator";
import AsyncSelect from 'react-select/async';
import moment from "moment";

import {
  getFarmerCrop,
  postSubscribeAlerts
} from "action/FPOact";

import "./ViewSubcribePopup.scss";

export class ViewSubcribePopupClass extends Component {
  state = {
    selLang: "",
    hasSubscribe: true,
    weatherAlerts: true,
    mandiAlerts: false,
    "selCrop1": null,
    "selCrop2": null,
    "selCrop3": null,
    startDate: null,
    endDate: null,
    timeSlotsArr: [],
    selTime: ""
  };

  componentDidMount() {
    let timeStops = this.getTimeStops('06:00', '23:59');
    this.setState({
      timeSlotsArr: timeStops
    })
  }

  //validation
  validator = new SimpleReactValidator({
    validators: {
      hasSubscribed: {
        message: "You need to subscribe to at least 1 of the above alerts.",
        rule: function (val, params) {
          return val === false;
        },
      }
    },
    element: (message) => (
      <span className="error-message text-danger fs14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  getTimeStops = (start, end) => {
    var startTime = moment(start, 'HH:mm');
    var endTime = moment(end, 'HH:mm');

    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day');
    }

    var timeStops = [];

    while (startTime <= endTime) {
      let timeStr = new moment(startTime).format('HH:mm A');
      let timeVal = new moment(startTime).format('HH:mm')
      timeStops.push({ label: timeStr, value: timeVal });
      startTime.add(30, 'minutes');
    }
    return timeStops;
  }

  handleWeatherSubscribe = () => {
    this.setState({
      weatherAlerts: !this.state.weatherAlerts,
      hasSubscribe: !this.state.weatherAlerts || this.state.mandiAlerts,
      mandiAlerts: false,
    })
  }
  handleMandiSubscribe = () => {
    this.setState({
      mandiAlerts: !this.state.mandiAlerts,
      hasSubscribe: this.state.weatherAlerts || !this.state.mandiAlerts,
      weatherAlerts: false
    })
  }

  //handle dropdown change
  handleInputChange = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value,
      }
    );
  };

  //handle submit
  handleSubmit = () => {
    if (this.validator.allValid()) {
      let {
        selLang,
        weatherAlerts,
        mandiAlerts,
        selCrop1,
        selCrop2,
        selCrop3,
        startDate,
        endDate,
        selTime
      } = this.state;
      let crops = [];
      if (mandiAlerts) {
        if (selCrop1) {
          crops.push(selCrop1.name);
        }
        if (selCrop2) {
          crops.push(selCrop2.name);
        }
        if (selCrop3) {
          crops.push(selCrop3.name);
        }
      }
      let payload = {

        "fpo": this.props.selFpo.fpoName,
        "target": "3",
        "fpo_preferred_lang": selLang,
        "crops": [
          ...crops
        ],
        "alertType": mandiAlerts ? "mandi" : "weather",
        "mandi_alert": mandiAlerts,
        "weather_alert": weatherAlerts,
        "start_date": startDate,
        "end_date": endDate,
        "time": selTime + ':00'
      };
      this.props.postSubscribeAlerts({
        id: this.props.selFpo.fpoId,
        payload
      }).then((data) => {
        this.props.updateData(data.data)
        this.props.toggle();
      });

    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  //

  // handle selection
  handleChange = (value, type) => {
    this.setState({
      [type]: value
    })
  }

  // load options using API call
  loadOptions = (inputValue) => {
    if (inputValue === '') {
      return new Promise((resolve) => resolve([]));
    } else {
      return this.props.getFarmerCrop({ name: inputValue }).then(res => {
        return res.data;
      });
    }
  };

  render() {
    let {
      className = "modal-dialog-centered fpo-popups fpo-subScr",
      isOpen = true,
      toggle,
      selFpo,
      langOptions,
    } = this.props;

    let {
      selLang,
      hasSubscribe,
      weatherAlerts,
      mandiAlerts,
      selCrop1,
      startDate,
      endDate,
      timeSlotsArr,
      selTime
    } = this.state;

    return (
      <>
        <Modal isOpen={isOpen} toggle={toggle} className={className} size="md">
          <ModalBody className="p-0">
            <div className="d-flex justify-content-end">

              <i
                className="icon-close fs-16 cursor-pointer"
                onClick={toggle}
              ></i>
            </div>
            <div className="">
              {
                selFpo && (
                  <>
                    <div className="d-flex flex-column mb-3">
                      <div className="font-weight-bold">{selFpo.fpoName}</div>
                      <div className="fs-12">FPO Name</div>
                    </div>
                    <div className="mb-3 selection-area">
                      <div className="d-flex flex-column">
                        <NormalSelect
                          className={'form-control select-form-control w-50'}
                          placeholder={"Choose a Language"}
                          value={selLang}
                          label={"Alert Language"}
                          required={true}
                          //arrow={true}
                          options={langOptions}
                          name="selLang"
                          handleChange={this.handleInputChange}
                        />
                        {this.validator.message(
                          'Alert Language',
                          selLang,
                          'required'
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="mb-2">
                        Subscribe to Alerts<span className='text-danger pl-1 fs-18'>*</span>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div class="form-check">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" name="subscribeAlerts" checked={weatherAlerts} onChange={this.handleWeatherSubscribe} /> Weather Alerts
                        </label>
                      </div>
                      <div class="form-check pl-5">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" name="subscribeAlerts" checked={mandiAlerts}  onChange={this.handleMandiSubscribe} /> Mandi Alerts
                        </label>
                      </div>
                      
                    </div>
                    {this.validator.message(
                      '',
                      !hasSubscribe,
                      `required|hasSubscribed`
                    )}
                    <div className={mandiAlerts ? "" : "d-none"}>
                      <div className="mb-1 font-weight-bold mt-3">
                        Choose atleast one crop for Mandi alerts (if applicable)
                      </div>
                      <div className="d-flex flex-wrap">
                        <div className="d-flex flex-column m-1 w-45">
                          <p className="mb-0 fs-14 line-height-17 pr-3">
                            Crop 1
                          </p>
                          <AsyncSelect
                            placeholder={"Type here..."}
                            cacheOptions
                            defaultOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e.id}
                            loadOptions={this.loadOptions}
                            onChange={(value) => this.handleChange(value, 'selCrop1')}
                          />
                          {this.validator.message(
                            'atleast one crop for Mandi alerts',
                            mandiAlerts && selCrop1,
                            'required'
                          )}
                        </div>
                        <div className="d-flex flex-column m-1 w-45">
                          <p className="mb-0 fs-14 line-height-17 pr-3">
                            Crop 2
                          </p>
                          <AsyncSelect
                            placeholder={"Type here..."}
                            cacheOptions
                            defaultOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e.id}
                            loadOptions={this.loadOptions}
                            onChange={(value) => this.handleChange(value, 'selCrop2')}
                          />
                        </div>
                        <div className="d-flex flex-column m-1 w-45">
                          <p className="mb-0 fs-14 line-height-17 pr-3">
                            Crop 3
                          </p>
                          <AsyncSelect
                            placeholder={"Type here..."}
                            cacheOptions
                            defaultOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e.id}
                            loadOptions={this.loadOptions}
                            onChange={(value) => this.handleChange(value, 'selCrop3')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <NormalDate
                          value={startDate}
                          name="startDate"
                          placeholder="DD/MM/YYYY"
                          onChange={this.handleInputChange}
                          mindate={false}
                          maxdate={false}
                          timeInput={false}
                          label={"Start Date"}
                          required={true}
                        />
                        {this.validator.message("date", startDate, "required")}
                      </div>

                      <div className="col-6">
                        <NormalDate
                          value={endDate}
                          name="endDate"
                          placeholder="DD/MM/YYYY"
                          onChange={this.handleInputChange}
                          mindate={startDate}
                          maxdate={false}
                          timeInput={false}
                          label={"End Date"}
                          required={true}
                        />
                        {this.validator.message("date", endDate, "required")}
                      </div>
                    </div>
                    <div className="mb-4 mt-4 selection-area">
                      <div className="d-flex flex-column">
                        <NormalSelect
                          className={'form-control select-form-control w-50'}
                          placeholder={"Choose a Preferred Time"}
                          value={selTime}
                          label={"Preferred Time"}
                          required={true}
                          //arrow={true}
                          options={timeSlotsArr}
                          name="selTime"
                          handleChange={this.handleInputChange}
                        />
                        {this.validator.message(
                          'Preferred Time',
                          selTime,
                          'required'
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-end mt-4" >
                      <NormalButton
                        className="cancel"
                        outline={true}
                        label="Cancel"
                        mainbg={true}
                        onClick={toggle}
                      />
                      <div className="ml-2">
                        <NormalButton
                          label="Submit"
                          mainbg={true}
                          onClick={this.handleSubmit}
                        />
                      </div>
                    </div>
                  </>
                )
              }

            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFarmerCrop,
      postSubscribeAlerts
    },
    dispatch
  );
};

let component = ViewSubcribePopupClass;

export const ViewSubcribePopup = connect(null, mapDispatchToProps)(component);