import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./sidebar.scss";
import logo from "../../../assets/svg/logowhite.svg";
import Expandedicon from "assets/svg/sidebar/bar-icon-expanded.svg";
import CollapsedIcon from "assets/svg/sidebar/bar-icon-collapsed.svg";
import { getSubAdmin } from "service/helperFunctions";
export default class Sidebar extends Component {
  state = {
    navLinks: [
      {
        to: "/admin/dashboard",
        label: "Dashboard",
        iconName: "menu",
        isSubAdmin: true,
      },
      {
        to: "/admin/robocall-sms",
        label: "Robocall / SMS",
        iconName: "menu",
        isSubAdmin: false,
      },
      {
        to: "/admin/academydashboard",
        label: "Academy dashboard",
        iconName: "menu",
        isSubAdmin: false,
      },
      {
        to: "/admin/courseManagement",
        label: "Academy management",
        iconName: "book-open",
        isSubAdmin: false,
      },
      {
        to: "/admin/fpoList",
        label: "FPO list",
        iconName: "users",
        isSubAdmin: true,
      },
      {
        to: "/admin/role-mangement",
        label: "RM-FPO Mapping",
        iconName: "user-check",
        isSubAdmin: false,
      },
      {
        to: "/admin/contentManagement",
        label: "Content management",
        iconName: "layout",
        isSubAdmin: false,
      },
      {
        to: "/admin/fpomanagement",
        label: "Management module",
        iconName: "users",
        isSubAdmin: false,
      },
      {
        to: "/admin/compliance-calender",
        label: "Compliance calendar",
        iconName: "mail",
        isSubAdmin: false,
      },
      {
        to: "/admin/forums",
        label: "Forums",
        iconName: "message",
        isSubAdmin: false,
      },
      {
        to: "/admin/feedbacks",
        label: "Feedbacks",
        iconName: "thumbs-up",
        isSubAdmin: false,
      },
      {
        to: "/admin/faq",
        label: "FAQ’s",
        iconName: "faq",
        isSubAdmin: false,
      },
    ],
  };

  componentDidMount() {
    let id = localStorage.getItem("userRoleId");
    if (id == 13 || id == 16) {
      let sideMenu = [
        {
          to: "/admin/dashboard",
          label: "Dashboard",
          iconName: "menu",
          isSubAdmin: true,
        },
        {
          to: "/admin/fpoList",
          label: "FPO list",
          iconName: "users",
          isSubAdmin: true,
        },
      ];
      this.setState({
        navLinks: sideMenu,
      });
    }
  }

  render() {
    let { navLinks } = this.state;
    let { menuOpen, handleSidenav } = this.props;

    return (
      <>
        {!menuOpen ? (
          <div
            className={`left-menu bg-site-primary ${menuOpen ? "open" : ""}`}
          >
            <div className="sidebar-logo">
              <img src={logo} alt="logo" height="50" width="100" />
              <img
                onClick={() => handleSidenav()}
                src={Expandedicon}
                alt="logo"
                height="25"
                width="25"
                className="cursor-pointer expand-icon"
              />
            </div>
            <PerfectScrollbar>
              <ul>
                {navLinks.map(({ to, label, iconName, isSubAdmin }, index) => {
                  let status = getSubAdmin();
                  return !status ? (
                    <li key={index}>
                      <NavLink to={to} className="nav-link py-3">
                        <div className="sidebar-menu">
                          <div className="menu-icon text-left">
                            <span className={`icon-${iconName} fs-24`} />
                          </div>
                          <span className="sidebar-menu-desc fs-16">
                            {label}
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  ) : status === isSubAdmin ? (
                    <li key={index}>
                      <NavLink to={to} className="nav-link py-3">
                        <div className="sidebar-menu">
                          <div className="menu-icon text-left">
                            <span className={`icon-${iconName} fs-24`} />
                          </div>
                          <span className="sidebar-menu-desc fs-16">
                            {label}
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  );
                })}
              </ul>
            </PerfectScrollbar>
          </div>
        ) : (
          <div
            className={`sidemenu-overlay ${menuOpen ? "open" : ""}`}
            onClick={() => handleSidenav()}
          >
            <img
              src={CollapsedIcon}
              alt="logo"
              height="25"
              width="25"
              className="cursor-pointer"
            />
          </div>
        )}
      </>
    );
  }
}
