import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import fpoBazaar from "assets/images/fpobazaar.png";
import {
  TableWrapper,
  CommonPageLoader,
  NormalButton,
  InputSearch,
  NormalSelect,
  DeletePopup,
} from "component/common";
import {
  getDescriptionData,
  getSubAdmin,
  dateDiff,
} from "service/helperFunctions";
import { debounceFunction } from "service/debounce";
import { history } from "service/helpers";
import {
  getFpoList,
  deleteFpoById,
  allState,
  editAccessFPO,
  updateFederationType,
} from "action/FPOact";
import "../FpoList/style.scss";
import "./style.scss";

export class ExistingFpoClass extends Component {
  state = {
    adminHeaderDetails: [
      { label: "Date of Registration" },
      { label: "Number of Days" },
      { label: "FPO ID" },
      { label: "FPO name" },
      { label: "Vertical" },
      // { label: "Contact person name" },
      { label: "State" },
      { label: "Email" },
      { label: "Mobile" },
      { label: "Members Uploaded" },
      { label: "Action" },
    ],
    otherHeaderDetails: [
      { label: "Date of Registration" },
      { label: "Number of Days" },
      { label: "FPO ID" },
      { label: "FPO name" },
      // { label: "FPO Bazaar" },
      // { label: "Contact person name" },
      { label: "State" },
      { label: "Email" },
      { label: "Mobile" },
      { label: "Members Uploaded" },
      { label: "Action" },
    ],
    pageMeta: {},
    fpoList: [],
    isloading: false,
    sortValue: "",
    sortOptions: [
      // { label: "All", value: "All" },
      { label: "FPO ID ascending", value: "ASC" },
      { label: "FPO ID descending", value: "DESC" },
    ],
    stateOption: [{ label: "All", value: "All" }],
    verticalOption: [
      { label: "Farmer Network", value: 0 },
      { label: "FPO Bazaar", value: 1 },
    ],
    isDeletePopup: false,
    fpoId: "",
    filterValue: "",
    activeTab: "1",
  };

  toggle = (tab) => {
    let { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  //Life cycle
  componentDidMount() {
    this._getFpoList(1);
    this._getAllState();
  }

  //get fpo list
  _getFpoList = (page) => {
    this.setState({ isloading: true });
    let { searchValue, sortValue, filterValue } = this.state;
    let body = {
      page,
      search: searchValue,
      sortBy: "",
      filterBy: "",
    };
    if (sortValue !== "All") {
      body.sortBy = sortValue;
    }
    if (filterValue !== "All") {
      body.filterBy = filterValue;
    }
    this.props.getFpoList(body).then((data) => {
      this.setState({
        isloading: false,
        pageMeta: data.pageMeta,
        fpoList: data.results,
      });
    });
  };

  //get all state
  _getAllState = () => {
    let { stateOption } = this.state;
    this.props.allState().then((data) => {
      data.map((list) => {
        stateOption.push({ value: list.stateId, label: list.stateName });
      });
      this.setState({
        stateOption,
      });
    });
  };

  handlePageChange = (page) => {
    // page change
    this._getFpoList(page);
  };

  handleVerticalChange = (event, index, options, fpoId) => {
    const { value } = event.target;
    const { fpoList } = this.state;
    const selectedOption = options.find(
      (option) => Number(option.value) === Number(value)
    );
    fpoList[index].isFederation = selectedOption.value;
    let body = {
      fpoId: String(fpoId),
      isFederation: Number(selectedOption.value),
    };
    this.props.updateFederationType(body).then(() => {
      const {
        pageMeta: { page },
      } = this.state;
      this._getFpoList(page);
    });
    this.setState({ fpoList });
  };

  handleInput = ({ target: { name, value } }) => {
    //Debounce Function
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    this.setState({ searchValue }, () => {
      this._getFpoList(1);
    });
  };

  //handle dropdown change
  handleInputChange = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        this._getFpoList(1);
      }
    );
  };

  //handle Delete
  handleDelete = () => {
    let { fpoId } = this.state;
    this.props.deleteFpoById(fpoId).then(() => {
      this.setState({ fpoId: "" });
      this._getFpoList(1);
      this.toggle("isDeletePopup");
    });
  };

  toggle = (name, id) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
      fpoId: id,
    }));
  };

  editFpo = (email, fpoId) => {
    let data = {
      email: email,
      fpoId: fpoId,
    };
    this.props.editAccessFPO(data).then((response) => {
      if (response.status == 200) {
        window.open(
          `https://fpogateway.com/login?token=${response.data.token}`
        );
      }
    });
  };

  render() {
    const {
      adminHeaderDetails,
      otherHeaderDetails,
      pageMeta,
      fpoList,
      isloading,
      sortValue,
      sortOptions,
      isDeletePopup,
      stateOption,
      filterValue,
      activeTab,
      verticalOption,
    } = this.state;
    let subAdminStatus = getSubAdmin();
    let id = localStorage.getItem("userRoleId");

    return (
      <div className="fpo-list mt-5">
        <div className="row" id="fpoMobileScreen">
          <div className="col-12 pb-3">
            <p className="mb-0 text-grey fs-14 line-height-17 pr-3">Sort by</p>
            <NormalSelect
              arrow={true}
              value={sortValue}
              options={sortOptions}
              name="sortValue"
              handleChange={this.handleInputChange}
              className="form-control-mobile"
            />
          </div>
          <div className="col-12 pb-3">
            <p className="mb-0 text-grey fs-14 line-height-17 pr-3">
              Filter by
            </p>
            <NormalSelect
              arrow={true}
              value={filterValue}
              options={stateOption}
              name="filterValue"
              handleChange={this.handleInputChange}
              className="form-control-mobile"
            />
          </div>
          <div className="col-12 pb-3">
            <InputSearch
              className={!subAdminStatus ? "mr-4" : ""}
              placeholder={"Search"}
              onChange={(e) => this.handleInput(e)}
            />
          </div>
          <div className="col-12">
            {!subAdminStatus ? (
              <NormalButton
                label="Add FPO"
                mainbg={true}
                onClick={() => {
                  history.push("/admin/fpoList/add");
                }}
              />
            ) : null}
          </div>
        </div>

        <div id="fpoDesktopScreen">
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex">
              <div className="mr-4 selection-area">
                <div className="d-flex align-items-center">
                  <p className="mb-0 text-grey fs-14 line-height-17 pr-3">
                    Sort by
                  </p>
                  <NormalSelect
                    arrow={true}
                    value={sortValue}
                    options={sortOptions}
                    name="sortValue"
                    handleChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="mr-4 selection-area">
                <div className="d-flex align-items-center">
                  <p className="mb-0 text-grey fs-14 line-height-17 pr-3">
                    Filter by
                  </p>
                  <NormalSelect
                    arrow={true}
                    value={filterValue}
                    options={stateOption}
                    name="filterValue"
                    handleChange={this.handleInputChange}
                  />
                </div>
              </div>
              <InputSearch
                className={!subAdminStatus ? "mr-4" : ""}
                placeholder={"Search"}
                onChange={(e) => this.handleInput(e)}
              />
              {!subAdminStatus ? (
                id != 16 ? (
                  <NormalButton
                    label="Add FPO"
                    mainbg={true}
                    onClick={() => {
                      history.push("/admin/fpoList/add");
                    }}
                  />
                ) : null
              ) : null}
            </div>
          </div>
        </div>

        {isloading ? (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        ) : (
          <div className="table-container w-100 mt-4 pt-1">
            {fpoList.length != 0 ? (
              <TableWrapper
                headerDetails={
                  id === "1" ? adminHeaderDetails : otherHeaderDetails
                }
                queryHandler={this.handlePageChange}
                pageMeta={pageMeta}
              >
                {fpoList.map((item, index) => {
                  let {
                    fpoName = "",
                    fpoId,
                    mobileNumber = "",
                    membersCount,
                    stateMaster = {},
                    emailId = "",
                    firstName = "",
                    lastName = "",
                    createdAt,
                  } = item;
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {moment(createdAt)
                            .tz("Asia/Kolkata")
                            .format("DD-MM-YYYY")}
                        </td>
                        {/* <td align="center">
                        {moment(createdAt).tz("Asia/Kolkata").format("hh:mm a")}
                      </td> */}
                        <td>
                          {createdAt
                            ? dateDiff(createdAt) == 0
                              ? "Today"
                              : dateDiff(createdAt)
                            : "-"}
                        </td>
                        <td>{fpoId}</td>
                        <td data-tip={fpoName}>
                          {getDescriptionData(fpoName, 15)}
                        </td>
                        {/* {id === "1" && (
                          <td>
                            {item?.isFederation === 1 ? (
                              <img
                                src={fpoBazaar}
                                height="60px"
                                width="60px"
                                alt="fpoBazaarIcon"
                              />
                            ) : (
                              "Farmer Network"
                            )}
                          </td>
                        )} */}
                        {id === "1" ? (
                          <td>
                            <div className="vertical_fig_dropdown">
                              <select
                                value={Number(item?.isFederation)}
                                onChange={(e) =>
                                  this.handleVerticalChange(
                                    e,
                                    index,
                                    verticalOption,
                                    fpoId
                                  )
                                }
                              >
                                {verticalOption.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                        ) : null}
                        {/* <td>
                          {firstName === null && lastName === null ? (
                            "-"
                          ) : (
                            <>
                              {firstName ? firstName : ""}{" "}
                              {lastName ? lastName : ""}
                            </>
                          )}
                        </td> */}
                        <td>{stateMaster ? stateMaster.stateName : "-"}</td>
                        <td data-tip={emailId}>
                          {emailId ? (
                            <a href={`mailto:${emailId}`}>
                              {getDescriptionData(emailId, 25)}
                            </a>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>{mobileNumber !== null ? mobileNumber : "-"}</td>
                        <td>{membersCount}</td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            <i
                              className="icon-eye mr-3 cursor-pointer fs-20"
                              onClick={() =>
                                history.push(`/admin/fpoList/view/${fpoId}`)
                              }
                            />
                            {!subAdminStatus ? (
                              id == 16 ? null : (
                                <React.Fragment>
                                  <i
                                    className="icon-edit mr-3 fs-20 cursor-pointer"
                                    onClick={() => this.editFpo(emailId, fpoId)}
                                  />
                                  {id != 13 && (
                                    <i
                                      className="icon-Delete cursor-pointer fs-20"
                                      onClick={() =>
                                        this.toggle("isDeletePopup", fpoId)
                                      }
                                    />
                                  )}
                                </React.Fragment>
                              )
                            ) : null}
                          </div>
                        </td>
                      </tr>
                      <ReactTooltip />
                      <tr className="trans-bg">
                        <td className="empty-height" height="12"></td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </TableWrapper>
            ) : (
              <div className="text-center fs-18 text-black py-5">
                {"No records found !!!"}
              </div>
            )}
          </div>
        )}
        <DeletePopup
          isOpen={isDeletePopup}
          toggle={() => this.toggle("isDeletePopup")}
          handleChange={() => this.handleDelete()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFpoList,
      deleteFpoById,
      allState,
      editAccessFPO,
      updateFederationType,
    },
    dispatch
  );
};

let component = ExistingFpoClass;

export const ExistingFpo = connect(null, mapDispatchToProps)(component);
