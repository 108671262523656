import React, { Component } from "react";
import { connect } from "react-redux";
import { AppBack } from "../../../component/common";
import { AddForum } from "../../../component/pages";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

export class CreateForumClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return (
      <div className="forums-add-component">
        <div className="row content-heading">
          <div className="col-md-12">
            <AppBack
              label={`${this.props.match.params.id ? "Edit" : "Create"} forum`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AddForum id={this.props.match.params.id} />
          </div>
        </div>
      </div>
    );
  }
}

export const CreateForum = connect(null, null)(CreateForumClass);
