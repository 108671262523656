import React, { Component } from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import {
  NormalInput,
  NormalButton,
  DragFileUpload,
  NormalTextarea,
} from "component/common";
import uploaderFile from "assets/images/video-upload.png";

import { history } from "service/helpers";
import { displayImg, getDescriptionData } from "service/helperFunctions";

import SimpleReactValidator from "simple-react-validator";

import {
  fileUpload,
  createIntroductionVideo,
  introductionVideoById,
  updateIntroductionVideo,
  courseManagementTab,
} from "action/CourseAct";

import { Toast } from "service/toast";

import "./style.scss";

export class AddintroducingVideoClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImageUploading: false,
      formFields: {
        title: "",
        video: [],
        fileType: "",
        fileName: "",
        description: "",
      },
      categoryOptions: [],
    };
  }

  //validation
  validator = new SimpleReactValidator({
    validators: {
      checkUrl: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  //LifeCycles
  componentDidMount = () => {
    let { match = {} } = this.props;

    let {
      params: { id },
    } = match;

    if (id != undefined) {
      //get introducing video details
      this.getIntroducingVideoDetails(id);
    }
  };

  getIntroducingVideoDetails = (id) => {
    let { introductionVideoById } = this.props;
    //get introducing video details
    introductionVideoById(id).then((data) => {
      let videoArray = [];
      videoArray.push(data.videoUrl);
      this.setState({
        formFields: {
          title: data.title,
          video: videoArray,
          fileType: "video",
          fileName: data.fileName,
          description: data.description,
        },
      });
    });
  };

  handleInput = ({ target: { name, value } }) => {
    //handle inputs

    let { formFields } = this.state;

    formFields[name] = value;

    this.setState({
      formFields,
    });
  };

  handleFileInput = ({ target: { name, value } }) => {
    //handle inputs file
    let { formFields } = this.state;

    if (value !== undefined) {
      //uploading loader
      this.setState({ isImageUploading: true });

      formFields.fileType = value.type;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "video-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", value);

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          if (formFields.fileType.includes("video")) {
            formFields["video"][0] = data[0].fullUrl;
            formFields.fileName = data[0].fileName;
          } else {
            formFields["video"][0] = data[0].fileUrl;
            formFields.fileName = data[0].fileName;
          }
          this.setState({
            formFields,
            isImageUploading: false,
          });
        } else {
          formFields.videos = [];
          formFields.fileName = "";
          this.setState({
            formFields,
            isImageUploading: false,
          });
        }
      });
    }
  };

  handleSubmit = () => {
    let { isImageUploading, formFields } = this.state;

    let {
      match = {},
      createIntroductionVideo,
      updateIntroductionVideo,
    } = this.props;

    let {
      params: { id },
    } = match;

    if (this.validator.allValid()) {
      if (!isImageUploading) {
        let body = {
          title: formFields.title,
          videoUrl: formFields.video[0],
          fileName: formFields.fileName,
          introVideoId: "",
          description: formFields.description,
        };
        if (id) {
          body.introVideoId = id;
          updateIntroductionVideo(body).then(() => {
            Toast({
              type: "success",
              message: "Introduction video updated successfully",
            });
            this.handleBack();
          });
        } else {
          delete body.introVideoId;
          createIntroductionVideo(body).then(() => {
            Toast({
              type: "success",
              message: "Introduction video added successfully",
            });
            this.handleBack();
          });
        }
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleBack = () => {
    history.goBack();
    this.validator.hideMessages();
    this.props.courseManagementTab(4);
  };

  render() {
    let { formFields, isImageUploading } = this.state;

    let { title, video, fileType = "", description } = formFields;

    let { match = {} } = this.props;

    let {
      params: { id },
    } = match;

    return (
      <div className="add-introducingvideo">
        <div
          className="d-flex cursor-pointer pt-2 mt-1 mb-4 back-area"
          onClick={() => this.handleBack()}
        >
          <i className="icon-arrow-left fs-24"></i>
          <h5 className="heading-20 pl-4">{id ? "Edit" : "Add"} video</h5>
        </div>
        <div className="add-introducingvideo-form">
          <h5 className="fs-19 text-black font-Gilroy-Bold line-height-22 mb-0 mt-2">
            Introduction video
          </h5>
          <div className="row">
            <div className="col-12">
              <NormalInput
                label="Title"
                type="text"
                onChange={(e) => this.handleInput(e)}
                value={title}
                placeholder="Enter title"
                name="title"
                required={true}
              />
              {this.validator.message("title", title, "required|max:50")}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <NormalTextarea
                label="Description"
                onChange={(e) => this.handleInput(e)}
                value={description}
                placeholder="Enter here"
                name="description"
                required={true}
              />
              {this.validator.message(
                "description",
                description,
                "required|max:250"
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mt-4 py-2">
                <div className="fs-17 text-black font-Gilroy pb-3">
                  Upload video
                  <span className="text-danger pl-2 fs-18">*</span>
                </div>
                <DragFileUpload
                  className={`file-uploader ${
                    video.length !== 0 ? "" : "no-img"
                  }`}
                  acceptFileFormat={["MP4", "mp4", "webm", "WEBM", "ogg"]}
                  handleFileUpload={([file]) =>
                    this.handleFileInput({
                      target: { name: "video", value: file },
                    })
                  }
                >
                  {isImageUploading ? (
                    <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100">
                      <div id="video-upload"></div>
                    </div>
                  ) : (
                    <>
                      {video.length !== 0 ? (
                        fileType.includes("video") ? (
                          <div className="row">
                            <div
                              className="justify-content-center align-items-center d-flex"
                              onMouseEnter={() => this.refs.vidRef.play()}
                              onMouseLeave={() => this.refs.vidRef.pause()}
                            >
                              <video
                                height="150"
                                width="300"
                                ref="vidRef"
                                src={video[0]}
                              ></video>
                            </div>
                          </div>
                        ) : (
                          <img src={displayImg(video[0])} />
                        )
                      ) : (
                        <div className="uploader-content text-center">
                          <img src={uploaderFile} alt="image" />
                          <span className="pt-2 mt-1">
                            {"Drag & drop to upload files"}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </DragFileUpload>
                {this.validator.message("video", video[0], "required")}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center add-buttons">
          <div className="ml-auto d-flex">
            <NormalButton
              label="Cancel"
              outline={true}
              className="px-4 mr-2 border-none"
              onClick={() => history.goBack()}
            />
            <NormalButton
              label={id ? "Update" : "Publish"}
              mainbg={true}
              className="px-4"
              onClick={this.handleSubmit.bind(this, true)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
      createIntroductionVideo,
      introductionVideoById,
      updateIntroductionVideo,
      courseManagementTab,
    },
    dispatch
  );
};

let component = AddintroducingVideoClass;

export const AddintroducingVideo = connect(null, mapDispatchToProps)(component);
