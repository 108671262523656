import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTopPerformanceOfFPO } from "action/dashboard";
import * as Highcharts from "highcharts/highstock";

export class TopPerfomanceClass extends Component {
  state = {
    options: {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },

      xAxis: {
        categories: [],
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "Views",
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0,
          maxPointWidth: 60,
        },
      },
      series: [
        {
          name: "Viewers",
          color: "#D0962B",
          data: [],
        },
      ],
      credits: {
        enabled: false,
      },
    },
  };

  //Lifecycles
  componentDidMount() {
    let { options } = this.state;
    this.props.getTopPerformanceOfFPO().then(({ data }) => {
      options.xAxis.categories = [];
      options.series[0].data = [];
      data.map((list) => {
        options.xAxis.categories.push(list.fpoDetails.fpoName);
        options.series[0].data.push(parseInt(list.loggedHours));
      });
      this.setState({ options }, () => {
        Highcharts.chart("container", options);
      });
    });
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-12 mb-4">
          <div className="card topPerfomance">
            <div className="card-body">
              <h5 className="card-title fs-24 font-Gilroy-Bold line-height-28 text-green-18">
                Top performance of FPO’s
              </h5>
              <div id="container"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTopPerformanceOfFPO,
    },
    dispatch
  );
};
export const TopPerfomance = connect(
  null,
  mapDispatchToProps
)(TopPerfomanceClass);
