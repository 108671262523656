import React, { Component, Fragment } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RoboCallRequest } from "./NewRequest";
import { CallHistory } from "./CallHistory";
import { roboCallReport } from "action/FPOact";

export class RoboCall extends Component {
  state = {
    activeTab: "1",
  };

  toggle = (tab) => {
    let { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  downloadReport = () => {
    let { roboCallReport } = this.props;
    roboCallReport().then((data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "robocall-report.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    const { activeTab } = this.state;

    return (
      <div className="course-management-inner-tabs">
        <div className="d-flex justify-content-center">
          <Nav className="tab-sammunaty">
            <NavItem>
              <NavLink
                className={(classnames({ active: activeTab === "1" }), "p-0")}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <button className={`btn ${activeTab === "1" ? "active" : ""}`}>
                  New Request
                </button>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={(classnames({ active: activeTab === "2" }), "p-0")}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                <button className={`btn ${activeTab === "2" ? "active" : ""}`}>
                  Call History
                </button>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {activeTab === "2" && (
          <div className="robocall-download d-flex justify-content-end mb-2">
            <button onClick={this.downloadReport}>Download Report</button>
          </div>
        )}
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <RoboCallRequest />
          </TabPane>
          <TabPane tabId="2">
            <CallHistory />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      roboCallReport,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(RoboCall);
