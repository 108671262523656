import React, { Component } from 'react';
import "./style.scss"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import { GradingUpload } from "../GradingUpload"

import classnames from 'classnames';
export class FpoManagementCompClass extends Component {
  state = {
    activeTab: '1',
  };

  toggle = (tab) => {
    let { activeTab } = this.state
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {

    let {
      activeTab
    } = this.state

    return (
      <>
        <div className="fpo-management">
          <div className="d-flex justify-content-between align-items-center fpo-management-heading">
            <div className="">
              <h5 className="heading-20">FPO management</h5>
            </div>
          </div>
          <div className="common-navlink">
            <div className="filled-tabs">
              <div className="tabs-block">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { this.toggle('1'); }}
                    >Grading Profile</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}
                    >Engagement Profile</NavLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <GradingUpload />
                </TabPane>
                <TabPane tabId="2">

                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
  }, dispatch)
}

export const FpoManagementComp = connect(null, mapDispatchToProps)(FpoManagementCompClass)