import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Addevent } from "component/contentManagement/AddEvent/Addevent";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

export class AddEventClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }

  render() {
    return <Addevent match={this.props.match} />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

let component = AddEventClass;

export const AddEvent = connect(null, mapDispatchToProps)(component);
