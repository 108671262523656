import React, { Component } from "react";
import { CertifingInstituteList } from "component/courseManagement/index";
import { connect } from "react-redux";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";
class CertifingListClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <CertifingInstituteList match={this.props.match} />;
  }
}

export const CertifingList = connect(null, null)(CertifingListClass);
