import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "./date.scss";

export class NormalDate extends Component {
  render() {
    let {
      className = "form-control w-100",
      placeholder = "DD/MM/YY",
      onChange,
      value = "",
      name,
      disabled = false,
      isReadOnly = false,
      showYearPicker = false,
      showFullMonthYearPickerStatus = false,
      showMonthYearPickerStatus = false,
      showYearDropdown = true,
      showMonthDropdown = true,
      maxdate = "false",
      mindate = "false",
      timeInput = "false",
      label = "",
      required = false,
      isIcon = false,
    } = this.props;

    return (
      <div className="normal-date-input">
        {label !== "" ? (
          <div className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20">
            {label}
            {required && <span className="text-danger pl-2 fs-18">*</span>}
          </div>
        ) : (
          ""
        )}
        {!isReadOnly ? (
          <>
            <DatePicker
              timeInputLabel={timeInput && "Time:"}
              showTimeInput={timeInput}
              className={className}
              disabled={disabled}
              placeholderText={placeholder}
              selected={value}
              showYearPicker={showYearPicker}
              showMonthYearPicker={showMonthYearPickerStatus}
              showFullMonthYearPicker={showFullMonthYearPickerStatus}
              showMonthDropdown={showMonthDropdown}
              showYearDropdown={showYearDropdown}
              dateFormat={"dd/MM/yyyy"}
              maxDate={maxdate && new Date()}
              minDate={mindate && new Date()}
              autoComplete="off"
              onChange={(date) => {
                let body = {};

                body = {
                  target: {
                    name: name,
                    value: date,
                  },
                };

                onChange(body);
              }}
              name={name}
            />
            {isIcon ? (
              <i className="icon-chevron-down calender-icon fs-20"></i>
            ) : null}
          </>
        ) : (
          <p>{value}</p>
        )}
      </div>
    );
  }
}
