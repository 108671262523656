import React, { Component } from "react";
import { connect } from "react-redux";
import { CommonPageLoader } from "component/common";
import { Modal, ModalBody } from "reactstrap";
import { getFilePathDownload } from "service/api";
import moment from "moment";

export class ViewStaffPopupClass extends Component {
  render() {
    let {
      className = "modal-dialog-centered fpo-popups",
      isOpen = true,
      toggle,
      isLoading = false,
      staffDetail,
    } = this.props;

    let {
      profileImageUrl = "",
      firstName = "",
      lastName = "",
      gender = "",
      dob = "",
      mobileNumber = "",
      employeeId = "",
      designation = "",
      educationmaster = {},
      resumeDocName = "",
      addressProofUrl = "",
      identityProofUrl = "",
    } = staffDetail;

    let { qualificationType = "" } = educationmaster ? educationmaster : {};

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={className + " px-3 py-4"}
        size="lg"
      >
        <ModalBody className="p-0">
          <div className="d-flex justify-content-between pb-2">
            <h5 className="px-0 pb-1 text-green-18 fs-26 font-Gilroy-Bold">
              Staff Details
            </h5>
            <i className="icon-close fs-20 cursor-pointer" onClick={toggle} />
          </div>
          {!isLoading ? (
            <div className="px-3 py-4">
              <div className="row">
                <div className="col-3 mx-auto">
                  <div className="profile-area">
                    <div className="profile-pic">
                      <img src={profileImageUrl} className="shadow" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-6">
                  <div className="row">
                    <div className="col-4">
                      <p className="text-black font-weight-bold fs-15 pr-2">
                        Name :
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-black-50 fs-15">
                        {firstName} {lastName}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="text-black font-weight-bold fs-15 pr-2">
                        Gender :
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-black-50 fs-15">{gender}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="text-black font-weight-bold fs-15 pr-2">
                        DOB :
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-black-50 fs-15">
                        {moment(dob).format("DD MMMM YYYY")}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="text-black font-weight-bold fs-15 pr-2">
                        Phone :
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-black-50 fs-15">{mobileNumber}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="text-black font-weight-bold fs-15 pr-2">
                        Employee ID :
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-black-50 fs-15">{employeeId}</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-4">
                      <p className="text-black font-weight-bold fs-15 pr-2">
                        Designation:{" "}
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-black-50 fs-15">{designation}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <p className="text-black font-weight-bold fs-15 pr-2">
                        Identity proof :
                      </p>
                    </div>
                    <div className="col-8">
                      <a
                        className="fs-16 text-green-18 text-underline cursor-pointer mb-3"
                        href={getFilePathDownload + identityProofUrl}
                        download
                      >
                        {"Download"}
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="text-black font-weight-bold fs-15 pr-2">
                        Address proof :
                      </p>
                    </div>
                    <div className="col-8">
                      <a
                        className="fs-16 text-green-18 text-underline cursor-pointer mb-3"
                        href={getFilePathDownload + addressProofUrl}
                        download
                      >
                        {"Download"}
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="text-black font-weight-bold fs-15 pr-2">
                        Resume :
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-black-50 fs-15">{resumeDocName}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="text-black font-weight-bold fs-15 pr-2">
                        Education{" "}
                        <span className="text-nowrap">{"qualification :"}</span>
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-black-50 fs-15">{qualificationType}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

let component = ViewStaffPopupClass;

export const ViewStaffPopup = connect(null, null)(component);
