import React, { Component } from "react";

import upload from "assets/svg/upload.svg";

import "./Addreport.scss";

import { connect } from "react-redux";

import { uploadGradingToolExcel } from "action/FPOact";

import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalEditor,
} from "component/common";

import moment from "moment";

import { history } from "service/helpers";

import { getDescriptionData } from "service/helperFunctions";

import { bindActionCreators } from "redux";

import SimpleReactValidator from "simple-react-validator";

import { fileUpload } from "action/CourseAct";

import {
  addReports,
  updateReportsById,
  getReportsById,
  contentManagementTab,
} from "action/ContentAct";

import { Toast } from "service/toast";

export class AddreportClass extends Component {
  constructor(props) {
    super();
    this.state = {
      csvUrl: "",
      gradingfile: "",
      fileNameDocument: "",
      isLoading: false,
      radioType: "text",
      fileName: "",
      fileArray: [],
      formFields: {
        title: "",
        description: "",
        content: "",
      },
      isImageUploading: false,
      isImageUploaded: false,
      reportId: null,
    };
  }

  //validation
  validator = new SimpleReactValidator({
    validators: {
      checkUrl: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleInput = ({ target: { name, value } }) => {
    let { formFields } = this.state;
    formFields[name] = value;
    this.setState({
      formFields,
    });
  };

  handleGradingFile = (e) => {
    //upload grading file
    let file = e.target.files[0];
    if (file !== undefined) {
      if (file?.name?.split(".")?.includes("pdf")) {
        this.setState({ gradingfile: "", fileNameDocument: "" }, () => {
          let { gradingfile, fileNameDocument } = this.state;
          gradingfile = file;
          fileNameDocument = file.name;
          const formData = new FormData();
          formData.append("file", gradingfile);
          this.setState({ isLoading: true });
          this.setState({
            gradingfile,
            fileNameDocument,
          });
          this.props
            .fileUpload(formData)
            .then((res) => {
              this.setState({
                isLoading: false,
                fileNameDocument: res[0]?.fileName,
                gradingfile: res[0]?.fileUrl,
              });
              this.validator.hideMessages();
            })
            .catch(() => {
              this.setState({
                isLoading: false,
              });
            });
        });
      } else {
        Toast({
          type: "error",
          message: "Please upload only Pdf*",
        });
      }
    }
  };

  handleToggle = ({ target: { name, value } }) => {
    let toggleEvent = Object.assign({}, this.state.toggleEvent);
    toggleEvent[name] = value ? 1 : 0;
    this.setState({
      toggleEvent,
    });
  };

  handleText = (type) => {
    this.setState({
      radioType: type,
    });
    this.setState({
      gradingfile: "",
      fileName: "",
      fileArray: [],
      fileNameDocument: "",
      formFields: {
        title: "",
        description: "",
        content: "",
      },
      isImageUploading: false,
      isImageUploaded: false,
    });
    this.validator.hideMessages();
  };

  onEditorChange = (editor) => {
    let { formFields } = this.state;
    formFields.description = editor.getData();
    this.setState({
      formFields,
    });
  };

  onEditorContentChange = (editor) => {
    let { formFields } = this.state;
    formFields.content = editor.getData();
    this.setState({
      formFields,
    });
  };

  uploadSingleFile = (e) => {
    //upload image
    let file = e.target.files[0];
    if (file !== undefined) {
      if (
        file?.name?.split(".")?.includes("jpg") ||
        file?.name?.split(".")?.includes("svg") ||
        file?.name?.split(".")?.includes("jpeg") ||
        file?.name?.split(".")?.includes("png") ||
        file?.name?.split(".")?.includes("gif")
      ) {
        this.setState({ fileArray: [] }, () => {
          let { fileArray, fileName } = this.state;
          fileName = "Uploading ....";
          let fileData = [];
          fileData.push(fileData);
          fileArray = fileData;
          this.setState({ isImageUploading: true });
          const config = {
            onUploadProgress: function (progressEvent) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              document.getElementById(
                "image-upload"
              ).innerText = `Uploading ${percentCompleted}%`;
            },
          };
          const formData = new FormData();
          formData.append("file", file);
          this.props.fileUpload(formData, config).then((data) => {
            if (data !== undefined) {
              let uploadedFile = [];
              uploadedFile.push(data[0].fileUrl);
              fileArray = uploadedFile;
              fileName = data[0]?.fileName;
              this.setState({
                fileArray,
                isImageUploading: false,
                fileName,
              });
            } else {
              this.setState({
                fileArray: [],
                isImageUploading: false,
                fileName: "",
              });
            }
          });
          this.setState({
            fileArray,
            isImageUploaded: false,
            fileName,
          });
        });
      } else {
        Toast({
          type: "error",
          message: "Please upload only image",
        });
      }
    }
  };

  handleSubmit = () => {
    let {
      formFields,
      gradingfile,
      fileArray,
      isImageUploading,
      fileName,
      fileNameDocument,
      radioType,
    } = this.state;

    let { addReports, updateReportsById, match } = this.props;
    if (radioType === "text") {
      this.validator.fields.fileUpload = true;
      this.validator.errorMessages.fileUpload = null;
    } else {
      this.validator.fields.content = true;
      this.validator.errorMessages.content = null;
    }
    if (this.validator.allValid() && fileArray.length !== 0) {
      if (!isImageUploading) {
        this.setState({ isImageUploading: false });
        let { title, description, content } = formFields;
        let bodyText = {
          reportType: radioType,
          reportTitle: title,
          content: content,
          description: description,
          uploadImage: fileArray.length !== 0 ? fileArray[0] : [],
        };
        let bodyDoc = {
          reportType: radioType,
          reportTitle: title,
          description: description,
          uploadDoc: gradingfile ? gradingfile : "",
          uploadImage: fileArray.length !== 0 ? fileArray[0] : [],
          fileName: fileNameDocument,
        };
        let body = radioType === "text" ? bodyText : bodyDoc;
        match.params.id === undefined
          ? addReports(body).then(() => {
              this.handleBack();
            })
          : updateReportsById(this.state.reportId, body).then(() => {
              this.handleBack();
            });
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      if (fileArray.length === 0) {
        this.setState({ isImageUploaded: true });
      }
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.props.getReportsById(this.props.match.params.id).then((data) => {
        this.setState({
          gradingfile: data?.uploadDoc ? data?.uploadDoc : "",
          fileArray: data?.uploadImage ? [data?.uploadImage] : [],
          fileNameDocument: data?.uploadDoc ? data?.fileName : "",
          formFields: {
            title: data?.reportTitle ? data?.reportTitle : "",
            description: data?.description ? data?.description : "",
            content: data?.content ? data?.content : "",
          },
          radioType: data?.reportType,
          reportId: data?.reportId,
        });
      });
    }
  }

  handleBack = () => {
    history.goBack();
    this.validator.hideMessages();
    this.props.contentManagementTab(1);
  };

  render() {
    let {
      formFields,
      radioType,
      gradingfile,
      fileNameDocument,
      isImageUploaded,
      fileName,
      fileArray,
      isImageUploading,
    } = this.state;

    let { title, description, content } = formFields;

    let { match } = this.props;

    return (
      <div className="add-news">
        <div
          className="d-flex cursor-pointer py-2 mt-1 mb-4 back-area"
          onClick={() => history.goBack()}
        >
          <i className="icon-arrow-left fs-24"></i>
          <h5 className="heading-20 ml-1 pl-3 mb-0">
            {match.params.id === undefined ? "Add" : "Edit"} reports
          </h5>
        </div>
        <div className="news-details">
          <div>
            <h3 className="news-heading mb-0">Report</h3>
          </div>
          <div>
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="d-flex flex-row pt-3">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        className="form-check-input radioTypeClass"
                        id="reportTypeText"
                        name="reportType"
                        checked={radioType === "text"}
                        onChange={() => this.handleText("text")}
                      />{" "}
                      Text
                    </label>
                  </div>
                  <div class="form-check pl-5">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        className="form-check-input radioTypeClass"
                        id="reportTypeDoc"
                        name="reportType"
                        checked={radioType === "document"}
                        onChange={() => this.handleText("document")}
                      />{" "}
                      Document
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 pb-3">
                <NormalInput
                  label="Report title"
                  type="text"
                  onChange={this.handleInput}
                  value={title}
                  placeholder="Enter here"
                  name="title"
                  required={true}
                />
                {this.validator.message("title", title, "required|max:200")}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label>Description</label>
                <span className="text-danger pl-3 fs-18">*</span>
                <NormalEditor
                  label="Description"
                  data={description}
                  onChange={this.onEditorChange}
                  required={true}
                />
                {this.validator.message(
                  "description",
                  description,
                  "required|max:700"
                )}
              </div>

              {radioType === "text" && (
                <div className="col-12 pt-3">
                  <label>Content</label>
                  <span className="text-danger pl-3 fs-18">*</span>
                  <NormalEditor
                    label="content"
                    data={content}
                    onChange={this.onEditorContentChange}
                    required={true}
                  />
                  {this.validator.message(
                    "content",
                    content,
                    "required|max:5000"
                  )}
                </div>
              )}
            </div>

            {radioType === "document" && (
              <div className="add-grading-area-report">
                <div className="add-grading-report">
                  <div className="container">
                    <div className="add-grading-form-container pt-4">
                      <div>
                        <p className="fs-16 line-height-37 text-black"></p>
                        <p className="fs-16 line-height-37 text-black">
                          Upload Document{" "}
                          <span className="text-danger pl-2 fs-18">*</span>
                        </p>
                      </div>
                      <div className="d-flex justify-content-center pt-5 mt-2 pb-4">
                        <div>
                          <div className="add-grading-container d-flex justify-content-center align-items-center">
                            <input
                              type="file"
                              accept=".pdf"
                              name="gradingfile"
                              onChange={this.handleGradingFile}
                              onClick={(e) => {
                                e.currentTarget.value = null;
                              }}
                            />
                            {fileNameDocument ? (
                              <> {fileNameDocument} </>
                            ) : (
                              <>
                                <img
                                  className="mr-2"
                                  src={upload}
                                  alt="upload"
                                />
                                {"Upload file"}
                              </>
                            )}
                          </div>
                          {this.validator.message(
                            "fileUpload",
                            gradingfile,
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="mb-2">
              <div className="fs-17 text-black font-Gilroy pb-4 pt-3">
              Upload Cover Image <span className="text-danger pl-2 fs-18">*</span>
              </div>
              <div className="d-flex align-items-center w-100 btn-file-input">
                <button className="">
                  <input
                    type="file"
                    className="h-100"
                    accept="image/*"
                    onChange={this.uploadSingleFile}
                    onClick={(e) => {
                      e.currentTarget.value = null;
                    }}
                  ></input>
                  {isImageUploading ? (
                    <div
                      id="image-upload"
                      className="d-flex align-items-center"
                    ></div>
                  ) : fileName === "" ? (
                    "Upload"
                  ) : (
                    <span>Uploaded</span>
                  )}
                </button>
                {fileArray.length !== 0 && !isImageUploading ? (
                  <div className="ml-4">
                    <img
                      src={fileArray[0]}
                      alt=""
                      className="upload-image-preview"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {isImageUploaded ? (
                <span className="error-message text-danger fs-16 pb-3">
                  Image field is required
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center add-news-buttons">
          <div className="ml-auto d-flex">
            <NormalButton
              label="Cancel"
              outline={true}
              className="px-4 mr-2 border-none"
              onClick={() => history.goBack()}
            />
            <NormalButton
              label={match.params.id === undefined ? "Publish" : "Update"}
              mainbg={true}
              className="px-4"
              onClick={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addReports,
      fileUpload,
      uploadGradingToolExcel,
      contentManagementTab,
      getReportsById,
      updateReportsById,
    },
    dispatch
  );
};

let component = AddreportClass;

export const Addreport = connect(null, mapDispatchToProps)(component);
