import React, { Component } from "react";

import "./AddCourse.scss";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import {
  NormalInput,
  NormalButton,
  DragFileUpload,
  NormalEditor,
  CommonPageLoader,
} from "component/common";

import { history } from "service/helpers";

import uploaderFile from "assets/images/file.png";

import SimpleReactValidator from "simple-react-validator";

import { displayImg } from "service/helperFunctions";

import { addPractices, fileUpload, updatePractices } from "action/CourseAct";

import pdfImage from "assets/images/pdfImage.png";

import { Toast } from "service/toast";

export class PracticeFilesClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        practiceFileArray: [
          {
            title: "",
            descriptions: "",
            video: [],
            videoArray: [],
            fileName: "",
            fileType: "",
            isImageUploading: false,
          },
        ],
      },
      isNewlyAddedData: false,
    };
    //validation
    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="error-message text-danger fs-16 pb-3">{message}</span>
      ),
      autoForceUpdate: this,
    });
  }

  componentDidMount() {
    let { isEdit, courseDetail } = this.props;
    if (isEdit) {
      let { practices = [] } = courseDetail;
      this.updateData(practices);
    }
  }

  updateData = async (practices) => {
    // description
    let practiceFileArray = [];

    practices.map((data) => {
      let sectionDetail = {
        title: data.title,
        descriptions: data.description,
        video: [data.practiceFileUrl],
        videoArray: [data.practiceFileUrl],
        fileName: data.practiceFileName,
        fileType:
          data.practiceFileName !== null &&
          data.practiceFileName.includes("pdf")
            ? "pdf"
            : "",
        isImageUploading: false,
        isDeleted: false,
        isAdded: false,
        practiceId: data.practiceId,
      };

      practiceFileArray.push(sectionDetail);
    });

    let formFields = {};

    if (practices.length != 0) {
      formFields = { practiceFileArray: practiceFileArray };
      this.setState({ isNewlyAddedData: false });
    } else {
      formFields = {
        practiceFileArray: [
          {
            title: "",
            descriptions: "",
            video: [],
            videoArray: [],
            fileName: "",
            fileType: "",
            isImageUploading: false,
          },
        ],
      };
      this.setState({ isNewlyAddedData: true });
    }

    this.setState({ formFields });
  };

  handleInput = ({ target: { name, value } }, index) => {
    //handle inputs

    let { formFields } = this.state;

    formFields["practiceFileArray"][index][name] = value;

    this.setState({
      formFields,
    });
  };

  handleFileInput = (e, index) => {
    let value = e.target.value;

    //handle inputs file
    let { formFields } = this.state;
    if (value !== undefined) {
      formFields["practiceFileArray"][index]["videoArray"][0] = value;
      formFields["practiceFileArray"][index]["fileType"] = value.type;
      formFields["practiceFileArray"][index]["isImageUploading"] = true;
      formFields["practiceFileArray"][index]["fileName"] = value.name;

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            `video-upload-${index}`
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", value);

      this.props.fileUpload(formData, config).then((data) => {
        formFields.practiceFileArray.map((list) => {
          if (data[0].actualFileName === list.fileName) {
            list["video"][0] = data[0].fileUrl;
            list["fileName"] = data[0].fileName;
            list["isImageUploading"] = false;
          }
        });
        this.setState({
          formFields,
        });
      });

      this.setState({
        formFields,
      });
    }
  };

  handleLessonAdd = () => {
    this.validator.hideMessages();

    let formFields = Object.assign({}, this.state.formFields);

    let { isEdit } = this.props;

    if (isEdit) {
      formFields.practiceFileArray = this.state.formFields.practiceFileArray.concat(
        [
          {
            title: "",
            descriptions: "",
            video: [],
            videoArray: [],
            fileName: "",
            fileType: "",
            isImageUploading: false,
            isDeleted: false,
            isAdded: true,
          },
        ]
      );
    } else {
      formFields.practiceFileArray = this.state.formFields.practiceFileArray.concat(
        [
          {
            title: "",
            descriptions: "",
            video: [],
            videoArray: [],
            fileName: "",
            fileType: "",
            isImageUploading: false,
          },
        ]
      );
    }

    this.setState({
      formFields,
    });
  };

  handleLessonRemove = (e, index) => {
    let { formFields } = this.state;

    let { isEdit } = this.props;

    if (isEdit) {
      if (formFields.practiceFileArray[index]["isAdded"] === true) {
        formFields.practiceFileArray = formFields.practiceFileArray.filter(
          (s, sidx) => index !== sidx
        );
      } else {
        formFields.practiceFileArray[index]["isDeleted"] = true;
      }
    } else {
      formFields.practiceFileArray = formFields.practiceFileArray.filter(
        (s, sidx) => index !== sidx
      );
    }
    this.setState({
      formFields,
    });
  };

  onEditorChange = (editor, index) => {
    let { formFields } = this.state;
    formFields["practiceFileArray"][index]["descriptions"] = editor.getData();
    this.setState({
      formFields,
    });
  };

  handleSubmit = (isChangeStepper) => {
    let { handleStepperChange } = this.props;

    if (this.validator.allValid()) {
      let { formFields } = this.state;

      let { isEdit, courseDetail } = this.props;
      if (isEdit) {
        this.practiceUpdate(isChangeStepper);
      } else {
        let body = {
          courseId: parseInt(localStorage.getItem("courseId")),
          practiceFiles: [],
        };
        let uploadingIndex = "";

        formFields.practiceFileArray.map((list, index) => {
          if (list.isImageUploading == true) {
            uploadingIndex = index;
          }
          body.practiceFiles.push({
            title: list.title,
            description: list.descriptions,
            practiceFileName: list.fileName,
            practiceFileUrl: list.video[0],
          });
        });
        if (formFields.practiceFileArray[uploadingIndex] == undefined) {
          this.props.addPractices(body).then(() => {
            this.validator.hideMessages();
            if (isChangeStepper) {
              let path = this.props.match.path.split("/");
              handleStepperChange("next");
              if (path[3] === "lessons") {
                history.push("quizquestions");
              }
            }
          });
        } else {
          Toast({
            type: "error",
            message: "Please wait file is uploading",
          });
        }
      }
    } else {
      this.validator.showMessages();
    }
  };

  practiceUpdate = (isChangeStepper) => {
    let { isEdit, courseDetail, handleStepperChange } = this.props;
    let { formFields, isNewlyAddedData } = this.state;

    let body = {
      courseId: courseDetail.courseId,
      practiceFiles: [],
    };
    if (isNewlyAddedData) {
      body.isAdded = true;
    }
    let uploadingIndex = "";

    formFields.practiceFileArray.map((list, index) => {
      if (list.isImageUploading == true) {
        uploadingIndex = index;
      }
      let data = {
        title: list.title,
        description: list.descriptions,
        practiceFileName: list.fileName,
        practiceFileUrl: list.video[0],
        isDeleted: list.isDeleted,
        isAdded: list.isAdded,
        isUpdated: true,
        practiceId: list.practiceId,
      };
      if (isNewlyAddedData) {
        delete data.isUpdated;
        data.isAdded = true;
        delete data.practiceId;
      }
      body.practiceFiles.push(data);
    });

    if (formFields.practiceFileArray[uploadingIndex] == undefined) {
      this.props.updatePractices(body).then(() => {
        this.validator.hideMessages();
        if (isChangeStepper) {
          let path = this.props.match.path.split("/");
          handleStepperChange("next");
          if (path[3] === "lessons") {
            // history.push('quizquestions')
            this.props.history.push(
              `/admin/addCourse/quizquestions/${body.courseId}`
            );
          }
        }
      });
    } else {
      Toast({ type: "error", message: "Please wait file is uploading" });
    }
  };

  render() {
    let { formFields } = this.state;

    let { isEdit = false } = this.props;

    let { practiceFileArray } = formFields;

    return (
      <>
        <div className="lesson-details">
          <div className="">
            <h3 className="course-heading">Practice files</h3>
          </div>
          {practiceFileArray.map((list, index) => {
            let { isDeleted = false } = list;
            return !isDeleted ? (
              <div className="pt-3" key={index}>
                <h5 className="fs-19 text-black font-Gilroy-Bold line-height-22 mb-0 mt-2">
                  {index + 1}. Practice file
                </h5>
                <div className="row">
                  <div className="col-12">
                    <NormalInput
                      label="Title"
                      type="text"
                      onChange={(e) => this.handleInput(e, index)}
                      value={list.title}
                      placeholder="Enter title"
                      name="title"
                      required={true}
                    />
                    {this.validator.message(
                      `title `,
                      list.title,
                      "required|max:75"
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <NormalEditor
                      label="Description"
                      data={list.descriptions}
                      onChange={(e) => this.onEditorChange(e, index)}
                      required={true}
                    />
                    {this.validator.message(
                      `descriptions `,
                      list.descriptions,
                      "required|max:150"
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-2 mt-4 pt-2">
                      <div className="fs-17 text-black font-Gilroy pb-3 mb-1">
                        Upload practice file
                        <span className="text-danger pl-2 fs-18">*</span>
                      </div>
                      <DragFileUpload
                        className={`file-uploader ${
                          list.video.length !== 0 ? "" : "no-img"
                        }`}
                        handleFileUpload={([file]) =>
                          this.handleFileInput(
                            {
                              target: {
                                name: "video",
                                value: file,
                              },
                            },
                            index
                          )
                        }
                      >
                        {list.isImageUploading ? (
                          <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100">
                            <div id={`video-upload-${index}`}></div>
                            {/* <CommonPageLoader isSpinningLoader={true} />
                                                            <p className="mt-2 mb-0 fs-14 text-grey-65">Uploading .....</p> */}
                          </div>
                        ) : (
                          <>
                            {list.video.length !== 0 ? (
                              list.fileType.includes("pdf") ? (
                                <img src={pdfImage} alt="Pdf" />
                              ) : (
                                <img src={displayImg(list.videoArray[0])} />
                              )
                            ) : (
                              <div className="uploader-content text-center">
                                <img src={uploaderFile} alt="image" />
                                <span className="pt-2 mt-1 fs-14 text-black">
                                  {"Drag & drop to upload files"}
                                </span>
                              </div>
                            )}
                          </>
                        )}
                      </DragFileUpload>
                      {this.validator.message(
                        `File `,
                        list.videoArray[0],
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    {index >= 1 ? (
                      <NormalButton
                        label={`Remove practice file`}
                        danger={true}
                        className="px-4 mr-4"
                        onClick={(e) => this.handleLessonRemove(e, index)}
                      />
                    ) : null}
                  </div>
                  <div>
                    <NormalButton
                      label={`Add practice file`}
                      outline={true}
                      className="px-4 font-Gilroy line-height-19"
                      onClick={this.handleLessonAdd}
                    />
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </div>
        <div className="d-flex align-items-center add-course-buttons">
          <div className="ml-auto d-flex">
            <NormalButton
              label="Discard changes"
              outline={true}
              className="px-4 mr-2 border-none"
              onClick={() => history.push("/admin/courseManagement")}
            />
            <NormalButton
              label={isEdit ? "Update & next" : "Save & next"}
              mainbg={true}
              className="px-4"
              onClick={this.handleSubmit.bind(this, true)}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addPractices,
      updatePractices,
      fileUpload,
    },
    dispatch
  );
};

let component = PracticeFilesClass;

export const PracticeFiles = connect(null, mapDispatchToProps)(component);
