import { axiosInstance } from "./utilities";
import { logout } from "./utilities";

export var api = async function ({
  method = "get",
  api,
  body,
  status = false,
  token = "",
  baseURL = "normal",
  configObj = {},
  isfortgotPass = false,
}) {
  return await new Promise((resolve, reject) => {
    // setting token
    if (isfortgotPass) {
      axiosInstance.defaults.headers.common["Authorization"] =
        localStorage.getItem("forgotPassauthToken");
    } else {
      axiosInstance.defaults.headers.common["Authorization"] =
        localStorage.getItem("AuthToken");
    }

    axiosInstance[method](
      `${getServiceUrl(baseURL)}${api}`,
      body ? body : "",
      configObj
    )
      .then((data) => {
        resolve(statusHelper(status, data));
      })
      .catch((error) => {
        try {
          if (error.response) {
            reject(statusHelper(status, error.response));
          } else {
            reject(error);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
  });
};

var statusHelper = (status, data) => {
  if (data.status == 401 || data.status == 403) {
    // logout();
  }
  if (status) {
    return {
      status: data.status,
      ...data.data,
    };
  } else {
    return data.data;
  }
};

let getServiceUrl = (baseURL) => {
  let finalURL = "";
  switch (baseURL) {
    case "normal":
      finalURL = "https://portal.fpogateway.com/auth/";
      break;
    case "auth":
      finalURL = "https://portal.fpogateway.com/auth/api/v1/";
      break;
    case "Course":
      finalURL = "https://portal.fpogateway.com/academy/api/v1/";
      break;
    case "Content":
      finalURL = "https://portal.fpogateway.com/contentmgmt/api/v1/";
      break;
    case "file":
      finalURL = "https://portal.fpogateway.com/documents/api/v1/";
      break;
    case "faq":
      finalURL = "https://portal.fpogateway.com/fpomgmt/api/v1/";
      break;
    case "fpo":
      finalURL = "https://portal.fpogateway.com/fpomgmt/api/v1/";
      break;
    case "licence":
      finalURL = "https://portal.fpogateway.com/complaince/api/v1/";
      break;
    case "keyFinancials":
      finalURL = "https://portal.fpogateway.com/keyfinancials/api/v1/";
      break;
    case "forum":
      finalURL = "https://portal.fpogateway.com/forum/api/v1/";
      break;
    case "member":
      finalURL = "https://portal.fpogateway.com/fpomgmt/api/v1/";
      break;
    case "f3c":
      finalURL = "https://f3c.kamatan.net/api/";
      break;
    case "campaign":
      finalURL = "https://campaignapi.kamatan.net/api/";
      break;
    default:
      finalURL = "https://portal.fpogateway.com/auth/";
      break;
  }

  return finalURL;
};

export const getFilePath =
  getServiceUrl("file") + "documents/file/stream/videos/";
export const getFilePathDocs =
  getServiceUrl("file") + "documents/file/stream/docs/";
export const getFilePathDownload =
  getServiceUrl("file") + "documents/file/download?fileUrl=";
