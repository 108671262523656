import React, { Component } from "react";
import { connect } from "react-redux";
import { AddFpo } from "component/Fpo";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

export class AddFPOClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return (
      <div className="fpolist">
        <AddFpo />
      </div>
    );
  }
}

export const AddFPO = connect(null, null)(AddFPOClass);
