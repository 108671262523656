import React, { Component } from "react";
import { AddFaq } from "component/Faq/index";
import { connect } from "react-redux";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

class AddFAQClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <AddFaq id={this.props.match.params.id} />;
  }
}

export const AddFAQ = connect(null, null)(AddFAQClass);
