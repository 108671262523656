import { contentMaganementApi } from "../service/apiVariables";
import { contentManagementType } from "service/actionType";
import { addQuery } from "../service/helperFunctions";

//Add Sections
export const addNews =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...contentMaganementApi.addNews, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const updateNews =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...contentMaganementApi.updateNews, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getAllNews =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, contentMaganementApi.getAllNews);
      api({ ...contentMaganementApi.getAllNews })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getNewsById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      contentMaganementApi.getNewsById.addQuery = { key: "id", payload: id };
      api({ ...contentMaganementApi.getNewsById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const deleteNewsById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      contentMaganementApi.deleteNewsById.addQuery = { key: "id", payload: id };
      api({ ...contentMaganementApi.deleteNewsById })
        .then(({ data, message }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const addStories =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...contentMaganementApi.addStories, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const deleteStoriesById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      contentMaganementApi.deleteStoriesById.addQuery = {
        key: "id",
        payload: id,
      };
      //   addQuery(id, contentMaganementApi.deleteStoriesById);
      api({ ...contentMaganementApi.deleteStoriesById })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const updateStories =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...contentMaganementApi.updateStories, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getAllStories =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, contentMaganementApi.getAllStories);
      api({ ...contentMaganementApi.getAllStories })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const getStoriesById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      contentMaganementApi.getStoriesById.addQuery = { key: "id", payload: id };
      //   addQuery(id, contentMaganementApi.getStoriesById);
      api({ ...contentMaganementApi.getStoriesById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const addTestimonials =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...contentMaganementApi.addTestimonials, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const deleteTestimonialsById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      contentMaganementApi.deleteTestimonialsById.addQuery = {
        key: "id",
        payload: id,
      };
      //   addQuery(id, contentMaganementApi.deleteTestimonialsById);
      api({ ...contentMaganementApi.deleteTestimonialsById })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const updateTestimonials =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...contentMaganementApi.updateTestimonials, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getAllTestimonials =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, contentMaganementApi.getAllTestimonials);
      api({ ...contentMaganementApi.getAllTestimonials })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const getTestimonialsById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      contentMaganementApi.getTestimonialsById.addQuery = {
        key: "id",
        payload: id,
      };
      //   addQuery(id, contentMaganementApi.getTestimonialsById);
      api({ ...contentMaganementApi.getTestimonialsById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const addEvents =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...contentMaganementApi.addEvents, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const deleteEventsById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      contentMaganementApi.deleteEventsById.addQuery = {
        key: "id",
        payload: id,
      };
      //   addQuery(id, contentMaganementApi.deleteEventsById);
      api({ ...contentMaganementApi.deleteEventsById })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const updateEvents =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...contentMaganementApi.updateEvents, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getAllEvents =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, contentMaganementApi.getAllEvents);
      api({ ...contentMaganementApi.getAllEvents })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
export const getEventsById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      contentMaganementApi.getEventsById.addQuery = {
        key: "id",
        payload: id,
      };
      //   addQuery(id, contentMaganementApi.getEventsById);
      api({ ...contentMaganementApi.getEventsById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getAllReports =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, contentMaganementApi.getAllReports);
      api({ ...contentMaganementApi.getAllReports })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getReportsById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      contentMaganementApi.getReportsById.addQuery = { key: "id", payload: id };
      api({ ...contentMaganementApi.getReportsById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const updateReportsById =
  (id, body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      contentMaganementApi.updateReportsById.reportId = id;
      api({ ...contentMaganementApi.updateReportsById, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const deleteReportsById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      contentMaganementApi.deleteReportsById.addQuery = {
        key: "id",
        payload: id,
      };
      api({ ...contentMaganementApi.deleteReportsById })
        .then(({ data, message }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const addReports =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...contentMaganementApi.addReports, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const contentManagementTab = (payload) => (dispatch) => {
  dispatch({ type: contentManagementType.contentManagementTab, payload });
};
