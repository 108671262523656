import React, { Component } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import {
   NormalInput,
   NormalButton,
   TableWrapper,
   CommonPageLoader,
} from 'component/common';

import { history } from 'service/helpers';

import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import { Toast } from 'service/toast';

import SimpleReactValidator from 'simple-react-validator';

import {
   fileUpload,
   getCertifyingInstituteList,
   addCertifyingInstitute,
   deleteCertifyingInstituteById,
   getInstituteById,
   updateCertifyingInstitute,
} from 'action/CourseAct';
import { getDescriptionData } from 'service/helperFunctions';

export class CertifingInstituteListClass extends Component {
   state = {
      headerDetails: [
         {
            label: 'S.no',
         },
         {
            label: 'Certifying institute name',
         },
         {
            label: 'Certifying institute profile',
         },
         {
            label: 'Action',
         },
      ],
      pageMeta: {},
      certifyingInstittuteList: [],
      isAddNewModal: false,
      isCertifyingProfileUploading: false,
      certifyingInsttituteDetail: {
         instituteName: '',
         instituteProfileUrl: '',
         instituteProfileFileName: '',
      },
      currentId: '',
      isapiDataUploading: false,
   };

   //validation
   validator = new SimpleReactValidator({
      element: (message) => (
         <span className='error-message text-danger fs-16 pb-3'>{message}</span>
      ),
      autoForceUpdate: this,
   });

   componentDidMount = () => {
      this._getCertifyingInstituteList(1);
   };

   _getCertifyingInstituteList = (page) => {
      this.setState({ isloading: true });
      this.props.getCertifyingInstituteList(page).then((data) => {
         let results = data.results;
         this.setState({
            pageMeta: data.pageMeta,
            certifyingInstittuteList: results,
            isloading: false,
         });
      });
   };

   handlePageChange = (page) => {
      //page change
      this._getCertifyingInstituteList(page);
   };

   handleInputChange = ({ target: { name, value } }) => {
      let { certifyingInsttituteDetail } = this.state;
      certifyingInsttituteDetail[name] = value;
      this.setState({
         certifyingInsttituteDetail,
      });
   };

   //Upload profile picture
   uploadauthorProfileIcon = (e) => {
      let file = e.target.files[0];
      if (file !== undefined) {
         let { certifyingInsttituteDetail } = this.state;
         certifyingInsttituteDetail.instituteProfileUrl = file;

         const config = {
            onUploadProgress: function (progressEvent) {
               var percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
               );
               document.getElementById(
                  'image-upload'
               ).innerText = `Uploading ${percentCompleted}%`;
            },
         };

         const formData = new FormData();
         formData.append('file', file);
         this.setState({ isCertifyingProfileUploading: true });

         this.props.fileUpload(formData, config).then((data) => {
            if (data !== undefined) {
               certifyingInsttituteDetail.instituteProfileUrl = data[0].fileUrl;
               certifyingInsttituteDetail.instituteProfileFileName =
                  data[0].fileName;
            } else {
               certifyingInsttituteDetail.instituteProfileUrl = '';
               certifyingInsttituteDetail.instituteProfileFileName = '';
            }
            this.setState({
               certifyingInsttituteDetail,
               isCertifyingProfileUploading: false,
            });
         });
         this.setState({
            certifyingInsttituteDetail,
         });
      }
   };

   handleBack = () => {
      let { isCertifyingProfileUploading } = this.state;
      if (!isCertifyingProfileUploading) {
         this._clearData();
      } else {
         Toast({ type: 'error', message: 'Please wait Image is uploading' });
      }
   };

   handleDelete = (id) => {
      this.props.deleteCertifyingInstituteById(parseInt(id)).then(() => {
         this.handlePageChange(1);
      });
   };

   _clearData = () => {
      this.setState({
         isapiDataUploading: false,
         isCertifyingProfileUploading: false,
         isAddNewModal: false,
         currentId: '',
         certifyingInsttituteDetail: {
            instituteName: '',
            instituteProfileUrl: '',
            instituteProfileFileName: '',
         },
      });
   };

   handleEdit = (instituteId) => {
      this.setState({ currentId: instituteId });

      this.props.getInstituteById(instituteId).then((data) => {
         this.setState({
            isAddNewModal: true,
            certifyingInsttituteDetail: {
               instituteName: data.instituteName,
               instituteProfileUrl: data.instituteProfileUrl,
               instituteProfileFileName: data.instituteProfileFileName,
            },
         });
      });
   };

   toggle = (key) => {
      this.setState((prevState) => ({
         [key]: !prevState[key],
      }));
      this.validator.hideMessages();
   };

   handleSubmit = () => {
      let {
         certifyingInsttituteDetail,
         isCertifyingProfileUploading,
      } = this.state;
      if (this.validator.allValid()) {
         if (!isCertifyingProfileUploading) {
            this.setState({ isapiDataUploading: true });
            this.props
               .addCertifyingInstitute(certifyingInsttituteDetail)
               .then(() => {
                  this._clearData();
                  this._getCertifyingInstituteList(1);
               });
         } else {
            Toast({ type: 'error', message: 'Please wait Image is uploading' });
         }
      } else {
         this.validator.showMessages();
      }
   };

   handleUpdate = () => {
      let {
         certifyingInsttituteDetail,
         isCertifyingProfileUploading,
         currentId,
      } = this.state;
      if (this.validator.allValid()) {
         if (!isCertifyingProfileUploading) {
            certifyingInsttituteDetail['id'] = currentId;
            this.setState({ isapiDataUploading: true });
            this.props
               .updateCertifyingInstitute(certifyingInsttituteDetail)
               .then(() => {
                  this._clearData();
                  this._getCertifyingInstituteList(1);
               });
         } else {
            Toast({ type: 'error', message: 'Please wait Image is uploading' });
         }
      } else {
         this.validator.showMessages();
      }
   };

   render() {
      const {
         certifyingInsttituteDetail,
         isAddNewModal,
         isCertifyingProfileUploading,
         certifyingInstittuteList,
         pageMeta,
         isloading,
         headerDetails,
         currentId,
         isapiDataUploading,
      } = this.state;

      let {
         instituteName,
         instituteProfileUrl,
         instituteProfileFileName,
      } = certifyingInsttituteDetail;

      return (
         <>
            <div className='d-flex justify-content-between align-items-center course-management-heading'>
               <div
                  className='d-flex cursor-pointer pt-2 mt-1'
                  onClick={() => history.goBack()}
               >
                  <i className='icon-arrow-left fs-24'></i>
                  <h5 className='heading-20 pl-2'>Certifying institute</h5>
               </div>
               <div className=''>
                  <NormalButton
                     label='Add certificate institute'
                     mainbg={true}
                     onClick={() => this.toggle('isAddNewModal')}
                  />
               </div>
            </div>
            {isloading ? (
               <>
                  {/* Loader */}
                  <CommonPageLoader />
               </>
            ) : (
                  <div className='table-container w-100 mt-4 pt-1'>
                     {certifyingInstittuteList.length != 0 ? (
                        <TableWrapper
                           headerDetails={headerDetails}
                           queryHandler={this.handlePageChange}
                           pageMeta={pageMeta}
                        >
                           {certifyingInstittuteList.map((item, index) => {
                              let {
                                 instituteName,
                                 instituteId,
                                 instituteProfileFileName,
                              } = item;

                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {index + 1}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <p className="mb-0">{instituteName}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        {instituteProfileFileName}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center h-100">
                                        <i
                                          className="icon-edit fs-20 cursor-pointer"
                                          onClick={() => {
                                            this.handleEdit(instituteId);
                                          }}
                                        ></i>
                                        {/* <i
                                             className='icon-Delete fs-20 pl-3 cursor-pointer'
                                             onClick={() => {
                                                this.handleDelete(instituteId);
                                             }}
                                          ></i> */}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="trans-bg">
                                    <td
                                      className="empty-height"
                                      height="12"
                                    ></td>
                                  </tr>
                                </React.Fragment>
                              );
                           })}
                        </TableWrapper>
                     ) : (
                           <div className='text-center fs-18 text-black py-5'>
                              No records found !!!
                           </div>
                        )}
                  </div>
               )}
            <Modal
               isOpen={isAddNewModal}
               className='modal-dialog-centered masters-addmodal'
            >
               <div className='p-3 w-100 d-flex justify-content-between'>
                  <div className='fs-16 font-weight-bold'>
                     {currentId === '' ? 'Add' : 'Update'} author
                  </div>
                  <div>
                     <i
                        className='icon-close fs-16 cursor-pointer'
                        onClick={() => this.handleBack()}
                     ></i>
                  </div>
               </div>
               <ModalBody className='pt-0'>
                  <div className='row'>
                     <div className='col-md-12'>
                        <NormalInput
                           label='Certifying institute name'
                           type='text'
                           onChange={this.handleInputChange}
                           value={instituteName}
                           placeholder='Certifying institute Name'
                           name='instituteName'
                           required={true}
                        />
                        {this.validator.message(
                           'instituteName',
                           instituteName,
                           'required|max:50'
                        )}
                     </div>
                     <div className='col-12 mt-3'>
                        <div className='mb-2'>
                           <div className='fs-17 text-black font-Gilroy pb-2'>
                              Certifying institute profile
                              <span className='text-danger pl-2 fs-18'>*</span>
                           </div>
                           <div className='d-flex align-items-center w-100 btn-file-input'>
                              <button className=''>
                                 <input
                                    type='file'
                                    className=''
                                    accept='image/*'
                                    onChange={this.uploadauthorProfileIcon}
                                 ></input>
                                 {isCertifyingProfileUploading ? (
                                    <div
                                       id='image-upload'
                                       className='d-flex align-items-center'
                                    ></div>
                                 ) : instituteProfileUrl === '' ? (
                                    'Upload'
                                 ) : (
                                          getDescriptionData(
                                             instituteProfileFileName,
                                             20
                                          )
                                       )}
                              </button>
                              {instituteProfileUrl !== '' &&
                                 !isCertifyingProfileUploading ? (
                                    <div className='ml-4'>
                                       <img
                                          src={instituteProfileUrl}
                                          alt=''
                                          className='upload-image-preview'
                                       />
                                    </div>
                                 ) : (
                                    ''
                                 )}
                           </div>
                           {this.validator.message(
                              'profile',
                              instituteProfileUrl,
                              'required'
                           )}
                        </div>
                     </div>
                  </div>
               </ModalBody>
               <ModalFooter>
                  <div className='mx-auto d-flex'>
                     <NormalButton
                        className='mr-3'
                        label='Close'
                        outline={true}
                        onClick={() => this._clearData()}
                     />
                     {!isapiDataUploading ? (
                        <NormalButton
                           label={currentId === '' ? 'Save' : 'Update'}
                           mainbg={true}
                           onClick={() =>
                              currentId === ''
                                 ? this.handleSubmit()
                                 : this.handleUpdate()
                           }
                        />
                     ) : (
                           <NormalButton
                              label={currentId === '' ? 'Save' : 'Update'}
                              mainbg={true}
                           />
                        )}
                  </div>
               </ModalFooter>
            </Modal>
         </>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         fileUpload,
         getCertifyingInstituteList,
         addCertifyingInstitute,
         deleteCertifyingInstituteById,
         getInstituteById,
         updateCertifyingInstitute,
      },
      dispatch
   );
};

let component = CertifingInstituteListClass;

export const CertifingInstituteList = connect(
   null,
   mapDispatchToProps
)(component);
