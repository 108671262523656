import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ViewSubcribePopup } from "./ViewSubcribePopup";

import {
  getFpoBasicDetailsById,
  getSupportedLanguage,
  getAllSubscribeAlerts
} from "action/FPOact";
import { CommonPageLoader, NormalButton, NormalSelect, TableStripedWrapper } from "component/common";
import moment from "moment";

export class SubscribeAlertsClass extends Component {
  state = {
    fpoDetails: null,
    langOptions: null,
    langOptionsMap: null,
    isSubcribeModalOpen: false,
    masterAlertsList: [],
    alertsList: [],
    pageMeta: {},
    isLoading: false,
    headerDetails: [
      {
        label: "Preferred Language",
      },
      {
        label: "Type",
      },
      {
        label: "Crop"
      },
      {
        label: "Start Date",
      },
      {
        label: "End Date",
      },
      {
        label: "Time",
      },
    ],
    filterOptions: [
      { label: "All", value: "All" },
      { label: "Weather Alert", value: "weatherAlert" },
      { label: "Mandi Alert", value: "mandiAlert" },
    ],
    filterValue: "All",
    sortOptions: [
      { label: "All", value: "All" },
      { label: "Start Date", value: "startDate" },
      { label: "End Date", value: "endDate" },
      { label: "Time", value: "time" },
    ],
    sortValue: "All",
    sortOrder: [
      { label: "ASC", value: "asc" },
      { label: "DESC", value: "desc" },
    ],
    sortOrderVal: "asc",
  };

  componentDidMount() {
    this._getDetails()
    this._getSupportedLanguage();
    this._getAllSubscribeAlerts(1);
  }
  handlePageChange = (page) => {
    // page change
    this._getAllSubscribeAlerts(page);
  };

  _getAllSubscribeAlerts = (page) => {
    this.setState({ isLoading: true });
    let { getAllSubscribeAlerts, id } = this.props;
    let body = {
      page,
      id,
      limit: 20
    };
    getAllSubscribeAlerts(body).then((data) => {
      this.setState({
        masterAlertsList: data.data.results,
        alertsList: data.data.results,
        pageMeta: data.data.pageMeta,
        isLoading: false,
      });
    });
  }
  _getDetails = () => {
    let { getFpoBasicDetailsById, id } = this.props;
    getFpoBasicDetailsById(id).then((data) => {
      this.setState({ fpoDetails: data.ProfileDetails });
    });
  };
  _getSupportedLanguage = () => {
    this.props.getSupportedLanguage({}).then((data) => {

      let weatherMap = data.data.Weather.reduce((acc, lang) => {
        acc[lang.value] = lang;
        return acc;
      }, {});
      let mandiMap = {};
      if (data.data?.Mandi) {
        mandiMap = data.data.Mandi.reduce((acc, lang) => {
          acc[lang.value] = lang;
          return acc;
        }, {});
      }

      let _langOptions = []

      Object.keys(weatherMap).forEach((lang) => {
        if (Object.keys(mandiMap).length > 0 && mandiMap[lang]) {
          _langOptions.push(weatherMap[lang]);
        } else {
          _langOptions.push(weatherMap[lang]);
        }
      });

      let filterOptions = [
        ...this.state.filterOptions,
        ..._langOptions
      ];

      this.setState({
        filterOptions: filterOptions,
        langOptions: _langOptions,
        langOptionsMap: weatherMap
      })
    });
  }

  showSubcribeModal = () => {
    this.setState({ isSubcribeModalOpen: true })
  }

  hideSubcribeModal = () => {
    this.setState({ isSubcribeModalOpen: false })
  }
  updateData = (data) => {
    this.setState({
      masterAlertsList: [data, ...this.state.masterAlertsList],
      alertsList: [data, ...this.state.alertsList],
    });
    this.filterBy({
      filter: this.state.filterValue,
      sortBy: this.state.sortValue,
      sortOrder: this.state.sortOrderVal
    })
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value,
      }
    );
    if (name === "filterValue") {
      this.filterBy({
        filter: value,
        sortBy: this.state.sortValue,
        sortOrder: this.state.sortOrderVal
      });
    }
    if (name === "sortValue") {
      this.filterBy({
        filter: this.state.filterValue,
        sortBy: value,
        sortOrder: this.state.sortOrderVal
      });
    }
    if (name === "sortOrderVal") {
      this.filterBy({
        filter: this.state.filterValue,
        sortBy: this.state.sortValue,
        sortOrder: value
      });
    }
  };

  filterBy = (opts) => {
    try {
      let _alertsList = [...this.state.masterAlertsList];
      if (opts.filter != 'All') {
        _alertsList = _alertsList.filter((alert) => {
          if (['mandiAlert'].includes(opts.filter)) {
            return alert.mandiAlert;
          } else if (['weatherAlert'].includes(opts.filter)) {
            return alert.weatherAlert
          } else {
            return alert.preferredLang === opts.filter
          }
        });
      }
      if (opts.sortBy != 'All') {
        _alertsList = _alertsList.sort((a, b) => {
          let diff;
          if (['startDate', 'endDate'].includes(opts.sortBy)) {
            diff = moment(a[opts.sortBy], "DD/MM/YYYY").isAfter(moment(b[opts.sortBy], "DD/MM/YYYY"));
          } else if (opts.sortBy === 'time') {
            let aTime = a.metaInfo.time.split(':');
            let bTime = b.metaInfo.time.split(':')
            let start = moment().add(parseInt(aTime[0]), 'hours').add(parseInt(aTime[1]), "minute");
            let end = moment().add(parseInt(bTime[0]), 'hours').add(parseInt(bTime[1]), "minute");
            diff = start.isAfter(end);
          }
          if (opts.sortOrder === 'desc') {
            return diff ? 1 : -1;
          } else {
            return diff ? -1 : 1;
          }
        });
      }
      this.setState({
        alertsList: _alertsList,
      });
    } catch (error) {

    }
  }

  render() {
    let {
      fpoDetails,
      isSubcribeModalOpen,
      langOptions,
      isLoading,
      masterAlertsList,
      alertsList,
      headerDetails,
      pageMeta,
      langOptionsMap,
      filterOptions,
      filterValue,
      sortOptions,
      sortValue,
      sortOrder,
      sortOrderVal
    } = this.state;

    return (
      <>
        <div className="d-flex justify-content-end align-items-center subscribe-alerts">
          <div className="d-flex mb-3">
            {
              masterAlertsList.length > 0 && (
                <>
                  <div className="mr-4 selection-area">
                    <div className="d-flex align-items-center">
                      <p className="mb-0 text-grey fs-14 line-height-17 pr-3">
                        Sort by
                      </p>
                      <NormalSelect
                        arrow={true}
                        value={sortValue}
                        options={sortOptions}
                        name="sortValue"
                        handleChange={this.handleInputChange}
                      />
                      <NormalSelect
                        className={'form-control select-form-control ml-2'}
                        arrow={true}
                        value={sortOrderVal}
                        options={sortOrder}
                        name="sortOrderVal"
                        handleChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="mr-4 selection-area">
                    <div className="d-flex align-items-center">
                      <p className="mb-0 text-grey fs-14 line-height-17 pr-3">
                        Filter by
                      </p>
                      <NormalSelect
                        arrow={true}
                        value={filterValue}
                        options={filterOptions}
                        name="filterValue"
                        handleChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </>
              )
            }

            <NormalButton
              label="Add New Alert"
              mainbg={true}
              onClick={this.showSubcribeModal}
            />
          </div>
        </div>
        {
          isLoading ? (
            <>
              {/* Loading */}
              <CommonPageLoader />
            </>
          ) : (
            <>
              {alertsList.length !== 0 ? (
                <TableStripedWrapper
                  headerDetails={headerDetails}
                  pageMeta={pageMeta}
                  queryHandler={this.handlePageChange}
                >
                  {
                    alertsList.map((item, index) => {
                      let startDateItem = item?.startDate?.split('01:00:00')[0];
                      let endDateItem = item?.endDate?.split('01:00:00')[0];
                      return (
                        <tr key={index}>
                          <td>
                            <div className="px-3">
                              {langOptionsMap && langOptionsMap[item.preferredLang]?.label}
                            </div>
                          </td>
                          <td>
                            <div className="px-3">{item.alertType}</div>
                          </td>
                          <td>
                            <div className="px-3">{item?.metaInfo?.crops.length > 0 ? item?.metaInfo?.crops.join(', ') : "-"}</div>
                          </td>
                          <td>
                            <div className="px-3">{startDateItem}</div>
                          </td>
                          <td>
                            <div className="px-3">{endDateItem}</div>
                          </td>
                          <td>
                            <div className="px-3">{item?.metaInfo?.time}</div>
                          </td>
                        </tr>
                      )
                    })
                  }

                </TableStripedWrapper>
              ) : (
                <p className="py-5 my-5 fs-20 text-center text-black-50 line-height-23 fw-700">
                  No records found !!!
                </p>
              )
              }
            </>
          )
        }
        {
          isSubcribeModalOpen && (
            <ViewSubcribePopup
              isOpen={isSubcribeModalOpen}
              toggle={() => this.hideSubcribeModal()}
              selFpo={fpoDetails}
              langOptions={langOptions}
              updateData={this.updateData}
            />
          )
        }
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFpoBasicDetailsById,
      getSupportedLanguage,
      getAllSubscribeAlerts
    },
    dispatch
  );
};

let component = SubscribeAlertsClass;

export const SubscribeAlerts = connect(null, mapDispatchToProps)(component);