import React from "react";
import "./NormalInput.scss";

export const NormalInput = ({
  className = "form-control",
  placeholder = "",
  label = "",
  onChange,
  value = "",
  name,
  disabled = false,
  type = "text",
  isLink = false,
  required = false,
  maxLength = null,
  readOnly = false,
}) => {
  return (
    <div className="normal-input">
      {label !== "" ? (
        <div className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20">
          {label}{" "}
          {required && <span className="text-danger pl-2 fs-18">*</span>}
        </div>
      ) : (
        ""
      )}
      <input
        className={`${isLink ? `${className} islink` : className}`}
        name={name}
        type={type}
        disabled={disabled}
        value={value}
        min={0}
        maxLength={maxLength}
        placeholder={placeholder}
        readOnly={readOnly}
        onChange={(e) => {
          let body = {};

          body = {
            target: {
              name: e.target.name,
              value: e.target.value,
            },
          };

          onChange(body);
        }}
      />
      {isLink ? (
        <span className="link-area">
          <i className="fs-24 icon-link"></i>
        </span>
      ) : (
        ""
      )}
    </div>
  );
};
