import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "service/helpers";
import { bindActionCreators } from "redux";
import * as AddCourseChildren from "component/courseManagement/AddCourse";

import { CommonPageLoader } from "component/common";

import { getCategoryDetail } from "action/CourseAct";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

export class AddCourseClass extends Component {
  state = {
    loadingEdit: false,
    isEdit: false,
    currentStepperIndex: 0,
    courseId: null,
    courseDetail: "",
    navDetails: [
      {
        label: "Course details",
        component: "CourseDetails",
        path: "courseDetail",
      },
      {
        label: "Section",
        component: "Sections",
        path: "section",
      },
      {
        label: "Practice files",
        component: "PracticeFiles",
        path: "lessons",
      },
      {
        label: "Quiz questions",
        component: "QuizQuestions",
        path: "quizquestions",
      },
    ],
  };
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
    let {
      match: {
        params: { id },
      },
      history,
    } = this.props;

    if (id) {
      this.setState({ isEdit: true, courseId: id });
      this.getCoursInfo(id);
    }

    let {
      location: { state = {} },
    } = history;

    let { changeStepperIndex } = state;

    let path = this.props.match.path.split("/");

    if (changeStepperIndex) {
      this.handleStepperChange("next");
    }

    let currentStepperIndex = 0;

    if (path[3] === "section") {
      currentStepperIndex = 1;
    } else if (path[3] === "lessons") {
      currentStepperIndex = 2;
    } else if (path[3] === "quizquestions") {
      currentStepperIndex = 3;
    } else {
      currentStepperIndex = 0;
    }

    this.setState({
      currentStepperIndex,
    });
  }

  getCoursInfo = (coursId) => {
    let { getCategoryDetail } = this.props;

    getCategoryDetail(coursId).then((data) => {
      this.setState({ courseDetail: data, loadingEdit: true });
    });
  };

  handleStepperChange = (direction = "next") => {
    this.setState(({ currentStepperIndex, navDetails }) => {
      let index = eval(
        `${currentStepperIndex} ${direction === "prev" ? "-" : "+"} 1`
      );
      return {
        currentStepperIndex:
          index > navDetails.length - 1 || currentStepperIndex < 0
            ? currentStepperIndex
            : index,
      };
    });
  };
  renderComponent = () => {
    let { currentStepperIndex, navDetails, isEdit, courseDetail } = this.state;

    let { component } = navDetails[currentStepperIndex];

    let Component = AddCourseChildren[component];

    return (
      <Component
        handleStepperChange={this.handleStepperChange}
        currentStepperIndex={currentStepperIndex}
        navDetails={navDetails}
        match={this.props.match}
        history={this.props.history}
        courseDetail={courseDetail}
        isEdit={isEdit}
      />
    );
  };

  changeTabIfEdit = (list, index) => {
    let { currentStepperIndex, courseId, isEdit } = this.state;
    if (isEdit) {
      this.setState({ currentStepperIndex: index });
      this.props.history.push(`/admin/addCourse/${list.path}/${courseId}`);
      this.getCoursInfo(courseId);
    }
  };

  backHandler = () => {
    const { currentStepperIndex, navDetails } = this.state;
    if (currentStepperIndex > 0) {
      this.setState({
        currentStepperIndex: this.state.currentStepperIndex - 1,
      });

      var path = navDetails[currentStepperIndex - 1].path;
      if (localStorage.getItem("courseId")) {
        this.props.history.push(
          `/admin/addCourse/${path}/${localStorage.getItem("courseId")}`
        );
      }
    } else {
      localStorage.removeItem("courseId");
      this.props.history.push("/admin/courseManagement");
    }
  };

  render() {
    let { navDetails, currentStepperIndex, isEdit, loadingEdit } = this.state;
    return (
      <>
        <div className="add-course">
          <div
            className="d-flex cursor-pointer pt-2 mt-1 back-area"
            onClick={() => {
              // history.goBack();
              this.backHandler();
            }}
          >
            <i className="icon-arrow-left fs-24"></i>
            <h5 className="heading-20 ml-1 pl-3">Add new course</h5>
          </div>
          <div className="stepper-horizontal green">
            {navDetails.map((list, index) => (
              <div
                onClick={() => this.changeTabIfEdit(list, index)}
                className={`step ${
                  currentStepperIndex == index ? "active" : ""
                }  ${currentStepperIndex > index ? "completed" : ""}`}
                key={index}
              >
                <div className="step-circle"></div>
                <div className="step-title">{list.label}</div>
                <div className="step-sub-title pt-2">STEP {index + 1}</div>
                <div
                  className={`step-bar-left ${
                    currentStepperIndex == index ? "completed" : ""
                  }`}
                ></div>
                <div className="step-bar-right"></div>
              </div>
            ))}
          </div>
          {isEdit ? (
            loadingEdit ? (
              this.renderComponent()
            ) : (
              <>
                {/* Loader */}
                <CommonPageLoader />
              </>
            )
          ) : (
            this.renderComponent()
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCategoryDetail,
    },
    dispatch
  );
};

let component = AddCourseClass;

export const AddCourse = connect(null, mapDispatchToProps)(component);
