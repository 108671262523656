import React, { Component } from "react";
import "./Addevents.scss";
import { connect } from "react-redux";
import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalEditor,
} from "component/common";

import moment from "moment";

import { history } from "service/helpers";

import { bindActionCreators } from "redux";

import SimpleReactValidator from "simple-react-validator";

import {
  addEvents,
  getEventsById,
  updateEvents,
  contentManagementTab,
} from "action/ContentAct";

import { getDescriptionData } from "service/helperFunctions";

import { fileUpload } from "action/CourseAct";

import { Toast } from "service/toast";

export class AddeventClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleEvent: {
        // save: 0,
        // share: 0,
        // like: 0,
        // dislike: 0,
        // download: 0,
        online: 1,
        offline: 0,
      },
      fileName: "",
      fileArray: [],
      formFields: {
        title: "",
        publishDate: "",
        registrationLink: "",
        description: "",
        eventDate: "",
        agenda: "",
        author: "",
        organizedby: "",
        location: "",
      },
      isImageUploaded: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-16 pb-3">{message}</span>
    ),
  });

  handleInput = ({ target: { name, value } }) => {
    let { formFields } = this.state;

    formFields[name] = value;

    this.setState({
      formFields,
    });
  };

  uploadSingleFile = (e) => {
    //upload image
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ fileArray: [] }, () => {
        let { fileArray, fileName } = this.state;
        fileName = "Uploading ....";
        let fileData = [];
        fileData.push(fileData);
        fileArray = fileData;
        this.setState({ isImageUploading: true });

        const config = {
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            document.getElementById(
              "image-upload"
            ).innerText = `Uploading ${percentCompleted}%`;
          },
        };

        const formData = new FormData();
        formData.append("file", file);

        this.props.fileUpload(formData, config).then((data) => {
          if (data !== undefined) {
            let uploadedFile = [];
            uploadedFile.push(data[0].fileUrl);
            fileArray = uploadedFile;
            fileName = data[0].fileName;
            this.setState({
              fileArray,
              isImageUploading: false,
              fileName,
            });
          } else {
            this.setState({
              fileArray: [],
              isImageUploading: false,
              fileName: "",
            });
          }
        });
        this.setState({
          fileArray,
          isImageUploaded: false,
          fileName,
        });
      });
    }
  };

  handleToggle = ({ target: { name, value } }) => {
    let toggleEvent = Object.assign({}, this.state.toggleEvent);
    toggleEvent[name] = value ? 1 : 0;
    this.setState({
      toggleEvent,
    });
  };

  handleToggleForEvent = ({ target: { name, value } }) => {
    let toggleEvent = Object.assign({}, this.state.toggleEvent);
    if (name == "online") {
      toggleEvent["online"] = 1;
      toggleEvent["offline"] = 0;
    } else {
      toggleEvent["online"] = 0;
      toggleEvent["offline"] = 1;
    }
    this.setState({
      toggleEvent,
    });
  };

  onEditorChange = (editor) => {
    let { formFields } = this.state;
    formFields.description = editor.getData();
    this.setState({
      formFields,
    });
  };

  onEditorChangeagenda = (editor) => {
    let { formFields } = this.state;
    formFields.agenda = editor.getData();
    this.setState({
      formFields,
    });
  };

  handleSubmit = () => {
    let {
      fileArray,
      formFields,
      toggleEvent,
      isImageUploading,
      fileName,
    } = this.state;

    if (this.validator.allValid() && fileArray.length != 0) {
      if (!isImageUploading) {
        this.setState({ isImageUploading: false });

        let { share, like, dislike, download, save, online } = toggleEvent;

        let {
          title,
          publishDate,
          registrationLink,
          description,
          eventDate,
          agenda,
          author,
          organizedby,
          location,
        } = formFields;

        let body = {
          eventId: this.props.match.params.id,
          fileName: fileName,
          eventTitle: title,
          description: description,
          eventDate: moment(eventDate).format("YYYY-MM-DD"),
          publishOn: moment(publishDate).format("YYYY-MM-DD"),
          agendaOfEvent: agenda,
          eventPartners: author,
          modeOfEvent: online == 1 ? true : false,
          organiseBy: organizedby,
          placeAndLocation: location,
          registrationLink: registrationLink,
          // isSave: save == 0 ? false : true,
          // isShare: share == 0 ? false : true,
          // idDownload: download == 0 ? false : true,
          // isLike: like == 0 ? false : true,
          // idDislike: dislike == 0 ? false : true,
          uploadImage: fileArray.length != 0 ? fileArray[0] : "",
        };
        this.props.match.params.id === undefined
          ? this.props.addEvents(body).then(() => {
              this.handleBack();
            })
          : this.props.updateEvents(body).then(() => {
              this.handleBack();
            });
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      if (fileArray.length == 0) {
        this.setState({ isImageUploaded: true });
      }
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.props.getEventsById(this.props.match.params.id).then((data) => {
        this.setState({
          fileName: data.fileName,
          fileArray: [data.uploadImage],
          isImageUploaded: false,
          toggleEvent: {
            // save: data.isSave === true ? 1 : 0,
            // share: data.isShare === true ? 1 : 0,
            // like: data.isLike === true ? 1 : 0,
            // dislike: data.idDislike === true ? 1 : 0,
            // download: data.idDownload === true ? 1 : 0,
            online: data.modeOfEvent === true ? 1 : 0,
            offline: data.modeOfEvent === false ? 1 : 0,
          },
          formFields: {
            title: data.eventTitle ? data.eventTitle : "",
            publishDate: data.publishDate
              ? new Date(data.publishDate)
              : new Date(),
            registrationLink: data.registrationLink
              ? data.registrationLink
              : "",
            description: data.description ? data.description : "",
            eventDate: data.eventDate ? new Date(data.eventDate) : new Date(),
            agenda: data.agendaOfEvent ? data.agendaOfEvent : "",
            author: data.eventPartners ? data.eventPartners : "",
            organizedby: data.organiseBy ? data.organiseBy : "",
            location: data.placeAndLocation ? data.placeAndLocation : "",
          },
        });
      });
    }
  }

  handleBack = () => {
    history.goBack();
    this.validator.hideMessages();
    this.props.contentManagementTab(3);
  };

  render() {
    let {
      formFields,
      toggleEvent,
      isImageUploaded,
      fileName,
      fileArray,
      isImageUploading,
    } = this.state;

    let {
      // save,
      // share,
      // like,
      // dislike,
      // download,
      online,
      offline,
    } = toggleEvent;

    let {
      title,
      publishDate,
      description,
      registrationLink,
      eventDate,
      agenda,
      author,
      organizedby,
      location,
    } = formFields;

    let { match } = this.props;

    return (
      <div className="add-event">
        <div
          className="d-flex cursor-pointer py-2 mt-1 mb-4 back-area"
          onClick={() => this.handleBack()}
        >
          <i className="icon-arrow-left fs-24"></i>
          <h5 className="heading-20 ml-1 pl-3 mb-0">
            {match.params.id === undefined ? "Add" : "Edit"} event
          </h5>
        </div>
        <div className="event-details">
          <div className="">
            <h3 className="event-heading mb-0">Event details</h3>
          </div>
          <div className="">
            <div className="row">
              <div className="col-12">
                <NormalInput
                  label="Event title"
                  type="text"
                  onChange={this.handleInput}
                  value={title}
                  placeholder="Enter here"
                  name="title"
                  required={true}
                />
                {this.validator.message("title", title, "required|max:200")}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NormalEditor
                  label="Description"
                  data={description}
                  onChange={this.onEditorChange}
                  required={true}
                />
                {this.validator.message(
                  "description",
                  description,
                  "required|max:700"
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <NormalDate
                  value={eventDate}
                  name="eventDate"
                  placeholder="DD/MM/YYYY"
                  onChange={this.handleInput}
                  mindate={true}
                  maxdate={false}
                  timeInput={false}
                  label={"Event date"}
                  required={true}
                />
                {this.validator.message("eventDate", eventDate, "required")}
              </div>
              <div className="col-6">
                <NormalDate
                  value={publishDate}
                  name="publishDate"
                  placeholder="MM/DD/YYYY"
                  onChange={this.handleInput}
                  mindate={true}
                  maxdate={false}
                  timeInput={false}
                  label={"Publication date"}
                  dateFormat="MM/dd/yyyy"
                  required={true}
                />
                {this.validator.message("publishDate", publishDate, "required")}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NormalEditor
                  label="Agenda of the event"
                  data={agenda}
                  onChange={this.onEditorChangeagenda}
                  required={true}
                />
                {this.validator.message("agenda", agenda, "required|max:200")}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NormalInput
                  label="Event partners"
                  type="text"
                  onChange={this.handleInput}
                  value={author}
                  placeholder="Enter here"
                  name="author"
                  required={true}
                />
                {this.validator.message(
                  "Event Partners",
                  author,
                  "required|max:100"
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-4 pt-2">
                <div className="d-flex radio-inputs flex-column justify-content-center">
                  <div className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20">
                    Mode of event{" "}
                    <span className="text-danger pl-2 fs-18">*</span>
                  </div>
                  <div className="d-flex radio-inputs-area">
                    <p className="d-flex align-items-center h-100 pr-5 mr-5 mb-0">
                      <input
                        id="online"
                        type="radio"
                        name="online"
                        checked={online}
                        onChange={(event) => this.handleToggleForEvent(event)}
                      />
                      <label htmlFor="online" className="pl-2 ml-1 fs-15 mb-0">
                        Online
                      </label>
                    </p>
                    <p className="d-flex align-items-center h-100 mb-0">
                      <input
                        id="offline"
                        type="radio"
                        name="offline"
                        checked={offline}
                        onChange={(event) => this.handleToggleForEvent(event)}
                      />
                      <label htmlFor="offline" className="pl-2 ml-1 fs-15 mb-0">
                        Offline
                      </label>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <NormalInput
                  label="Organise by"
                  type="text"
                  onChange={this.handleInput}
                  value={organizedby}
                  placeholder="Enter here"
                  name="organizedby"
                  required={true}
                />
                {this.validator.message(
                  "organizedby",
                  organizedby,
                  "required|max:100"
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <NormalInput
                  label="Place / Location"
                  type="text"
                  onChange={this.handleInput}
                  value={location}
                  placeholder="Enter here"
                  name="location"
                  required={true}
                />
                {this.validator.message(
                  "location",
                  location,
                  "required|max:50"
                )}
              </div>
              <div className="col-6">
                <NormalInput
                  label="Registration link"
                  type="text"
                  onChange={this.handleInput}
                  value={registrationLink}
                  placeholder="Enter here"
                  name="registrationLink"
                  required={true}
                />
                {this.validator.message(
                  "registrationLink",
                  registrationLink,
                  "required|max:200"
                )}
              </div>
            </div>
            {/* <div className='row'>
                     <div className='col-12 my-4 py-2'>
                        <div className='fs-17 text-black font-Gilroy pb-3 mb-1'>
                           Viewers able to
                        </div>
                        <div className='row'>
                           <div className='col'>
                              <NormalCheckbox
                                 value=''
                                 label='Save'
                                 name='save'
                                 checked={save}
                                 onChange={(event) => this.handleToggle(event)}
                              />
                           </div>
                           <div className='col'>
                              <NormalCheckbox
                                 value=''
                                 label='Share'
                                 name='share'
                                 checked={share}
                                 onChange={(event) => this.handleToggle(event)}
                              />
                           </div>
                           <div className='col'>
                              <NormalCheckbox
                                 value=''
                                 label='Download'
                                 name='download'
                                 checked={download}
                                 onChange={(event) => this.handleToggle(event)}
                              />
                           </div>
                           <div className='col'>
                              <NormalCheckbox
                                 value=''
                                 label='Like'
                                 name='like'
                                 checked={like}
                                 onChange={(event) => this.handleToggle(event)}
                              />
                           </div>
                           <div className='col'>
                              <NormalCheckbox
                                 value=''
                                 label='Dislike'
                                 name='dislike'
                                 checked={dislike}
                                 onChange={(event) => this.handleToggle(event)}
                              />
                           </div>
                        </div>
                     </div>
                  </div> */}
            <div className="mb-2 mt-3">
              <div className="fs-17 text-black font-Gilroy pb-2">
                Upload image <span className="text-danger pl-2 fs-18">*</span>
              </div>
              <div className="d-flex align-items-center w-100 btn-file-input">
                <button className="">
                  <input
                    type="file"
                    className="h-100"
                    accept="image/*"
                    onChange={this.uploadSingleFile}
                  ></input>
                  {isImageUploading ? (
                    <div
                      id="image-upload"
                      className="d-flex align-items-center"
                    ></div>
                  ) : fileName == "" ? (
                    "Upload"
                  ) : (
                    getDescriptionData(fileName, 15)
                  )}
                </button>
                {fileArray.length != 0 && !isImageUploading ? (
                  <div className="ml-4">
                    <img
                      src={fileArray[0]}
                      alt=""
                      className="upload-image-preview"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {isImageUploaded ? (
                <span className="error-message text-danger fs-16 pb-3">
                  Image field is required
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center add-event-buttons">
          <div className="ml-auto d-flex">
            <NormalButton
              label="Cancel"
              outline={true}
              className="px-4 mr-2 border-none"
              onClick={() => history.goBack()}
            />
            <NormalButton
              label={match.params.id === undefined ? "Publish" : "Update"}
              mainbg={true}
              className="px-4"
              onClick={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
      addEvents,
      getEventsById,
      updateEvents,
      contentManagementTab,
    },
    dispatch
  );
};

let component = AddeventClass;

export const Addevent = connect(null, mapDispatchToProps)(component);
