import React, { Component } from "react";
import { AddintroducingVideo } from "component/courseManagement/index";
import { connect } from "react-redux";

import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

class AddIntroducingVideosClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <AddintroducingVideo match={this.props.match} />;
  }
}

export const AddIntroducingVideos = connect(
  null,
  null
)(AddIntroducingVideosClass);
