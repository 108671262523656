import { generateQuery } from "./helperFunctions";

export const authApi = {
  adminLogin: {
    api: "user/auth/admin/login",
    method: "post",
    baseURL: "auth",
  },
  forgotPasswordLink: {
    api: "user/auth/admin/forgot/password",
    method: "post",
    baseURL: "auth",
  },
  forgotPasswordTokeValidation: {
    api: "user/auth/admin/check/forgot/password/token",
    method: "get",
    baseURL: "auth",
  },
  changePassword: {
    api: "user/auth/admin/change/forgot/password",
    method: "post",
    baseURL: "auth",
  },
  changePasswordAfterLogin: {
    api: "user/auth/change/password/admin",
    method: "post",
    baseURL: "auth",
  },
};

export const courseManagementApi = {
  getCategoryList: {
    api: "academy/category/list",
    method: "get",
    baseURL: "Course",
  },
  getCategoryDetail: {
    url: "academy/course/list/",
    method: "get",
    baseURL: "Course",
    courseId: null,
    get api() {
      return this.url + this.courseId;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createCourse: {
    api: "academy/course/create",
    method: "post",
    baseURL: "Course",
  },
  updateCourse: {
    api: "academy/course/update",
    method: "put",
    baseURL: "Course",
  },
  addSections: {
    api: "academy/course/sections/add",
    method: "post",
    baseURL: "Course",
  },
  updateSections: {
    api: "academy/course/sections/update",
    method: "put",
    baseURL: "Course",
  },
  addPractices: {
    api: "academy/course/practice/file/add",
    method: "post",
    baseURL: "Course",
  },
  updatePractices: {
    api: "academy/course/practice/file/update",
    method: "put",
    baseURL: "Course",
  },
  addQuiz: {
    api: "academy/course/quiz/add",
    method: "post",
    baseURL: "Course",
  },
  updateQuiz: {
    api: "academy/course/quiz/update",
    method: "put",
    baseURL: "Course",
  },
  pusblishCourse: {
    api: "academy/course/publish",
    method: "post",
    baseURL: "Course",
  },
  addTrendingVideo: {
    api: "academy/trendingvideo/add",
    method: "post",
    baseURL: "Course",
  },
  getVideoDetail: {
    url: "academy/trendingvideo/list/",
    method: "get",
    baseURL: "Course",
    videoId: null,
    get api() {
      return this.url + this.videoId;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateVideo: {
    api: "academy/trendingvideo/update",
    method: "put",
    baseURL: "Course",
  },
  addBlog: {
    api: "academy/blog/add",
    method: "post",
    baseURL: "Course",
  },
  getBlogDetail: {
    url: "academy/blog/list/",
    method: "get",
    baseURL: "Course",
    blogId: null,
    get api() {
      return this.url + this.blogId;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateBlog: {
    api: "academy/blog/update",
    method: "put",
    baseURL: "Course",
  },
  getCourseListForDropdown: {
    api: "academy/course/list/dropdown",
    method: "get",
    baseURL: "Course",
  },
  getCourseList: {
    url: "academy/course/list",
    method: "get",
    baseURL: "Course",
    query: {
      limit: 10,
      page: 1,
      filter: "",
      filterType: "",
      search: "",
      sortBy: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getPublishedCourseList: {
    url: "academy/course/list/published",
    method: "get",
    baseURL: "Course",
    query: {
      limit: 10,
      page: 1,
      filter: "",
      filterType: "",
      search: "",
      sortBy: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getTrendingVideoList: {
    url: "academy/trendingvideo/list",
    method: "get",
    baseURL: "Course",
    query: {
      limit: 10,
      page: 1,
      filter: "",
      filterType: "",
      search: "",
      sortBy: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getBlogList: {
    url: "academy/blog/list",
    method: "get",
    baseURL: "Course",
    query: {
      limit: 10,
      page: 1,
      filter: "",
      filterType: "",
      search: "",
      sortBy: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getIntroducingVideoList: {
    api: "academy/introvideo/getvideos",
    method: "get",
    baseURL: "Course",
  },
  createIntroductionVideo: {
    api: "academy/introvideo/addvideo",
    method: "post",
    baseURL: "Course",
  },
  introductionVideoById: {
    url: "academy/introvideo/getvideos/",
    method: "get",
    baseURL: "Course",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateIntroductionVideo: {
    api: "academy/introvideo/updatevideo",
    method: "put",
    baseURL: "Course",
  },
  deleteintroducingVideoById: {
    url: "academy/introvideo/deletevideo/",
    method: "delete",
    baseURL: "Course",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  fileUpload: {
    api: "documents/file/upload1",
    method: "post",
    baseURL: "file",
  },
  multipleImageUpload: {
    api: "documents/file/upload2",
    method: "post",
    baseURL: "file",
  },
  deleteCourseById: {
    url: "academy/course/delete/",
    method: "delete",
    baseURL: "Course",
    courseId: null,
    get api() {
      return this.url + this.courseId;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteVideosById: {
    url: "academy/trendingvideo/delete/",
    method: "delete",
    baseURL: "Course",
    videoId: null,
    get api() {
      return this.url + this.videoId;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteblogById: {
    url: "academy/blog/delete/",
    method: "delete",
    baseURL: "Course",
    blogId: null,
    get api() {
      return this.url + this.blogId;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAuthorList: {
    url: "academy/author/list",
    method: "get",
    baseURL: "Course",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addAuthor: {
    api: "academy/author",
    method: "post",
    baseURL: "Course",
  },
  deleteAuthorById: {
    url: "academy/author/",
    method: "delete",
    baseURL: "Course",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAuthorById: {
    url: "academy/view/author/",
    method: "get",
    baseURL: "Course",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateAuthor: {
    api: "academy/author",
    method: "put",
    baseURL: "Course",
  },
  getCertifyingInstituteList: {
    url: "academy/institute/list",
    method: "get",
    baseURL: "Course",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addCertifyingInstitute: {
    api: "academy/institute",
    method: "post",
    baseURL: "Course",
  },
  deleteCertifyingInstituteById: {
    url: "academy/institute/",
    method: "delete",
    baseURL: "Course",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateCertifyingInstitute: {
    api: "academy/institute",
    method: "put",
    baseURL: "Course",
  },
  getInstituteById: {
    url: "academy/view/institute/",
    method: "get",
    baseURL: "Course",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCertifyingInstituteDropdown: {
    api: "academy/institute/list",
    method: "get",
    baseURL: "Course",
  },
  getAuthorDropdown: {
    api: "academy/author/list",
    method: "get",
    baseURL: "Course",
  },
};

export const contentMaganementApi = {
  addReports: {
    api: "content/reports/add",
    method: "post",
    baseURL: "Content",
  },
  getAllReports: {
    url: "content/reports/list",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getReportsById: {
    url: "content/reports/get",
    method: "get",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  updateReportsById: {
    url: "content/reports/update",
    method: "put",
    baseURL: "Content",
    reportId: 1,
    get api() {
      return this.url + "/" + this.reportId;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteReportsById: {
    url: "content/reports/delete",
    method: "delete",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },

  addNews: {
    api: "content/news/news/add",
    method: "post",
    baseURL: "Content",
  },
  updateNews: {
    api: "content/news/news/update",
    method: "put",
    baseURL: "Content",
  },
  getAllNews: {
    url: "content/news/news/get",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getNewsById: {
    url: "content/news/news",
    method: "get",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteNewsById: {
    url: "content/news/delete",
    method: "delete",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },

  addStories: {
    api: "content/stories/stories/add",
    method: "post",
    baseURL: "Content",
  },
  updateStories: {
    api: "content/stories/stories/update",
    method: "put",
    baseURL: "Content",
  },
  getAllStories: {
    url: "content/stories/stories/get",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getStoriesById: {
    url: "content/stories/stories/getById",
    method: "get",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteStoriesById: {
    url: "content/stories/delete",
    method: "delete",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },

  addTestimonials: {
    api: "content/testimonial/add/testimonial",
    method: "post",
    baseURL: "Content",
  },
  updateTestimonials: {
    api: "content/testimonial/update/testimonial",
    method: "put",
    baseURL: "Content",
  },
  getAllTestimonials: {
    url: "content/testimonial/get/testimonial",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getTestimonialsById: {
    url: "content/testimonial/get/testimonial",
    method: "get",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteTestimonialsById: {
    url: "content/testimonial/delete",
    method: "delete",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },

  addEvents: {
    api: "content/event/add/events",
    method: "post",
    baseURL: "Content",
  },
  updateEvents: {
    api: "content/event/update/event",
    method: "put",
    baseURL: "Content",
  },
  getAllEvents: {
    url: "content/event/get/events",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getEventsById: {
    url: "content/event/get/event",
    method: "get",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteEventsById: {
    url: "content/event/delete",
    method: "delete",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
};

export const faqApi = {
  getFaqList: {
    url: "faq/allquestions",
    method: "get",
    baseURL: "faq",
    query: {
      limit: 10,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addFaq: {
    api: "faq/add",
    method: "post",
    baseURL: "faq",
  },
  deleteFaq: {
    url: "faq/delete/",
    method: "delete",
    baseURL: "faq",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateFaq: {
    api: "faq/update",
    method: "put",
    baseURL: "faq",
  },
  getFaqById: {
    url: "faq/question/",
    method: "get",
    baseURL: "faq",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const dashboard = {
  getRequestFormList: {
    url: "user/request/list",
    method: "get",
    baseURL: "auth",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRoboCallRequestList: {
    url: "user/request/robocallAudio/list",
    method: "get",
    baseURL: "auth",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  rejectRoboCall: {
    api: "user/registration/request/robocall/reject",
    method: "post",
    baseURL: "auth",
  },
  acceptRoboCall: {
    api: "user/registration/request/robocall/accept",
    method: "post",
    baseURL: "auth",
  },
  getRoboCallRequestCount: {
    api: "user/dashboard/admin/robocall",
    method: "get",
    baseURL: "auth",
  },
  rejectUser: {
    api: "user/registration/request/reject",
    method: "post",
    baseURL: "auth",
  },
  acceptUser: {
    api: "user/registration/request/accept",
    method: "post",
    baseURL: "auth",
  },
  getDashboardDetails: {
    url: "fpo/management/dashboard/admin",
    method: "get",
    baseURL: "faq",
    query: {
      program: "",
      state: "",
      district: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&district=" +
        this.query.district +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDashboardGraphDetails: {
    url: "fpo/management/dashboard/admin/trandGraph",
    method: "get",
    baseURL: "faq",
    query: {
      search: "",
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate +
        "&search=" +
        this.query.search
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDashboardBusinessHome: {
    url: "fpo/management/dashboard/admin/business/home",
    method: "get",
    baseURL: "faq",
    query: {
      limit: 10,
      page: 1,
      search: "",
      state: "",
      stateHead: "",
      rmName: "",
      program: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?limit=" +
        this.query.limit +
        "&page=" +
        this.query.page +
        "&search=" +
        this.query.search +
        "&program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDashboardBusinessMember: {
    url: "fpo/management/dashboard/admin/business/member",
    method: "get",
    baseURL: "faq",
    query: {
      limit: 10,
      page: 1,
      search: "",
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?limit=" +
        this.query.limit +
        "&page=" +
        this.query.page +
        "&search=" +
        this.query.search +
        "&program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDashboardBusinessFIG: {
    url: "fpo/management/dashboard/admin/business/fig",
    method: "get",
    baseURL: "faq",
    query: {
      limit: 10,
      page: 1,
      search: "",
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?limit=" +
        this.query.limit +
        "&page=" +
        this.query.page +
        "&search=" +
        this.query.search +
        "&program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDashboardBusinessKYM: {
    url: "fpo/management/dashboard/admin/business/kym",
    method: "get",
    baseURL: "faq",
    query: {
      limit: 10,
      page: 1,
      search: "",
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?limit=" +
        this.query.limit +
        "&page=" +
        this.query.page +
        "&search=" +
        this.query.search +
        "&program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDashboardBusinessBMS: {
    url: "fpo/management/dashboard/admin/business/bms",
    method: "get",
    baseURL: "faq",
    query: {
      limit: 10,
      page: 1,
      search: "",
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?limit=" +
        this.query.limit +
        "&page=" +
        this.query.page +
        "&search=" +
        this.query.search +
        "&program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDashboardBusiness: {
    url: "fpo/management/dashboard/admin/business",
    method: "get",
    baseURL: "faq",
    query: {
      limit: 10,
      page: 1,
      search: "",
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?limit=" +
        this.query.limit +
        "&page=" +
        this.query.page +
        "&search=" +
        this.query.search +
        "&program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDashboardBusinessStateHead: {
    url: "fpo/management/fpo/stateHead/list",
    method: "get",
    baseURL: "faq",
    query: {
      stateId: "",
      stateHeadName: "",
    },
    get api() {
      return (
        this.url +
        "?stateId=" +
        this.query.stateId +
        "&stateHeadName=" +
        this.query.stateHeadName
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRMList: {
    url: "fpo/management/fpo/rmName/list",
    method: "get",
    baseURL: "faq",
    query: {
      stateId: "",
      stateHeadName: "",
    },
    get api() {
      return (
        this.url +
        "?stateId=" +
        this.query.stateId +
        "&stateHeadName=" +
        this.query.stateHeadName
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downloadBusinessHomeData: {
    url: "fpo/management/dashboard/admin/business/home/download/csv",
    method: "get",
    baseURL: "faq",
    query: {
      search: "",
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?search=" +
        this.query.search +
        "&program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downloadBusinessMembersData: {
    url: "fpo/management/dashboard/admin/business/member/download/csv",
    method: "get",
    baseURL: "faq",
    query: {
      search: "",
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?search=" +
        this.query.search +
        "&program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downloadBusinessFIGData: {
    url: "fpo/management/dashboard/admin/business/fig/download/csv",
    method: "get",
    baseURL: "faq",
    query: {
      search: "",
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?search=" +
        this.query.search +
        "&program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downloadBusinessKYMData: {
    url: "fpo/management/dashboard/admin/business/kym/download/csv",
    method: "get",
    baseURL: "faq",
    query: {
      search: "",
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?search=" +
        this.query.search +
        "&program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downloadBusinessBMSData: {
    url: "fpo/management/dashboard/admin/business/bms/download/csv",
    method: "get",
    baseURL: "faq",
    query: {
      search: "",
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
    },
    get api() {
      return (
        this.url +
        "?search=" +
        this.query.search +
        "&program=" +
        this.query.program +
        "&state=" +
        this.query.state +
        "&stateHead=" +
        this.query.stateHead +
        "&rmName=" +
        this.query.rmName +
        "&startDate=" +
        this.query.startDate +
        "&endDate=" +
        this.query.endDate
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDashboardDetailsforAcadamy: {
    url: "academy/dashboard/admin",
    method: "get",
    baseURL: "Course",
    query: {
      type: "course",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDashboardDetailsforuser: {
    api: "user/dashboard/admin",
    method: "get",
    baseURL: "auth",
  },
  getTopPerformanceOfFPO: {
    api: "user/auth/top/fpos",
    method: "get",
    baseURL: "auth",
  },
};

export const FeedbackApi = {
  getFeedbackList: {
    url: "feedback/allfeedback",
    method: "get",
    baseURL: "faq",
    query: {
      limit: 10,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFeedbackById: {
    url: "feedback/feedback/",
    method: "get",
    baseURL: "faq",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
};

export const Master = {
  pincodemaster: {
    api: "master/add/pincodemaster",
    method: "post",
    baseURL: "faq",
  },
  stateList: {
    api: "fpo/management/fpo/state/list",
    method: "post",
    baseURL: "faq",
  },
  districtList: {
    api: "fpo/management/fpo/district/list",
    method: "get",
    baseURL: "faq",
  },
  addbusinessActivity: {
    api: "members/fpo/members/master/businessActivity",
    method: "post",
    baseURL: "faq",
  },
  addkeyfinancialDetails: {
    api: "keyfinancial/master/business/activity",
    method: "post",
    baseURL: "keyFinancials",
  },
  addCrops: {
    api: "members/fpo/members/allcrops/add",
    method: "post",
    baseURL: "faq",
  },
  addDistrict: {
    api: "master/add/districtmaster",
    method: "post",
    baseURL: "faq",
  },
  addfinancialDetails: {
    api: "association/institutiontypes/add",
    method: "post",
    baseURL: "faq",
  },
  addeducationQualification: {
    api: "fpo/management/education/qualification/add",
    method: "post",
    baseURL: "faq",
  },
  addfinancialYear: {
    api: "master/add/financialYear",
    method: "post",
    baseURL: "faq",
  },
  addinfraDetails: {
    api: "master/add/inframaster",
    method: "post",
    baseURL: "faq",
  },
  addinsititutionDetails: {
    api: "keyfinancial/master/institution",
    method: "post",
    baseURL: "keyFinancials",
  },
  addlicenceDetails: {
    api: "compliance/getmasters/add/licensemaster",
    method: "post",
    baseURL: "licence",
  },
  addliveStockDetails: {
    api: "members/fpo/members/livestocktypes/add",
    method: "post",
    baseURL: "faq",
  },
  addState: {
    api: "master/add/statemaster",
    method: "post",
    baseURL: "faq",
  },
  addAssetDetails: {
    api: "members/fpo/members/assettype/add",
    method: "post",
    baseURL: "faq",
  },
  addKycDocumentDetails: {
    api: "master/add/kycmaster",
    method: "post",
    baseURL: "faq",
  },
};

export const forum = {
  createForum: {
    api: "community/forum/admin/create",
    method: "post",
    baseURL: "forum",
  },
  updateForum: {
    url: "community/forum/admin/",
    method: "put",
    baseURL: "forum",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getForumList: {
    url: "community/forum/admin/list",
    baseURL: "forum",
    method: "get",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getForumById: {
    url: "community/forum/",
    baseURL: "forum",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getForumComments: {
    url: "community/comment/list/",
    method: "get",
    baseURL: "forum",
    id: null,
    query: {
      limit: 5,
      page: 1,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteForumById: {
    url: "community/forum/admin/",
    baseURL: "forum",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  list: {
    api: "community/category/list",
    method: "get",
    baseURL: "forum",
  },
};

export const FPO = {
  allState: {
    api: "members/fpo/members/allstate",
    method: "get",
    baseURL: "fpo",
  },
  getcropBusinessInfra: {
    api: "fpo/management/fpo/cropBusinessInfra/list",
    method: "get",
    baseURL: "fpo",
  },
  getPincodeApiwithStateDistrict: {
    url: "members/fpo/members/allpincode/",
    method: "get",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getDistrictApiwithState: {
    url: "members/fpo/members/alldistrict/",
    method: "get",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getFpoList: {
    url: "fpo/management/fpolist",
    baseURL: "fpo",
    method: "get",
    query: {
      limit: 10,
      page: 1,
      search: "",
      sortBy: "",
      filterBy: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addFPO: {
    api: "user/auth/admin/create/fpo",
    baseURL: "auth",
    method: "post",
  },
  getFpoBasicDetailsById: {
    url: "fpo/management/fpolist/",
    baseURL: "fpo",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFpoMemberList: {
    url: "fpo/management/fpomembers/",
    baseURL: "fpo",
    method: "get",
    id: null,
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteMember: {
    url: "fpo/management/deleteFpoMemeber",
    method: "put",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getFpoBodList: {
    url: "fpo/management/bods/",
    baseURL: "fpo",
    method: "get",
    id: null,
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFpoStaffList: {
    url: "fpo/management/staffs/",
    baseURL: "fpo",
    method: "get",
    id: null,
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFpoCompanyKYC: {
    url: "fpo/management/fpo/companykyc/view/",
    baseURL: "fpo",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFpoStuatary: {
    url: "fpo/management/fpo/statutory/view/",
    baseURL: "fpo",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFpodirectdoc: {
    url: "fpo/management/fpo/directdoc/view/",
    baseURL: "fpo",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFpofinancial: {
    url: "fpo/management/fpo/financial/view/",
    baseURL: "fpo",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFpocreditdata: {
    url: "fpo/management/fpo/creditdata/view/",
    baseURL: "fpo",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCompanyKycBulkdownload: {
    url: "fpo/management/fpo/companykyc/admin/download",
    method: "get",
    baseURL: "fpo",
    query: {
      fpoId: null,
      docId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFinancialBulkdownload: {
    url: "fpo/management/fpo/financial/admin/download",
    method: "get",
    baseURL: "fpo",
    query: {
      fpoId: null,
      docId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDirectdocBulkdownload: {
    url: "fpo/management/fpo/directdoc/admin/download",
    method: "get",
    baseURL: "fpo",
    query: {
      fpoId: null,
      docId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getcreditdataBulkdownload: {
    url: "fpo/management/fpo/creditdata/admin/download",
    method: "get",
    baseURL: "fpo",
    query: {
      fpoId: null,
      docId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getstatutoryBulkdownload: {
    url: "fpo/management/fpo/statutory/admin/download",
    method: "get",
    baseURL: "fpo",
    query: {
      fpoId: null,
      docId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getBodById: {
    url: "fpo/management/bod",
    method: "get",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getstaffById: {
    url: "fpo/management/staff",
    method: "get",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getMemberById: {
    url: "fpo/management/member",
    method: "get",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  downloadCsvforFpoMembers: {
    url: "fpo/management/member/list/download/csv",
    method: "get",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getDownloadBusinessKYMMembersData: {
    url: "fpo/management/dashboard/admin/business/kym/member/download/csv",
    method: "post",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  downloadCsvforFpoBod: {
    url: "fpo/management/bod/list/download/csv",
    method: "get",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  downloadCsvforFpoStaff: {
    url: "fpo/management/staff/list/download/csv",
    method: "get",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteFpoById: {
    url: "fpo/management/deletefpo",
    method: "delete",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  federationType: {
    api: "fpo/management/updateFpoVertical",
    baseURL: "fpo",
    method: "put",
  },
  downloadGradingToolExcel: {
    api: "fpo/management/fpo/grading/sample/excel",
    method: "get",
    baseURL: "fpo",
  },
  uploadGradingToolExcel: {
    api: "fpo/management/bulkupload/grading",
    method: "post",
    baseURL: "fpo",
  },
  editFPO: {
    api: "user/auth/token/fpo",
    method: "post",
    baseURL: "auth",
  },
  getAllFinancialYearDropdown: {
    api: "fpo/management/fpo/financialMaster",
    method: "get",
    baseURL: "member",
  },
  supportedLanguages: {
    api: "templates/languages",
    method: "get",
    baseURL: "campaign",
  },
  farmerCrop: {
    api: "farmerCrop/",
    method: "get",
    baseURL: "f3c",
  },
  subscribeAlerts: {
    url: "fpo/management/subscribeAlerts",
    method: "post",
    baseURL: "fpo",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getSubscribeAlerts: {
    url: "fpo/management/getSubscribeAlerts",
    method: "get",
    baseURL: "fpo",
    id: null,
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  roboCallReport: {
    api: "fpo/robocall/download/report",
    method: "post",
    baseURL: "fpo",
  },
  getRoboCallHistory: {
    url: "fpo/robocall/robocallAudio/traksheet/list",
    method: "post",
    baseURL: "fpo",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getSmsHistory: {
    url: "members/sms/traksheet/list",
    method: "post",
    baseURL: "fpo",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  smsReport: {
    api: "members/sms/download/report",
    method: "post",
    baseURL: "fpo",
  },
  smsIndividualReport: {
    api: "members/sms/download",
    method: "post",
    baseURL: "fpo",
  },
  signUpEntityType: {
    api: "fpo/management/fpo/entityType/list",
    method: "get",
    baseURL: "member",
  },
  emailAndNumberValidation: {
    api: "user/fpo/signup/validate",
    method: "post",
    baseURL: "auth",
  },
};

export const complianceCalender = {
  complianceCalenderList: {
    url: "fpo/task/admin/list",
    baseURL: "fpo",
    method: "get",
    query: {
      limit: 10,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getComplianceTaskById: {
    url: "fpo/task/admin/list/",
    baseURL: "fpo",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  addFpoTask: {
    api: "fpo/task/admin/add",
    baseURL: "fpo",
    method: "post",
  },
  updateFpoTask: {
    api: "fpo/task/admin/update",
    baseURL: "fpo",
    method: "put",
  },
};

export const roleManagement = {
  roleManagementList: {
    url: "user/role/management/user/list",
    baseURL: "auth",
    method: "get",
    query: {
      limit: 10,
      page: 1,
      search: "",
      filterBy: "",
      filterValue: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getUserRolesList: {
    api: "user/role/management/user/roles/list",
    baseURL: "auth",
    method: "get",
  },
  getFpoListForMapping: {
    url: "fpo/management/rmmapping/list",
    baseURL: "fpo",
    method: "get",
    query: {
      userMasterId: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  fpoMappingToRm: {
    api: "fpo/management/rmmapping/add",
    baseURL: "fpo",
    method: "post",
  },
  getFpomanagementForFpo: {
    url: "user/role/management/roles/",
    baseURL: "auth",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateRoleForFPO: {
    api: "user/role/management/roles/update",
    baseURL: "auth",
    method: "post",
  },
  getRMList: {
    url: "fpo/management/fpo/rm/list",
    method: "get",
    baseURL: "faq",
    query: {
      stateId: "",
      stateHeadName: "",
    },
    get api() {
      return (
        this.url +
        "?stateId=" +
        this.query.stateId +
        "&stateHeadName=" +
        this.query.stateHeadName
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  rmFPOMapping: {
    url: "fpo/management/rmmapping/add",
    method: "post",
    baseURL: "fpo",
    query: {
      userId: "",
      fpoIds: "",
    },
    get api() {
      return (
        this.url +
        "?userId=" +
        this.query.userId +
        "&fpoIds=" +
        this.query.fpoIds
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getMappedFPO: {
    url: "fpo/management/rmmapping/fpo/mappedlist/",
    method: "get",
    baseURL: "fpo",
    id: null,
    query: {
      page: 1,
      limit: 10,
      search: "",
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteMappedFPO: {
    url: "fpo/management/rmmapping/fpo/remove/",
    method: "delete",
    baseURL: "fpo",
    rmId: null,
    fpoId: null,
    query: {},
    get api() {
      return (
        this.url + this.rmId + "/" + this.fpoId + generateQuery(this.query)
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};
