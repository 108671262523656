import React, { Fragment, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  getRMList,
  getMappedFPO,
  deleteMappedFPO,
} from "action/RoleMagementAct";
import {
  TableWrapper,
  CommonPageLoader,
  NormalSelect,
  DeletePopup,
  InputSearch,
} from "component/common";
import fpoBazaar from "assets/images/fpobazaar.png";
import "./../style.scss";
import { debounceFunction } from "service/debounce";

const headerDetails = [
  { label: "FPO ID" },
  { label: "FPO Name" },
  { label: "State" },
  { label: "Registered by" },
  { label: "Mapping date" },
];

const ViewMappedFPO = (props) => {
  const { getRMListApi, getMappedFPOApi, deleteMappedFPOApi } = props;
  const [loading, setLoading] = useState(false);
  const [rmData, setRMData] = useState([]);
  const [fpoList, setFPOList] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [selectedRM, setSelectedRM] = useState("");
  const [isDelete, setDelete] = useState(false);
  const [selectedFPO, setSelectedFPO] = useState(null);
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    getRMListApi().then(({ data }) => {
      let rmData = data.map((list) => ({
        label: list.rmEmail,
        value: list.userId,
      }));
      setRMData([...rmData]);
    });
  }, [getRMListApi]);

  const getRMFPOListData = useCallback(
    (page = 1, search = "") => {
      if (selectedRM) {
        setLoading(true);
        getMappedFPOApi(selectedRM, { page, search })
          .then(({ data }) => {
            const { results, pageMeta } = data;
            setFPOList(results);
            setPageMeta(pageMeta);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    },
    [getMappedFPOApi, selectedRM]
  );

  useEffect(() => {
    getRMFPOListData(1, "");
  }, [getRMFPOListData]);

  const handlePageChange = ({ page }) => {
    getRMFPOListData(page, "");
  };

  const deleteMapping = () => {
    deleteMappedFPOApi(selectedRM, selectedFPO).then(() => {
      setSelectedFPO(null);
      getRMFPOListData(1, "");
      setDelete(false);
    });
  };

  const handleSearch = ({ target: { value } }) => {
    setSearchData(value);
    debounceFunction(() => getRMFPOListData(1, value), 1000);
  };

  return (
    <div className="role-list">
      <div className="row" id="rmFpoViewMappedMobile">
        <div className="col-12 pb-3">
          <NormalSelect
            arrow={true}
            value={selectedRM}
            options={rmData}
            name="selectedRM"
            handleChange={(e) => setSelectedRM(e.target.value)}
            label="RM User Email"
            placeholder="Select"
            className="form-control-mobile"
          />
        </div>
        <div className="col-12">
            <InputSearch
              className="mr-4 rmFPOSearchBox"
              placeholder={"Search by FPO Name/FPO Id"}
              value={searchData}
              onChange={(e) => handleSearch(e)}
            />
          </div>
      </div>
      <div id="rmFpoViewMappedDesktop">
        <div className="d-flex justify-content-between align-items-end">
          <NormalSelect
            arrow={true}
            value={selectedRM}
            options={rmData}
            name="selectedRM"
            handleChange={(e) => setSelectedRM(e.target.value)}
            label="RM User Email"
            placeholder="Select"
          />
          <div>
            <InputSearch
              className="mr-4 rmFPOSearchBox"
              placeholder={"Search by FPO Name/FPO Id"}
              value={searchData}
              onChange={(e) => handleSearch(e)}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <CommonPageLoader />
      ) : (
        <div className="table-container w-100 mt-4 pt-1">
          {fpoList?.length !== 0 ? (
            <TableWrapper
              headerDetails={headerDetails}
              queryHandler={handlePageChange}
              pageMeta={pageMeta}
            >
              {fpoList.map(
                (
                  {
                    fpoId,
                    fpoDetails: { fpoName, isFederation, stateMaster },
                    createdAt,
                  },
                  index
                ) => {
                  return (
                    <Fragment key={index}>
                      <tr className="new-user-bg">
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {fpoId}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {fpoName}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100">
                            {stateMaster?.stateName}
                          </div>
                        </td>
                        <td>
                          {isFederation === 1 ? (
                            <img
                              src={fpoBazaar}
                              height="60px"
                              width="60px"
                              alt="fpoBazaarIcon"
                            />
                          ) : (
                            "Farmer Network"
                          )}
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-between">
                            {moment(createdAt)
                              .tz("Asia/Kolkata")
                              .format("DD-MM-YYYY")}
                            <i
                              className="icon-Delete fs-20 cursor-pointer text-danger"
                              onClick={() => {
                                setDelete(true);
                                setSelectedFPO(fpoId);
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                      <tr className="trans-bg">
                        <td className="empty-height" height="12"></td>
                      </tr>
                    </Fragment>
                  );
                }
              )}
            </TableWrapper>
          ) : (
            <div className="text-center fs-18 text-black py-5">
              {selectedRM && fpoList?.length === 0
                ? "No records found !!!"
                : "Please select any RM"}
            </div>
          )}
        </div>
      )}
      {isDelete ? (
        <DeletePopup
          isOpen={isDelete}
          toggle={() => setDelete(!isDelete)}
          handleChange={() => deleteMapping()}
        />
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRMListApi: getRMList,
      getMappedFPOApi: getMappedFPO,
      deleteMappedFPOApi: deleteMappedFPO,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ViewMappedFPO);
