import React, { Component } from "react";

import "./AddCourse.scss";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import {
  NormalInput,
  NormalButton,
  TableStripedWrapper,
  NormalCheckbox,
  NormalTextarea,
} from "component/common";

import { CoursePreviewPopup } from "../CoursePreviewPopup";

import { getCategoryDetail } from "action/CourseAct";

import { history } from "service/helpers";

import SimpleReactValidator from "simple-react-validator";

import {
  updateQuiz,
  addQuiz,
  pusblishCourse,
  pusblishCourseAfterUpdate,
} from "action/CourseAct";
import { getDescriptionData } from "service/helperFunctions";
import { Toast } from "service/toast";

export class QuizQuestionsClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      QuizQuestionAdded: false,
      headerDetails: [
        {
          label: "S. No",
        },
        {
          label: "Question name",
        },
        {
          label: "Type",
        },
        {
          label: "Question values",
        },
        {
          label: "",
        },
      ],
      isQuizUpdate: false,
      isCourseSaved: false,
      currentIndex: "",
      quizId: "",
      courseId: "",
      formFields: {
        questionArray: [
          {
            optionName: "",
            isAnswer: 0,
            isDeleted: false,
            isAdded: false,
            isUpdated: false,
          },
          {
            optionName: "",
            isAnswer: 0,
            isDeleted: false,
            isAdded: false,
            isUpdated: false,
          },
        ],
        answerJustification: "",
        questionName: "",
        quizArray: [],
        tempData: {
          questionName: "",
          type: "Multiple Choice",
          questionValues: "",
          questionArray: [],
          answerJustification: "",
          isDeleted: false,
          isAdded: false,
          isUpdated: false,
        },
      },
      ispreviewPopup: false,
      courseData: {},
      isPopupdataLoading: false,
    };
    //validation
    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="error-message text-danger fs-16 pb-3">{message}</span>
      ),
      autoForceUpdate: this,
    });
  }

  componentDidMount() {
    document.getElementById("overlay-area").style.display = "none";
    let { isEdit, courseDetail } = this.props;
    if (isEdit) {
      let { quizes } = courseDetail;
      this.updateData(quizes);
    }
  }

  updateData = async (quizes) => {
    let { formFields, quizId } = this.state;
    // description
    let quizArrayList = [];

    quizes.map((list) => {
      list.questions.map((data) => {
        let quizDetail = {
          questionName: data.questionName,
          type: "Multiple Choice",
          questionValues: "",
          questionArray: [],
          answerJustification: data.answerJustification,
          questionId: data.questionId,
          options: data.options,
        };
        let optionArrayList = [];

        data.options.map((quizdata) => {
          let optionArray = {
            optionName: quizdata.optionName,
            isAnswer: quizdata.isAnswer ? 1 : 0,
            optionId: quizdata.optionId,
            questionId: quizdata.questionId,
            isDeleted: false,
            isAdded: false,
            isUpdated: false,
          };
          optionArrayList.push(optionArray);
          quizDetail.questionArray = optionArrayList;
        });

        quizArrayList.push(quizDetail);

        formFields.quizArray = quizArrayList;
      });
      quizId = list.quizId;

      this.setState({ formFields, quizId });
    });
  };

  handleInput = ({ target: { name, value } }, index) => {
    //handle inputs
    let { formFields } = this.state;
    if (index !== undefined) {
      formFields["questionArray"][index][name] = value;
    } else {
      formFields[name] = value;
    }
    this.setState({
      formFields,
    });
  };

  handleToggle = ({ target: { name, value } }, index) => {
    let { formFields } = this.state;
    let form = { ...formFields };
    form["questionArray"].map((ques, i) => {
      if (i === index) {
        form["questionArray"][i][name] = value ? 1 : 0;
      } else {
        form["questionArray"][i][name] = 0;
      }
    });
    this.setState({
      formFields: form,
    });
  };

  handleOpenQuestion = (isEdit, index) => {
    //Open side question menu
    document.getElementById("myNav").style.width = "50%";
    document.getElementById("overlay-area").style.display = "block";

    let { formFields } = this.state;

    let { quizArray } = formFields;

    if (isEdit) {
      formFields.questionArray = quizArray[index].questionArray;
      formFields.questionName = quizArray[index].questionName;
      formFields.answerJustification = quizArray[index].answerJustification;

      this.setState({
        formFields,
        isQuizUpdate: true,
        currentIndex: index,
      });
    }
  };

  handledelete = (isDelete, index) => {
    //handle delete

    let { formFields } = this.state;

    formFields.quizArray[index].isDeleted = true;

    this.setState({
      formFields,
    });
  };

  handleClear = () => {
    this.setState({
      formFields: {
        ...this.state.formFields,
        questionArray: [
          {
            optionName: "",
            isAnswer: 0,
            isDeleted: false,
            isAdded: false,
            isUpdated: false,
          },
          {
            optionName: "",
            isAnswer: 0,
            isDeleted: false,
            isAdded: false,
            isUpdated: false,
          },
        ],
        answerJustification: "",
        questionName: "",
      },
    });
  };
  handleCloseQuestion = () => {
    //close side question menu
    document.getElementById("myNav").style.width = "0";
    document.getElementById("overlay-area").style.display = "none";
    this.handleClear();
  };

  handleAddQuestions = () => {
    //Add answer

    let formFields = Object.assign({}, this.state.formFields);
    if (formFields.questionArray.length < 4) {
      formFields.questionArray = formFields.questionArray.concat([
        {
          optionName: "",
          isAnswer: 0,
          isAdded: true,
          isUpdated: false,
          isDelete: false,
        },
      ]);

      this.setState({
        formFields,
      });
    } else {
      Toast({ type: "error", message: "Max amount reached" });
    }
  };

  handleRemoveQuestions = (e, index) => {
    //Remove answer

    let { formFields, currentIndex } = this.state;

    if (formFields.questionArray[index].isAdded) {
      formFields.questionArray = formFields.questionArray.filter(
        (s, sidx) => index !== sidx
      );
    } else {
      formFields.questionArray[index].isDeleted = true;
    }

    this.setState({
      formFields,
    });
  };

  handleSubmit = () => {
    let { formFields } = this.state;

    let { courseDetail } = this.props;

    if (formFields.quizArray.length != 0) {
      let body = {
        courseId: parseInt(
          localStorage.getItem("courseId")
            ? localStorage.getItem("courseId")
            : courseDetail.courseId
        ),
        questions: [],
      };

      formFields.quizArray.map((list, index) => {
        let data = {
          questionName: list.questionName,
          questionSequence: index,
          options: [],
          answerJustification: list.answerJustification,
        };
        list.questionArray.map((optionList) => {
          data.options.push({
            optionName: optionList.optionName,
            isAnswer: optionList.isAnswer == 0 ? false : true,
          });
        });

        body.questions.push(data);
      });

      this.props.addQuiz(body).then(() => {
        this.setState({
          courseId: parseInt(
            localStorage.getItem("courseId")
              ? localStorage.getItem("courseId")
              : courseDetail.courseId
          ),
        });

        localStorage.removeItem("courseId");

        this.setState({ QuizQuestionAdded: false, isCourseSaved: true });
      });
    } else {
      this.setState({ QuizQuestionAdded: true });
    }
  };

  handlePublish = (isPublishAfterUpdate) => {
    let { courseId } = this.state;
    let body = {
      courseId,
    };
    if (isPublishAfterUpdate) {
      this.props.pusblishCourseAfterUpdate(body).then(() => {
        this.props.history.push("/admin/courseManagement");
      });
    } else {
      this.props.pusblishCourse(body).then(() => {
        this.props.history.push("/admin/courseManagement");
      });
    }
  };

  handleUpdate = () => {
    let { formFields, quizId } = this.state;

    let { courseDetail } = this.props;

    if (formFields.quizArray.length != 0) {
      let body = {
        courseId: courseDetail.courseId,
        quizId: quizId,
        questions: [],
      };

      formFields.quizArray.map((list, index) => {
        let data = {
          isUpdated: true,
          questionName: list.questionName,
          questionSequence: index,
          options: [],
          answerJustification: list.answerJustification,
          isAdded: false,
          isDeleted: false,
          questionId: "",
        };

        //checking new question or updated Questions
        if (list.questionId !== undefined) {
          data.questionId = list.questionId;
        } else {
          data.isAdded = true;
          delete data.questionId;
        }

        //Checking deleted status
        if (list.isDeleted !== undefined) {
          data.isDeleted = list.isDeleted;
        }

        list.questionArray.map((optionList) => {
          let optionData = {
            optionName: optionList.optionName,
            isAnswer: optionList.isAnswer == 0 ? false : true,
            isAdded: false,
            isUpdated: true,
            isDeleted: false,
          };

          //New option added
          if (optionList.isAdded != undefined && optionData.isUpdated) {
            optionData.isAdded = optionList.isAdded;
            optionData.isUpdated = false;
          }

          //Is option Deleted
          if (optionList.isDeleted !== undefined) {
            optionData.isDeleted = optionList.isDeleted;
          }

          data.options.push(optionData);
        });

        body.questions.push(data);
      });

      this.props.updateQuiz(body).then(() => {
        this.setState({
          courseId: parseInt(
            localStorage.getItem("courseId")
              ? localStorage.getItem("courseId")
              : courseDetail.courseId
          ),
        });
        localStorage.removeItem("courseId");

        this.handlePublish(true);

        this.props.history.push(`/admin/courseManagement`);

        this.setState({ QuizQuestionAdded: false });
      });
    } else {
      this.setState({ QuizQuestionAdded: true });
    }
  };

  handleQuestion = (isUpdated) => {
    //add question
    let correctAns = [];
    correctAns = this.state.formFields.questionArray.find((ques) => {
      return ques.isAnswer === 1;
    });
    if (correctAns !== undefined) {
      if (this.validator.allValid()) {
        let { formFields, currentIndex } = this.state;

        let {
          tempData,
          quizArray,
          answerJustification,
          questionArray,
          questionName,
        } = formFields;

        if (isUpdated) {
          //Update Questions

          if (quizArray[currentIndex] !== undefined) {
            tempData.questionName = questionName;

            tempData.questionArray = questionArray;

            tempData.answerJustification = answerJustification;

            tempData.questionId = quizArray[currentIndex].questionId;

            quizArray[currentIndex] = tempData;

            formFields = {
              tempData: {
                questionName: "",
                type: "Multiple Choice",
                questionValues: "",
                questionArray: [],
                answerJustification: "",
              },
              answerJustification: "",
              quizArray,
              questionArray: [
                { optionName: "", isAnswer: 0 },
                { optionName: "", isAnswer: 0 },
              ],
              questionName: "",
            };

            this.setState(
              {
                formFields,
                isQuizUpdate: false,
                currentIndex: "",
              },
              () => {
                if (quizArray.length != 0) {
                  this.setState({
                    QuizQuestionAdded: false,
                  });
                }
              }
            );

            this.validator.hideMessages();
          }
        } else {
          tempData.questionName = questionName;

          tempData.questionArray = questionArray;

          tempData.answerJustification = answerJustification;

          tempData.options = questionArray;

          quizArray.push(tempData);

          formFields = {
            tempData: {
              questionName: "",
              type: "Multiple Choice",
              questionValues: "",
              questionArray: [],
              answerJustification: "",
            },
            answerJustification: "",
            quizArray,
            questionArray: [
              { optionName: "", isAnswer: 0 },
              { optionName: "", isAnswer: 0 },
            ],
            questionName: "",
          };

          this.setState(
            {
              formFields,
            },
            () => {
              if (quizArray.length != 0) {
                this.setState({
                  QuizQuestionAdded: false,
                });
              }
            }
          );

          this.validator.hideMessages();
        }

        document.getElementById("myNav").style.width = "0";
        document.getElementById("overlay-area").style.display = "none";
      } else {
        this.validator.showMessages();
      }
    } else {
      Toast({ type: "error", message: "Select one correct answer" });
    }
  };

  handlePreview = () => {
    let { formFields, courseId } = this.state;

    let { courseDetail } = this.props;

    if (formFields.quizArray.length != 0) {
      this.togglePreview("ispreviewPopup");
      this.setState({ isPopupdataLoading: true }, () => {
        this.props
          .getCategoryDetail(
            localStorage.getItem("courseId")
              ? localStorage.getItem("courseId")
              : courseId
              ? courseId
              : courseDetail.courseId
          )
          .then((data) => {
            this.setState({
              courseData: data,
              isPopupdataLoading: false,
            });
          });
      });
    } else {
      this.setState({ QuizQuestionAdded: true });
    }
  };

  togglePreview = (name) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  render() {
    let {
      formFields,
      headerDetails,
      QuizQuestionAdded,
      isQuizUpdate,
      quizId,
      isCourseSaved,
      courseData,
      ispreviewPopup,
      isPopupdataLoading,
    } = this.state;

    let {
      quizArray,
      questionArray,
      questionName,
      answerJustification,
    } = formFields;

    return (
      <>
        <div className="quiz-details">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="course-heading pb-4">Quiz questions</h5>
          </div>

          {quizArray.length !== 0 ? (
            <TableStripedWrapper headerDetails={headerDetails}>
              {quizArray.map((item, index) => {
                let { questionName, type, options, isDeleted } = item;

                return (
                  <tr key={index}>
                    <td>
                      <div className="px-3">{index + 1}</div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center h-100">
                        {questionName}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center h-100">
                        {type}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center h-100">
                        {options?.length > 0 &&
                          getDescriptionData(
                            options
                              .map((option) => {
                                return option.isAnswer ? option.optionName : "";
                              })
                              .join(""),
                            20
                          )}
                      </div>
                    </td>
                    {isDeleted != true ? (
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {/* <i className='icon-question-menu fs-24 cursor-pointer mr-3'></i> */}
                          <i
                            className="icon-edit fs-24 cursor-pointer mr-3"
                            onClick={() => this.handleOpenQuestion(true, index)}
                          ></i>
                          <i
                            className="icon-Delete fs-24 cursor-pointer"
                            onClick={() => this.handledelete(true, index)}
                          ></i>
                        </div>
                      </td>
                    ) : (
                      <td>
                        <div className="d-flex align-items-center h-100">
                          <i className="icon-edit fs-24 mr-3"></i>
                          <i className="icon-Delete fs-24"></i>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </TableStripedWrapper>
          ) : null}
          {QuizQuestionAdded ? (
            <span className="error-message text-danger fs-16">
              Add One Quiz Question
            </span>
          ) : null}
          <div className="mr-auto d-flex">
            <NormalButton
              label="Add question"
              outline={true}
              className="px-4 mr-2 font-Gilroy"
              onClick={() => this.handleOpenQuestion()}
            />
          </div>
          <div className="overlay-area" id="overlay-area">
            <div id="myNav" className="overlay">
              <div className="add-question">
                <div className="h-100 d-flex">
                  <h5 className="fs-20 text-black font-Gilroy-Medium line-height-23 mx-auto h-100">
                    Add questions
                  </h5>
                  <div
                    className="close-area"
                    onClick={() => this.handleCloseQuestion()}
                  >
                    <i className="icon-close fs-14 cursor-pointer ml-auto"> </i>
                  </div>
                </div>
              </div>
              <div className="overlay-content">
                <div className="row mb-1 pb-3">
                  <div className="col-12">
                    <NormalInput
                      label="Enter question name"
                      type="text"
                      onChange={this.handleInput}
                      value={questionName}
                      placeholder="Enter here"
                      name="questionName"
                      required={true}
                    />
                    {this.validator.message(
                      "questionName",
                      questionName,
                      "required"
                    )}
                  </div>
                </div>
                <div className="question-area">
                  <div className="row">
                    <div className="col-9">
                      <h5 className="fs-18 text-black-4D mb-1 pb-3 font-Gilroy-Medium line-height-21">
                        Question values
                        <span className="text-danger pl-2 fs-18">*</span>
                      </h5>
                    </div>
                    <div className="col-3 correct-answer-selection text-center">
                      <h5 className="fs-15 text-black-4D mb-1 pb-3 font-Gilroy line-height-21">
                        Correct answer
                      </h5>
                    </div>
                  </div>
                  {questionArray.map((list, index) => (
                    <div className="row" key={index}>
                      <div className="col-9 pb-2 mb-1">
                        <div className="row">
                          <div className="col-12">
                            <div className="d-flex align-items-center">
                              <div className="col-1 px-0">
                                <p className="fs-16 text-grey-65 mb-0">
                                  {index + 1}.
                                </p>
                              </div>
                              <div className="col-9 pr-2 mr-1 pl-0">
                                <NormalInput
                                  type="text"
                                  onChange={(e) => this.handleInput(e, index)}
                                  value={list.optionName}
                                  placeholder="Enter here"
                                  name="optionName"
                                  disabled={list.isDeleted}
                                />
                                {this.validator.message(
                                  `Option-${index + 1}`,
                                  list.optionName,
                                  "required"
                                )}
                              </div>
                              <div className="col-1 px-2 mx-1 w-100 text-center">
                                {index == questionArray.length - 1 ? (
                                  <div
                                    onClick={(e) => this.handleAddQuestions(e)}
                                  >
                                    <i className="icon-plus fs-15 cursor-pointer"></i>
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-1 px-2 mx-1 w-100 text-center">
                                {index != 0 ? (
                                  <div
                                    onClick={(e) =>
                                      this.handleRemoveQuestions(e, index)
                                    }
                                  >
                                    <i className="icon-close fs-13 cursor-pointer"></i>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 pb-2 mb-1 correct-answer-selection text-center">
                        <div className="d-flex align-items-center correct-answer-selection-area justify-content-center">
                          <NormalCheckbox
                            value=""
                            name="isAnswer"
                            checked={list.isAnswer}
                            onChange={(event) =>
                              this.handleToggle(event, index)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="row pt-4 mt-5">
                  <div className="col-12">
                    <NormalTextarea
                      className="form-control modal-question-text"
                      id="comment"
                      value={answerJustification}
                      name="answerJustification"
                      onChange={this.handleInput}
                      resize={false}
                      rows="4"
                      placeholder="Justification"
                      label="Answer justification"
                      required={true}
                    />
                    {this.validator.message(
                      "Answer justification",
                      answerJustification,
                      "required"
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center add-questions-buttons">
                  <div className="mr-auto d-flex flex-column">
                    <div className="d-flex">
                      <NormalButton
                        label="Cancel"
                        outline={true}
                        className="mr-2 border-none"
                        onClick={() => this.handleCloseQuestion()}
                      />
                      {isQuizUpdate ? (
                        <NormalButton
                          label="Add"
                          mainbg={true}
                          className="px-4"
                          onClick={() => this.handleQuestion(true)}
                        />
                      ) : (
                        <NormalButton
                          label="Add"
                          mainbg={true}
                          className="px-4"
                          onClick={() => this.handleQuestion()}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center add-course-buttons">
          <div className="ml-auto d-flex">
            <NormalButton
              label="Discard changes"
              outline={true}
              className="px-4 mr-2 border-none"
              onClick={() => history.push("/admin/courseManagement")}
            />
            {quizId !== "" ? (
              <NormalButton
                label="Update"
                mainbg={true}
                className="px-4 mr-2"
                onClick={this.handleUpdate.bind(this, true)}
              />
            ) : (
              <NormalButton
                label={`${isCourseSaved ? "Publish" : "Save"}`}
                mainbg={true}
                className="px-4 mr-2"
                onClick={() =>
                  `${
                    isCourseSaved ? this.handlePublish() : this.handleSubmit()
                  }`
                }
              />
            )}
            <NormalButton
              label="Preview"
              outline={true}
              className="px-4"
              onClick={() => this.handlePreview()}
            />
            <CoursePreviewPopup
              isOpen={ispreviewPopup}
              toggle={() => this.togglePreview("ispreviewPopup")}
              formFields={courseData}
              isPopupdataLoading={isPopupdataLoading}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateQuiz,
      addQuiz,
      pusblishCourse,
      getCategoryDetail,
      pusblishCourseAfterUpdate,
    },
    dispatch
  );
};

let component = QuizQuestionsClass;

export const QuizQuestions = connect(null, mapDispatchToProps)(component);
