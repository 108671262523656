import React, { Component } from 'react';
import { CustomDate } from 'component/common';
import './index.scss';
export class ComplianceCalender extends Component {
   render() {
      const { date } = this.props;
      return (
         <div className='compliance-calender'>
            <div className='calender-section'>
               <div className='calender-container'>
                  <CustomDate
                     list={date}
                  />
               </div>
            </div>
         </div>
      );
   }
}
