import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFpoBodList, getBodById, downloadCsvforFpoBod } from "action/FPOact";
import { ViewBodPopup } from "./ViewBodPopup";
import { TableStripedWrapper, CommonPageLoader } from "component/common";
import { getDescriptionData } from "service/helperFunctions";
import moment from "moment";

export class BodListClass extends Component {
  state = {
    bodList: [],
    headerDetails: [
      {
        label: "S. No",
      },
      {
        label: "Name",
      },
      {
        label: "Designation",
      },
      {
        label: "DIN",
      },
      {
        label: "Date of Appointment",
      },
      {
        label: "Action",
      },
    ],
    pageMeta: {},
    isLoading: false,
    isBodView: false,
    isPopupDetailLoading: false,
    bodDetail: {},
  };

  //get details
  _getDetails = (page) => {
    let { getFpoBodList, id } = this.props;
    let body = {
      page: page,
    };
    this.setState({ isLoading: true });
    getFpoBodList(id, body).then((data) => {
      this.setState({
        bodList: data.results,
        pageMeta: data.pageMeta,
        isLoading: false,
      });
    });
  };

  toggle = (name, id) => {
    this.setState(
      (prevState) => ({
        [name]: !prevState[name],
      }),
      () => {
        if (id !== undefined) {
          this.setState({ isPopupDetailLoading: true });
          this.props.getBodById({ id }).then((data) => {
            this.setState({
              isPopupDetailLoading: false,
              bodDetail: data,
            });
          });
        }
      }
    );
  };

  //life cycles
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab === "4") {
        this._getDetails(1);
      }
    }
  }

  handlePageChange = (page) => {
    // page change
    this._getDetails(page);
  };

  downloadCsv = () => {
    let { id } = this.props;
    this.props.downloadCsvforFpoBod(id).then((data) => {
      this.downloadFunction(data, "BOD");
    });
  };

  downloadFunction(data, fileName) {
    var a = window.document.createElement("a");
    var blob = new Blob([data], {
      type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(
        blob,
        `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`
      );
    } else {
      a.href = window.URL.createObjectURL(blob);
      // supply your own fileName here...
      a.download = `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  render() {
    let {
      bodList,
      headerDetails,
      pageMeta,
      isLoading,
      isBodView,
      isPopupDetailLoading,
      bodDetail,
    } = this.state;

    return (
      <div className="bod-list">
        <div className="d-flex justify-content-between mb-4">
          <div>
            <h5 className="text-green-18 fs-20 line-height-25 font-weight-bold">
              BOD list
            </h5>
          </div>
          <div>
            <h5
              className="text-green-18 fs-20 line-height-25 font-weight-bold text-underline cursor-pointer"
              onClick={() => this.downloadCsv()}
              id="csv-download"
            >
              Download CSV
            </h5>
          </div>
        </div>

        {isLoading ? (
          <>
            {/* Loading */}
            <CommonPageLoader />
          </>
        ) : (
          <>
            {bodList.length !== 0 ? (
              <TableStripedWrapper
                headerDetails={headerDetails}
                pageMeta={pageMeta}
                queryHandler={this.handlePageChange}
              >
                {bodList.map((item, index) => {
                  let {
                    firstName = "",
                    lastName = "",
                    designation = "",
                    din = "-",
                    AppointedOn = "",
                    bodID = "",
                  } = item;
                  let userName = firstName + " " + lastName;
                  return (
                    <tr key={index}>
                      <td>
                        <div className="px-3">{index + 1}</div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {userName ? getDescriptionData(userName, 20) : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {designation
                            ? getDescriptionData(designation, 25)
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {din ? getDescriptionData(din, 20) : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {AppointedOn
                            ? moment(AppointedOn).format("DD/MM/YYYY")
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          <i
                            className="icon-eye mr-3 cursor-pointer fs-20"
                            onClick={() => this.toggle("isBodView", bodID)}
                          ></i>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </TableStripedWrapper>
            ) : (
              <p className="py-5 my-5 fs-20 text-center text-black-50 line-height-23 fw-700">
                No records found !!!
              </p>
            )}
          </>
        )}
        <ViewBodPopup
          isOpen={isBodView}
          toggle={() => this.toggle("isBodView")}
          isLoading={isPopupDetailLoading}
          bodDetail={bodDetail}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFpoBodList,
      getBodById,
      downloadCsvforFpoBod,
    },
    dispatch
  );
};

let component = BodListClass;

export const BodList = connect(null, mapDispatchToProps)(component);
