import React, { Component } from "react";
import "./allcourse.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  InputSearch,
  NormalButton,
  TableWrapper,
  CommonPageLoader,
} from "component/common";
import { history } from "service/helpers";
import { debounceFunction } from "service/debounce";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import { TrendingVideoList, Course, BlogList, CourseMaster } from "../index";

import {
  getCategoryList,
  getIntroducingVideoList,
  deleteintroducingVideoById,
  courseManagementTab,
} from "action/CourseAct";
import { getDescriptionData } from "service/helperFunctions";

import classnames from "classnames";
export class AllCourseClass extends Component {
  state = {
    activeTab: "1",
    searchValue: "",
    active: false,
    sortActive: false,
    dropdownList: [],
    categoryName: "",
    sortName: "",
    filterType: "",
    filter: "",
    sortBy: "",
    difficultlyLevelOptions: [
      { label: "Beginner" },
      { label: "Intermediate" },
      { label: "Advanced" },
    ],
    sortoptions: [{ label: "A to Z" }, { label: "Z to A" }],
    headerDetails: [
      {
        label: "Video id",
      },
      {
        label: "Video title",
      },
      {
        label: "File name",
      },
      {
        label: "Action",
      },
    ],
    introducingVideoList: [],
    isloading: false,
  };

  toggle = (tab) => {
    let { activeTab } = this.state;
    this.setState({
      active: false,
      sortActive: false,
    });
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        categoryName: "",
        sortName: "",
        filterType: "",
        filter: "",
        sortBy: "",
        searchValue: "",
      });
      if (tab === "4") {
        this._getintorducingVideoList();
      }
    }
  };

  //get inroducing videos
  _getintorducingVideoList = () => {
    let { getIntroducingVideoList } = this.props;
    this.setState({ isloading: true });
    getIntroducingVideoList().then((data) => {
      this.setState({
        introducingVideoList: data !== null ? data : [],
        isloading: false,
      });
    });
  };

  //handle Delete
  handleDelete = (id) => {
    this.props.deleteintroducingVideoById(id).then(() => {
      this._getintorducingVideoList();
    });
  };

  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  handleOutsideClick = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  handleSortDropdown = () => {
    if (!this.state.sortActive) {
      document.addEventListener("click", this.handleOutsideClickForsort, false);
    } else {
      document.removeEventListener(
        "click",
        this.handleOutsideClickForsort,
        false
      );
    }
    this.setState((prevState) => ({
      sortActive: !prevState.sortActive,
    }));
  };

  handleOutsideClickForsort = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleSortDropdown();
  };

  componentDidMount() {
    let { getCategoryList, courseManagementActiveTab } = this.props;
    this.setState({ activeTab: courseManagementActiveTab.toString() });
    getCategoryList().then((data) => {
      this.setState({ dropdownList: data });
    });
  }

  handleInput = ({ target: { name, value } }) => {
    //Debounce Function
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    this.setState({ searchValue });
  };

  handleSubmitforSort = (sortvalue) => {
    this.setState({
      sortName: sortvalue,
    });

    if (sortvalue === "A to Z") {
      this.setState({ sortBy: "ASC" });
    } else {
      this.setState({ sortBy: "DESC" });
    }
  };

  handleSubmit = (filterType, filter, categoryName) => {
    if (filterType === "categoryId") {
      this.setState({ categoryName, filterType, filter });
    } else if (filterType === "difficultyLevel") {
      this.setState({ categoryName: filter, filterType, filter });
    }
  };

  handleRemoveFilter = () => {
    this.setState({ categoryName: "", filterType: "", filter: "" });
  };

  handleRemoveSort = () => {
    this.setState({ sortBy: "", sortName: "" });
  };

  render() {
    let {
      activeTab,
      searchValue,
      dropdownList,
      categoryName = "",
      active,
      difficultlyLevelOptions,
      filterType,
      filter,
      sortName,
      sortActive,
      sortoptions,
      sortBy,
      headerDetails,
      introducingVideoList,
      isloading,
    } = this.state;

    return (
      <>
        <div className="course-management">
          <div className="d-flex justify-content-between align-items-center course-management-heading">
            <div className="">
              <h5 className="heading-20">Academy management</h5>
            </div>
            <div className="">
              {activeTab == 1 ? (
                <NormalButton
                  label="Add new course"
                  mainbg={true}
                  onClick={() => {
                    {
                      this.props.courseManagementTab(1);
                      history.push("addCourse/courseDetail");
                    }
                  }}
                />
              ) : activeTab == 2 ? (
                <NormalButton
                  label="Add new video"
                  mainbg={true}
                  onClick={() => {
                    history.push("addCourse/addVideo");
                  }}
                />
              ) : activeTab == 3 ? (
                <NormalButton
                  label="Add new blog"
                  mainbg={true}
                  onClick={() => {
                    history.push("addCourse/addBlog");
                  }}
                />
              ) : activeTab == 4 && introducingVideoList.length < 1 ? (
                <NormalButton
                  label="Add introduction video"
                  mainbg={true}
                  onClick={() => {
                    history.push("addCourse/infomativevideo");
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className="common-navlink">
            <div className="filled-tabs">
              <div className="tabs-block">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      Courses
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      Informative videos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        this.toggle("3");
                      }}
                    >
                      Blogs
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        this.toggle("4");
                      }}
                    >
                      Introduction video
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "5" })}
                      onClick={() => {
                        this.toggle("5");
                      }}
                    >
                      Masters
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              {activeTab !== "4" && activeTab !== "5" ? (
                <div className="d-flex justify-content-end align-items-center mt-3 search-area">
                  <ul className="menu mb-0 mr-4">
                    <li
                      className="cursor-pointer filter-dropdown"
                      onClick={this.handleDropdown}
                    >
                      {categoryName == "" ? "Filter" : categoryName}
                      <i className="icon-down fs-12 ml-2"></i>
                      <div
                        className={`dropdown ${
                          active && !sortActive ? "active" : ""
                        }`}
                      >
                        <ul className="h-100 w-100 d-flex flex-column flex-wrap">
                          <p>Category</p>
                          {categoryName !== "" ? (
                            <li
                              className="cursor-pointer"
                              onClick={() => {
                                this.handleRemoveFilter();
                              }}
                            >
                              {"All"}
                            </li>
                          ) : (
                            ""
                          )}
                          {dropdownList.map((list, index) =>
                            categoryName === list.categoryName ? (
                              <li className="cursor-pointer active" key={index}>
                                {list.categoryName}
                              </li>
                            ) : (
                              <li
                                className="cursor-pointer"
                                key={index}
                                onClick={() => {
                                  this.handleSubmit(
                                    "categoryId",
                                    list.courseCategoryId,
                                    list.categoryName
                                  );
                                }}
                              >
                                {list.categoryName}
                              </li>
                            )
                          )}
                          {activeTab === "1" ? (
                            <>
                              <p>Difficulty level</p>
                              {difficultlyLevelOptions.map((list, index) =>
                                categoryName === list.label ? (
                                  <li
                                    className="cursor-pointer active"
                                    key={index}
                                  >
                                    {list.label}
                                  </li>
                                ) : (
                                  <li
                                    className="cursor-pointer"
                                    key={index}
                                    onClick={() => {
                                      this.handleSubmit(
                                        "difficultyLevel",
                                        list.label
                                      );
                                    }}
                                  >
                                    {list.label}
                                  </li>
                                )
                              )}
                            </>
                          ) : null}
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <ul className="menu mb-0 mr-4">
                    <li
                      className="cursor-pointer sort-dropdown"
                      onClick={this.handleSortDropdown}
                    >
                      {sortName == "" ? "Sort" : sortName}
                      <i className="icon-down fs-12 ml-2"></i>
                      <div
                        className={`dropdown ${
                          sortActive && !active ? "sortActive" : ""
                        }`}
                      >
                        <ul className="h-100 w-100 d-flex flex-column flex-wrap">
                          {sortName !== "" ? (
                            <li
                              className="cursor-pointer"
                              onClick={() => {
                                this.handleRemoveSort();
                              }}
                            >
                              {"All"}
                            </li>
                          ) : (
                            ""
                          )}
                          {sortoptions.map((list, index) =>
                            sortName === list.label ? (
                              <li className="cursor-pointer active" key={index}>
                                {list.label}
                              </li>
                            ) : (
                              <li
                                className="cursor-pointer"
                                key={index}
                                onClick={() => {
                                  this.handleSubmitforSort(list.label);
                                }}
                              >
                                {list.label}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <InputSearch
                    className={"mr-4"}
                    placeholder={"Search"}
                    onChange={(e) => this.handleInput(e)}
                  />
                </div>
              ) : null}
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {activeTab === "1" ? (
                    <Course
                      history={this.props.history}
                      searchValue={searchValue}
                      filterType={filterType}
                      filter={filter}
                      sortBy={sortBy}
                      activeTab={activeTab}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="2">
                  {activeTab === "2" ? (
                    <TrendingVideoList
                      history={this.props.history}
                      searchValue={searchValue}
                      filterType={filterType}
                      filter={filter}
                      sortBy={sortBy}
                      activeTab={activeTab}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="3">
                  {activeTab === "3" ? (
                    <BlogList
                      history={this.props.history}
                      searchValue={searchValue}
                      filterType={filterType}
                      filter={filter}
                      sortBy={sortBy}
                      activeTab={activeTab}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="4">
                  {isloading ? (
                    <>
                      {/* Loader */}
                      <CommonPageLoader />
                    </>
                  ) : (
                    <div className="table-container w-100 mt-4 pt-1">
                      {introducingVideoList.length != 0 ? (
                        <TableWrapper headerDetails={headerDetails}>
                          {introducingVideoList.map((item, index) => {
                            let { title, introVideoId, fileName } = item;

                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center h-100">
                                      {introVideoId}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center h-100">
                                      {getDescriptionData(title, 70)}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center h-100">
                                      {getDescriptionData(fileName, 70)}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center h-100">
                                      <i
                                        className="icon-edit fs-20 cursor-pointer"
                                        onClick={() => {
                                          this.props.history.push({
                                            pathname: `addCourse/infomativevideo/${introVideoId}`,
                                          });
                                        }}
                                      ></i>
                                      <i
                                        className="icon-Delete fs-20 pl-3 cursor-pointer"
                                        onClick={() => {
                                          this.handleDelete(introVideoId);
                                        }}
                                      ></i>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="trans-bg">
                                  <td className="empty-height" height="12"></td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        </TableWrapper>
                      ) : (
                        <div className="text-center fs-18 text-black py-5">
                          No records found !!!
                        </div>
                      )}
                    </div>
                  )}
                </TabPane>
                <TabPane tabId="5">
                  {activeTab === "5" ? (
                    <CourseMaster history={this.props.history} />
                  ) : null}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  courseManagementActiveTab: state.courseManagement.tab,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCategoryList,
      getIntroducingVideoList,
      deleteintroducingVideoById,
      courseManagementTab,
    },
    dispatch
  );
};

let component = AllCourseClass;

export const AllCourse = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
