import React, { Component } from "react";
import { FaqList } from "component/Faq/index";
import { connect } from "react-redux";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";
class FaqClass extends Component {
  componentDidMount() {
    let status = getSubAdmin();
    if (status) {
      logout();
    }
  }
  render() {
    return <FaqList history={this.props.history} />;
  }
}

export const Faq = connect(null, null)(FaqClass);
