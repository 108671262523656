import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { TableWrapper, NormalButton } from "component/common";
import {
  getRequestFormList,
  requestFormRejectUser,
  requestFormAcceptUser,
  getDashboardDetailsforuser,
} from "action/dashboard";
import { NormalSelect } from "component/common";
import "./requestFormList.scss";
import { getSubAdmin } from "service/helperFunctions";
import { logout } from "service/utilities";

export class NewFpoClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerDetails: [
        {
          label: "Date",
        },
        {
          label: "Time stamp",
        },
        {
          label: "User name",
        },
        // {
        //   label: "Role",
        // },
        {
          label: "FPO Name",
        },
        {
          label: "Email id",
        },
        {
          label: "Mobile number",
        },
        // {
        //   label: "User Type",
        // },
        {
          label: "Action",
        },
      ],
      pageMeta: {},
      courseManagement: [],
      isShowDetalModal: false,
      userDetails: {},
      isTableTrue: false,
      newData: [],
      usertype: [],
      selectedType: [{ selectedType: "" }],
      selectedIndex: null,
    };
  }

  handleRequestFormList = (page) => {
    this.props
      .getRequestFormList({ limit: 10, page: page.page })
      .then(({ data: { results, total, pageMeta } }) => {
        this.setState({ courseManagement: results, pageMeta });
      });
  };

  componentDidMount() {
    // let status = getSubAdmin();
    // if (status) {
    //   logout();
    // }
    this.setState({ isloading: true });
    this.handleRequestFormList({ page: 1 });
    this.props.getDashboardDetailsforuser().then(({ data }) => {
      this.setState({
        userDetails: data,
        isLoading: false,
      });
    });
    let sampleData = [
      {
        userName: "Data1",
        userRoles: {
          userRoleName: "Data1",
        },
        emailId: "Data1",
        userMasterId: "Data1",
        mobileNumber: "Data1",
        fpousers: "Data1",
        createdAt: "Data1",
      },
      {
        userName: "Data2",
        userRoles: {
          userRoleName: "Data2",
        },
        emailId: "Data2",
        userMasterId: "Data2",
        mobileNumber: "Data2",
        fpousers: "Data2",
        createdAt: "Data2",
      },
      {
        userName: "Data3",
        userRoles: {
          userRoleName: "Data3",
        },
        emailId: "Data3",
        userMasterId: "Data3",
        mobileNumber: "Data3",
        fpousers: "Data3",
        createdAt: "Data3",
      },
    ];
    let sampleType = [
      {
        label: "RM User",
        value: "rmUser",
      },
      {
        label: "KYM User",
        value: "kymUser",
      },
    ];
    this.setState({
      newData: sampleData,
      usertype: sampleType,
    });
  }

  handleRejectUser = (i, type) => {
    let { courseManagement } = this.state;
    let {
      userMasterId,
      userRoles: {
        userGroup: { userGroupName },
      },
    } = courseManagement[i];
    let body = {
      userMasterId,
      userGroupName,
    };
    let apiCall =
      type === "accept"
        ? this.props.requestFormAcceptUser(body)
        : this.props.requestFormRejectUser(body);
    apiCall.then(() => {
      this.handleRequestFormList({ page: 1 });
    });
  };

  handleChange = (e, index) => {
    console.log("index", index);
    let { name, value } = e.target;
    let { selectedType } = this.state;
    let list = [...selectedType];
    list[index][name] = value;
    this.setState({
      list,
    });
  };

  render() {
    let {
      headerDetails,
      courseManagement,
      pageMeta,
      isTableTrue,
      newData,
      usertype,
      selectedType,
      selectedIndex,
    } = this.state;
    let {
      externaluser = 0,
      fpoUser = 0,
      individualUser = 0,
      sammunatiUser = 0,
    } = this.state.userDetails;

    return (
      <>
        {!isTableTrue ? (
          <div className="card requestForms  mt-5">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h5 className="card-title fs-24 font-Gilroy-Bold line-height-28 text-green-18">
                  Request forms
                </h5>
                <h6
                  className="card-title fs-16 font-Gilroy-Bold line-height-28 text-green-18 cursor-pointer"
                  onClick={() => this.setState({ isTableTrue: true })}
                >
                  View
                </h6>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  FPO <span className="float-right">{fpoUser}</span>
                </li>
                <li className="list-group-item">
                  Samunnati <span className="float-right">{sammunatiUser}</span>
                </li>
                {/* <li className="list-group-item">
                  External organization{" "}
                  <span className="float-right">{externaluser}</span>
                </li>
                <li className="list-group-item">
                  Individual{" "}
                  <span className="float-right">{individualUser}</span>
                </li> */}
              </ul>
            </div>
          </div>
        ) : (
          <div className="requestFormList mt-5">
            <div className="d-flex justify-content-end mb-2">
              <NormalButton
                label="Request forms"
                mainbg={true}
                onClick={() => this.setState({ isTableTrue: false })}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-containermt-4 pt-1">
                  <TableWrapper
                    headerDetails={headerDetails}
                    queryHandler={this.handleRequestFormList}
                    pageMeta={pageMeta}
                  >
                    {courseManagement.map(
                      (
                        {
                          userName,
                          userRoles: { userRoleName },
                          emailId,
                          userMasterId,
                          mobileNumber,
                          fpousers,
                          createdAt,
                        },
                        index
                      ) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {moment(createdAt)
                                  .tz("Asia/Kolkata")
                                  .format("DD-MM-YYYY")}
                              </td>
                              <td align="center">
                                {moment(createdAt)
                                  .tz("Asia/Kolkata")
                                  .format("hh:mm a")}
                              </td>
                              <td>{userName}</td>
                              {/* <td>{userRoleName}</td> */}
                              <td align="center">
                                {fpousers ? fpousers.fpoName : "-"}
                              </td>
                              <td>{emailId}</td>
                              <td>{mobileNumber}</td>
                              {/* <td>
                                <NormalSelect
                                  placeholder="Select"
                                  value={selectedType?.selectedType?.[index]}
                                  options={usertype}
                                  name="selectedType"
                                  handleChange={(e) =>
                                    this.handleChange(e, index)
                                  }
                                />
                              </td> */}
                              <td>
                                <div className="d-flex align-items-center h-100">
                                  <span
                                    className=" mr-3 cursor-pointer approve-text"
                                    onClick={() =>
                                      this.handleRejectUser(index, "accept")
                                    }
                                  >
                                    Approve
                                  </span>
                                  <span
                                    className=" mr-3 cursor-pointer approve-text text-danger"
                                    onClick={() =>
                                      this.handleRejectUser(index, "reject")
                                    }
                                  >
                                    Reject
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr className="trans-bg">
                              <td className="empty-height" height="12"></td>
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )}
                  </TableWrapper>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRequestFormList,
      requestFormRejectUser,
      requestFormAcceptUser,
      getDashboardDetailsforuser,
    },
    dispatch
  );
};

export const NewFpo = connect(null, mapDispatchToProps)(NewFpoClass);
