import { roleManagement } from "service/apiVariables";

import { addQuery } from "service/helperFunctions";

// Get list of role management
export const roleManagementList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, roleManagement.roleManagementList);

    return new Promise((resolve, reject) => {
      api({ ...roleManagement.roleManagementList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get User Roles List
export const getUserRolesList =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...roleManagement.getUserRolesList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Fpo List For Mapping
export const getFpoListForMapping =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, roleManagement.getFpoListForMapping);

    return new Promise((resolve, reject) => {
      api({ ...roleManagement.getFpoListForMapping })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//fpo Mapping To Rm
export const fpoMappingToRm =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...roleManagement.fpoMappingToRm, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Fpo management For Fpo
export const getFpomanagementForFpo =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getFpomanagementForFpo } = roleManagement;
    getFpomanagementForFpo.id = id;
    return new Promise((resolve, reject) => {
      api({ ...roleManagement.getFpomanagementForFpo })
        .then(({ data, message }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//update Role For FPO
export const updateRoleForFPO =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...roleManagement.updateRoleForFPO, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getRMList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, roleManagement.getRMList);
    return new Promise((resolve, reject) => {
      api({ ...roleManagement.getRMList })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const rmFPOMapping =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, roleManagement.rmFPOMapping);
    return new Promise((resolve, reject) => {
      api({ ...roleManagement.rmFPOMapping })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getMappedFPO =
  (id, query) =>
  (dispatch, getState, { api, Toast }) => {
    roleManagement.getMappedFPO.id = id;
    addQuery(query, roleManagement.getMappedFPO);
    return new Promise((resolve, reject) => {
      api({ ...roleManagement.getMappedFPO })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const deleteMappedFPO =
  (rmId, fpoId, query) =>
  (dispatch, getState, { api, Toast }) => {
    roleManagement.deleteMappedFPO.rmId = rmId;
    roleManagement.deleteMappedFPO.fpoId = fpoId;
    addQuery(query, roleManagement.deleteMappedFPO);
    return new Promise((resolve, reject) => {
      api({ ...roleManagement.deleteMappedFPO })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
