import { authType } from "../service/actionType";

import { courseManagementApi } from "../service/apiVariables";

import { addQuery } from "../service/helperFunctions";

import { courseManagementType } from "service/actionType";

//Get Category List

export const getCategoryList =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.getCategoryList, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Get Category Detail

export const getCategoryDetail =
  (coursId) =>
  (dispatch, getState, { api, Toast }) => {
    let { getCategoryDetail } = courseManagementApi;

    getCategoryDetail.courseId = coursId;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.getCategoryDetail })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
          reject(true);
        });
    });
  };

//Create Course

export const createCourse =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.createCourse, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Update Course

export const updateCourse =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.updateCourse, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Add Sections
export const addSections =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.addSections, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Update Sections
export const updateSections =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.updateSections, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Add Practices
export const addPractices =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.addPractices, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Update Practices
export const updatePractices =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.updatePractices, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Add Quiz
export const addQuiz =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.addQuiz, body })
        .then(({ data }) => {
          Toast({
            type: "success",
            message: "Course saved successfully",
            time: 5000,
          });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//pusblish Course
export const pusblishCourse =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.pusblishCourse, body })
        .then(({ data }) => {
          Toast({
            type: "success",
            message: "Course Created Successfully",
            time: 5000,
          });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//pusblish Course
export const pusblishCourseAfterUpdate =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.pusblishCourse, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Update Quiz
export const updateQuiz =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.updateQuiz, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Get CourseList
export const getCourseList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, courseManagementApi.getCourseList);

      api({ ...courseManagementApi.getCourseList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//getPublishedCourseList
export const getPublishedCourseList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, courseManagementApi.getPublishedCourseList);

      api({ ...courseManagementApi.getPublishedCourseList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Add Trending Video

export const addTrendingVideo =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.addTrendingVideo, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Get Trending video Detail

export const getVideoDetail =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    let { getVideoDetail } = courseManagementApi;

    getVideoDetail.videoId = body.videoId;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.getVideoDetail, body })
        .then(({ data, message }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
          reject(true);
        });
    });
  };

//Update Trending Video

export const updateVideo =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.updateVideo, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Add Blog

export const addBlog =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.addBlog, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Get Course List for Dropdown

export const getCourseListForDropdown =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.getCourseListForDropdown, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Get Blog Detail

export const getBlogDetail =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    let { getBlogDetail } = courseManagementApi;

    getBlogDetail.blogId = body.blogId;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.getBlogDetail, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
          reject(true);
        });
    });
  };

//Update Blog

export const updateBlog =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.updateBlog, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Get CourseList
export const getTrendingVideoList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, courseManagementApi.getTrendingVideoList);

      api({ ...courseManagementApi.getTrendingVideoList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get blog list
export const getBlogList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, courseManagementApi.getBlogList);

      api({ ...courseManagementApi.getBlogList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//getIntroducingVideoList
export const getIntroducingVideoList =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.getIntroducingVideoList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//File Upload
export const fileUpload =
  (body, configObj) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.fileUpload, body, configObj })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          resolve(message);

          Toast({ type: "error", message });
        });
    });
  };

//File Upload
export const multipleImageUpload =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.multipleImageUpload, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          resolve(message);

          Toast({ type: "error", message });
        });
    });
  };

//delete course
export const deleteCourseById =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    let { deleteCourseById } = courseManagementApi;

    deleteCourseById.courseId = body.courseId;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.deleteCourseById, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//delete video
export const deleteVideosById =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    let { deleteVideosById } = courseManagementApi;

    deleteVideosById.videoId = body.videoId;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.deleteVideosById, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//delete video
export const deleteblogById =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    let { deleteblogById } = courseManagementApi;

    deleteblogById.blogId = body.blogId;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.deleteblogById, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Author List
export const getAuthorList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, courseManagementApi.getAuthorList);

      api({ ...courseManagementApi.getAuthorList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//add Author
export const addAuthor =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.addAuthor, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//deleteAuthorById
export const deleteAuthorById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { deleteAuthorById } = courseManagementApi;

    deleteAuthorById.id = id;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.deleteAuthorById })
        .then(({ data, message }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

// get Author By Id
export const getAuthorById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getAuthorById } = courseManagementApi;

    getAuthorById.id = id;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.getAuthorById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//updateAuthor
export const updateAuthor =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.updateAuthor, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get certifying institute List
export const getCertifyingInstituteList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, courseManagementApi.getCertifyingInstituteList);

      api({ ...courseManagementApi.getCertifyingInstituteList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//add certifying institute
export const addCertifyingInstitute =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.addCertifyingInstitute, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//updateCertifyingInstitute
export const updateCertifyingInstitute =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.updateCertifyingInstitute, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//delete certifying institute By Id
export const deleteCertifyingInstituteById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { deleteCertifyingInstituteById } = courseManagementApi;

    deleteCertifyingInstituteById.id = id;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.deleteCertifyingInstituteById })
        .then(({ data, message }) => {
          Toast({ type: "success", message });

          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Institute By Id
export const getInstituteById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getInstituteById } = courseManagementApi;

    getInstituteById.id = id;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.getInstituteById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Certifying Institute Dropdown
export const getCertifyingInstituteDropdown =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getCertifyingInstituteDropdown } = courseManagementApi;

    getCertifyingInstituteDropdown.id = id;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.getCertifyingInstituteDropdown })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Author Dropdown
export const getAuthorDropdown =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getAuthorDropdown } = courseManagementApi;

    getAuthorDropdown.id = id;

    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.getAuthorDropdown })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//create Introduction Video

export const createIntroductionVideo =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.createIntroductionVideo, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//introduction Video By Id
export const introductionVideoById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { introductionVideoById } = courseManagementApi;
    introductionVideoById.id = id;
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.introductionVideoById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//update Introduction Video
export const updateIntroductionVideo =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.updateIntroductionVideo, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//deleteintroducingVideoById
export const deleteintroducingVideoById =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { deleteintroducingVideoById } = courseManagementApi;
    deleteintroducingVideoById.id = id;
    return new Promise((resolve, reject) => {
      api({ ...courseManagementApi.deleteintroducingVideoById })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const courseManagementTab = (payload) => (dispatch) => {
  dispatch({ type: courseManagementType.courseManagementTab, payload });
};
