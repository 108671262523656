import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import tickIcon from "assets/svg/dashboard/dashboard-tickIcon.svg";
import closeIcon from "assets/svg/dashboard/dashboard-closeIcon.svg";
import {
  getDashboardBusinessHomeDetails,
  getDashboardBusinessMemberDetails,
  getDashboardBusinessFIGDetails,
  getDashboardBusinessKYMDetails,
  getDashboardBusinessBMSDetails,
  getDashboardBusinessDetails,
  getDashboardBusinessStateHeadDetails,
  getgetRMListDetails,
  getDownloadBusinessHomeData,
  getDownloadBusinessMembersData,
  getDownloadBusinessFIGData,
  getDownloadBusinessKYMData,
  getDownloadBusinessBMSData,
} from "action/dashboard";
import { allState, getDistrictApiwithState } from "action/FPOact";
import classnames from "classnames";
import { debounceFunction } from "service/debounce";
import { numberFormatChange } from "service/helperFunctions";
import {
  NormalSelect,
  NormalDate,
  InputSearch,
  TableWrapper,
  CommonPageLoader,
  NormalButton,
} from "component/common";
import { MultiSelect } from "react-multi-select-component";

const programLists = [
  { label: "LHFPO", value: "LHFPO" },
  { label: "BMGF", value: "BMGF" },
  { label: "FPOnEXT", value: "FPOnEXT" },
  { label: "FPO 360", value: "FPO_360" },
  { label: "FPO Bazaar", value: "FPO_Bazaar" },
];

const programListsOne = [
  { label: "LHFPO", value: "LHFPO" },
  { label: "BMGF", value: "BMGF" },
  { label: "FPOnEXT", value: "FPOnEXT" },
  { label: "FPO 360", value: "FPO_360" },
];

const homeTabHeader = [
  { label: "FPO ID" },
  { label: "FPO Name" },
  { label: "Grading" },
  { label: "Engagement" },
  { label: "Member%" },
  { label: "FIG%" },
  { label: "KYM%" },
  { label: "BMS" },
];

const memberTabHeader = [
  { label: "FPO ID" },
  { label: "FPO Name" },
  { label: "Membership Base" },
  { label: "Members Uploaded" },
  { label: "Members with name/mobile/village/farm" },
  { label: "Member%" },
];

const figTabHeader = [
  { label: "FPO ID" },
  { label: "FPO Name" },
  { label: "Membership Base" },
  { label: "FIGs Uploaded" },
  { label: "Members mapped" },
  { label: "FIG%" },
];

const kymTabHeader = [
  { label: "FPO ID" },
  { label: "FPO Name" },
  { label: "Membership Base" },
  { label: "KYMs Done" },
  { label: "Uploaded Fields" },
  { label: "Required Fields" },
  { label: "KYM%" },
  { label: "KYMs yet to be started" },
];

const bmsTabHeader = [
  { label: "FPO ID" },
  { label: "FPO Name" },
  { label: "Masters Uploaded" },
  { label: "Purchases" },
  { label: "Sales" },
  { label: "Other transactions" },
];

const Titles = [
  { title: "Home" },
  { title: "Members" },
  { title: "FIG" },
  { title: "KYM" },
  { title: "BMS" },
];

const DashboardBusinessClass = (props) => {
  const {
    allStateApi,
    getDashboardBusinessHomeDetailsApi,
    getDashboardBusinessMemberDetailsApi,
    getDashboardBusinessFIGDetailsApi,
    getDashboardBusinessKYMDetailsApi,
    getDashboardBusinessBMSDetailsApi,
    getDashboardBusinessDetailsApi,
    getDashboardBusinessStateHeadDetailsApi,
    getgetRMListDetailsApi,
    homeActiveTab,
    getDownloadBusinessHomeDataApi,
    getDownloadBusinessMembersDataApi,
    getDownloadBusinessFIGDataApi,
    getDownloadBusinessKYMDataApi,
    getDownloadBusinessBMSDataApi,
  } = props;
  const [activeTab, setActiveTab] = useState("Home");
  const [homeTabPageMeta, setHomeTabPageMeta] = useState({});
  const [memberTabPageMeta, setMemberTabPageMeta] = useState({});
  const [figTabPageMeta, setFIGTabPageMeta] = useState({});
  const [kymTabPageMeta, setKYMTabPageMeta] = useState({});
  const [bmsTabPageMeta, setBMSTabPageMeta] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [stateHeadOptions, setStateHeadOptions] = useState([]);
  const [rmNameOptions, setRMNameOptions] = useState([]);
  const [homeData, setHomeData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [figData, setFIGData] = useState([]);
  const [kymData, setKYMData] = useState([]);
  const [bmsData, setBMSData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [loading, setloading] = useState(false);
  const [kymLoading, setKymLoading] = useState(false);
  const [stateID, setStateID] = useState("");
  let [selectedStateHead, setSelectedStateHead] = useState("");
  const [loggedInID, setloggedInID] = useState("");
  const [selected, setSelected] = useState([]);
  const [sortingData, setSortingData] = useState({
    program: "",
    state: "",
    stateHead: "",
    rmName: "",
    startDate: "",
    endDate: "",
    search: "",
  });

  useEffect(() => {
    let id = localStorage.getItem("userRoleId");
    setloggedInID(id);
  }, []);

  const stateApiCall = useCallback(() => {
    allStateApi().then((data) => {
      let stateData = [{ label: "All", value: "All" }];
      data.forEach((list) => {
        stateData.push({
          label: list.stateName,
          value: list.stateId,
        });
        setStateOptions([...stateData]);
      });
    });
  }, [allStateApi]);

  useEffect(() => {
    if (homeActiveTab === "Business") {
      stateApiCall();
    }
  }, [stateApiCall, homeActiveTab]);

  const getStateHead = useCallback(
    (value) => {
      if (value === "All") {
        value = "";
      }
      let body = {
        stateId: value === "" || value === undefined ? "" : value,
      };
      getDashboardBusinessStateHeadDetailsApi(body).then((data) => {
        let stateHeadData = data.data.map((list) => ({
          label: list.stateHead,
          value: list.stateHead,
        }));
        setStateHeadOptions([{ label: "All", value: "All" }, ...stateHeadData]);
      });
    },
    [getDashboardBusinessStateHeadDetailsApi]
  );

  useEffect(() => {
    if (homeActiveTab === "Business" && loggedInID !== "13") {
      getStateHead();
    }
  }, [getStateHead, homeActiveTab, loggedInID]);

  const getRMListMethod = useCallback(
    (value) => {
      if (value === "All") {
        value = "";
      }
      if (selectedStateHead === "All" && sortingData?.state === "") {
        selectedStateHead = "";
      } else if (selectedStateHead === "All" && sortingData?.state !== "") {
        value = value || stateID;
      } else if (sortingData?.state !== "" && selectedStateHead !== "") {
        value = value || stateID;
        selectedStateHead = "";
      }
      let body = {
        stateId: value === "" || value === undefined ? "" : value,
        stateHeadName: selectedStateHead !== "" ? selectedStateHead : "",
      };
      if (selectedStateHead === "All") {
        body.stateHeadName = "";
      }
      getgetRMListDetailsApi(body).then((data) => {
        let rmData = data.data.map((list) => ({
          label: list.rmName,
          value: list.rmName,
        }));
        setRMNameOptions([{ label: "All", value: "All" }, ...rmData]);
      });
    },
    [getgetRMListDetailsApi, selectedStateHead, stateID]
  );

  useEffect(() => {
    if (homeActiveTab === "Business") {
      getRMListMethod();
    }
  }, [getRMListMethod, homeActiveTab]);

  const handleInput = (e) => {
    const { name, value } = e.target || e || {};
    if (name === "state") {
      if (loggedInID !== "13") {
        sortingData.rmName = "";
        setSortingData(
          { ...sortingData, [name]: value },
          getStateHead(value),
          getRMListMethod(value)
        );
        for (let i = 0; i < stateOptions?.length; i++) {
          if (value === stateOptions[i].value.toString()) {
            setStateID(stateOptions[i].value);
            getRMListMethod(stateOptions[i].value);
          }
        }
      }
    }
    if (name === "stateHead") {
      if (value === "All") {
        sortingData.rmName = "All";
      }
      setSelectedStateHead(value);
    }
    if (name === "search") {
      setSortingData({ ...sortingData, [name]: value });
      debounceFunction(() => homeApiCall, memberApiCall, figApiCall, 1000);
    }
    setSortingData({ ...sortingData, [name]: value });
  };

  useEffect(() => {
    if (
      sortingData?.startDate === null ||
      sortingData?.startDate === "" ||
      sortingData?.startDate === undefined
    ) {
      sortingData.startDate = "";
      sortingData.endDate = "";
    }
  }, [sortingData.startDate, sortingData.endDate]);

  useEffect(() => {
    if (sortingData?.endDate === null) {
      sortingData.endDate = "";
    }
  }, [sortingData.endDate]);

  const homeApiCall = useCallback(
    (page, value) => {
      setloading(true);
      let body = {
        program:
          loggedInID === "13"
            ? JSON.stringify(["FPO_Bazaar"])
            : sortingData?.program !== ""
            ? sortingData?.program
            : "",
        state: sortingData?.state !== "" ? sortingData?.state : "",
        stateHead: sortingData?.stateHead !== "" ? sortingData?.stateHead : "",
        rmName: sortingData?.rmName !== "" ? sortingData?.rmName : "",
        startDate:
          sortingData?.startDate !== ""
            ? moment(sortingData?.startDate).format("YYYY-MM-DD")
            : "",
        endDate:
          sortingData?.endDate !== ""
            ? moment(sortingData?.endDate).format("YYYY-MM-DD")
            : "",
        page: page,
        search: sortingData?.search !== "" ? sortingData?.search : "",
      };
      if (sortingData?.program === "All") {
        body.program = "";
      }
      if (sortingData?.state === "All") {
        body.state = "";
        body.stateHead = "";
        body.rmName = "";
      }
      if (sortingData?.stateHead === "All") {
        if (sortingData?.state === "") {
          body.stateHead = "";
          body.rmName = "";
        } else if (sortingData?.state !== "") {
          body.stateHead = "";
        }
      }
      if (sortingData?.rmName === "All") {
        body.rmName = "";
      }
      if (loggedInID !== "13") {
        if (sortingData?.program?.length > 0) {
          body.program = JSON.stringify(body.program);
        } else {
          body.program = "";
        }
      }
      getDashboardBusinessHomeDetailsApi(body)
        .then((data) => {
          setHomeData(data?.data?.results);
          setHomeTabPageMeta(data?.data?.pageMeta);
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    },
    [getDashboardBusinessHomeDetailsApi, sortingData, loggedInID]
  );

  useEffect(() => {
    if (homeActiveTab === "Business" && activeTab === "Home") {
      homeApiCall(1);
    }
  }, [homeApiCall, homeActiveTab, activeTab]);

  const memberApiCall = useCallback(
    (page, value) => {
      setloading(true);
      let body = {
        program:
          loggedInID === "13"
            ? JSON.stringify(["FPO_Bazaar"])
            : sortingData?.program !== ""
            ? sortingData?.program
            : "",
        state: sortingData?.state !== "" ? sortingData?.state : "",
        stateHead: sortingData?.stateHead !== "" ? sortingData?.stateHead : "",
        rmName: sortingData?.rmName !== "" ? sortingData?.rmName : "",
        startDate:
          sortingData?.startDate !== ""
            ? moment(sortingData?.startDate).format("YYYY-MM-DD")
            : "",
        endDate:
          sortingData?.endDate !== ""
            ? moment(sortingData?.endDate).format("YYYY-MM-DD")
            : "",
        page: page,
        search: sortingData?.search !== "" ? sortingData?.search : "",
      };
      if (sortingData?.program === "All") {
        body.program = "";
      }
      if (sortingData?.state === "All") {
        body.state = "";
        body.stateHead = "";
        body.rmName = "";
      }
      if (sortingData?.stateHead === "All") {
        if (sortingData?.state === "") {
          body.stateHead = "";
          body.rmName = "";
        } else if (sortingData?.state !== "") {
          body.stateHead = "";
        }
      }
      if (sortingData?.rmName === "All") {
        body.rmName = "";
      }
      if (loggedInID !== "13") {
        if (sortingData?.program?.length > 0) {
          body.program = JSON.stringify(body.program);
        } else {
          body.program = "";
        }
      }
      getDashboardBusinessMemberDetailsApi(body)
        .then((data) => {
          setMemberData(data?.data?.results);
          setMemberTabPageMeta(data?.data?.pageMeta);
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    },
    [getDashboardBusinessMemberDetailsApi, sortingData, loggedInID]
  );

  useEffect(() => {
    if (activeTab === "Members") {
      memberApiCall(1);
    }
  }, [memberApiCall, activeTab]);

  const figApiCall = useCallback(
    (page, value) => {
      setloading(true);
      let body = {
        program:
          loggedInID === "13"
            ? JSON.stringify(["FPO_Bazaar"])
            : sortingData?.program !== ""
            ? sortingData?.program
            : "",
        state: sortingData?.state !== "" ? sortingData?.state : "",
        stateHead: sortingData?.stateHead !== "" ? sortingData?.stateHead : "",
        rmName: sortingData?.rmName !== "" ? sortingData?.rmName : "",
        startDate:
          sortingData?.startDate !== ""
            ? moment(sortingData?.startDate).format("YYYY-MM-DD")
            : "",
        endDate:
          sortingData?.endDate !== ""
            ? moment(sortingData?.endDate).format("YYYY-MM-DD")
            : "",
        page: page,
        search: sortingData?.search !== "" ? sortingData?.search : "",
      };
      if (sortingData?.program === "All") {
        body.program = "";
      }
      if (sortingData?.state === "All") {
        body.state = "";
        body.stateHead = "";
        body.rmName = "";
      }
      if (sortingData?.stateHead === "All") {
        if (sortingData?.state === "") {
          body.stateHead = "";
          body.rmName = "";
        } else if (sortingData?.state !== "") {
          body.stateHead = "";
        }
      }
      if (sortingData?.rmName === "All") {
        body.rmName = "";
      }
      if (loggedInID !== "13") {
        if (sortingData?.program?.length > 0) {
          body.program = JSON.stringify(body.program);
        } else {
          body.program = "";
        }
      }
      getDashboardBusinessFIGDetailsApi(body)
        .then((data) => {
          setFIGData(data?.data?.results);
          setFIGTabPageMeta(data?.data?.pageMeta);
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    },
    [getDashboardBusinessFIGDetailsApi, sortingData, loggedInID]
  );

  useEffect(() => {
    if (activeTab === "FIG") {
      figApiCall(1);
    }
  }, [figApiCall, activeTab]);

  const kymApiCall = useCallback(
    (page, value) => {
      setloading(true);
      let body = {
        program:
          loggedInID === "13"
            ? JSON.stringify(["FPO_Bazaar"])
            : sortingData?.program !== ""
            ? sortingData?.program
            : "",
        state: sortingData?.state !== "" ? sortingData?.state : "",
        stateHead: sortingData?.stateHead !== "" ? sortingData?.stateHead : "",
        rmName: sortingData?.rmName !== "" ? sortingData?.rmName : "",
        startDate:
          sortingData?.startDate !== ""
            ? moment(sortingData?.startDate).format("YYYY-MM-DD")
            : "",
        endDate:
          sortingData?.endDate !== ""
            ? moment(sortingData?.endDate).format("YYYY-MM-DD")
            : "",
        page: page,
        search: sortingData?.search !== "" ? sortingData?.search : "",
      };
      if (sortingData?.program === "All") {
        body.program = "";
      }
      if (sortingData?.state === "All") {
        body.state = "";
        body.stateHead = "";
        body.rmName = "";
      }
      if (sortingData?.stateHead === "All") {
        if (sortingData?.state === "") {
          body.stateHead = "";
          body.rmName = "";
        } else if (sortingData?.state !== "") {
          body.stateHead = "";
        }
      }
      if (sortingData?.rmName === "All") {
        body.rmName = "";
      }
      if (loggedInID !== "13") {
        if (sortingData?.program?.length > 0) {
          body.program = JSON.stringify(body.program);
        } else {
          body.program = "";
        }
      }
      getDashboardBusinessKYMDetailsApi(body)
        .then((data) => {
          setKYMData(data?.data?.results);
          setKYMTabPageMeta(data?.data?.pageMeta);
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    },
    [getDashboardBusinessKYMDetailsApi, sortingData, loggedInID]
  );

  useEffect(() => {
    if (activeTab === "KYM") {
      kymApiCall(1);
    }
  }, [kymApiCall, activeTab]);

  const bmsApiCall = useCallback(
    (page, value) => {
      setloading(true);
      let body = {
        program:
          loggedInID === "13"
            ? JSON.stringify(["FPO_Bazaar"])
            : sortingData?.program !== ""
            ? sortingData?.program
            : "",
        state: sortingData?.state !== "" ? sortingData?.state : "",
        stateHead: sortingData?.stateHead !== "" ? sortingData?.stateHead : "",
        rmName: sortingData?.rmName !== "" ? sortingData?.rmName : "",
        startDate:
          sortingData?.startDate !== ""
            ? moment(sortingData?.startDate).format("YYYY-MM-DD")
            : "",
        endDate:
          sortingData?.endDate !== ""
            ? moment(sortingData?.endDate).format("YYYY-MM-DD")
            : "",
        page: page,
        search: sortingData?.search !== "" ? sortingData?.search : "",
      };
      if (sortingData?.program === "All") {
        body.program = "";
      }
      if (sortingData?.state === "All") {
        body.state = "";
        body.stateHead = "";
        body.rmName = "";
      }
      if (sortingData?.stateHead === "All") {
        if (sortingData?.state === "") {
          body.stateHead = "";
          body.rmName = "";
        } else if (sortingData?.state !== "") {
          body.stateHead = "";
        }
      }
      if (sortingData?.rmName === "All") {
        body.rmName = "";
      }
      if (loggedInID !== "13") {
        if (sortingData?.program?.length > 0) {
          body.program = JSON.stringify(body.program);
        } else {
          body.program = "";
        }
      }
      getDashboardBusinessBMSDetailsApi(body)
        .then((data) => {
          setBMSData(data?.data?.results);
          setBMSTabPageMeta(data?.data?.pageMeta);
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    },
    [getDashboardBusinessBMSDetailsApi, sortingData, loggedInID]
  );

  useEffect(() => {
    if (activeTab === "BMS") {
      bmsApiCall(1);
    }
  }, [bmsApiCall, activeTab]);

  const businessDetailsApiCall = useCallback(
    (page, value) => {
      let body = {
        program:
          loggedInID === "13"
            ? JSON.stringify(["FPO_Bazaar"])
            : sortingData?.program !== ""
            ? sortingData?.program
            : "",
        state: sortingData?.state !== "" ? sortingData?.state : "",
        stateHead: sortingData?.stateHead !== "" ? sortingData?.stateHead : "",
        rmName: sortingData?.rmName !== "" ? sortingData?.rmName : "",
        startDate:
          sortingData?.startDate !== ""
            ? moment(sortingData?.startDate).format("YYYY-MM-DD")
            : "",
        endDate:
          sortingData?.endDate !== ""
            ? moment(sortingData?.endDate).format("YYYY-MM-DD")
            : "",
        page: page,
        search: sortingData?.search !== "" ? sortingData?.search : "",
      };
      if (sortingData?.program === "All") {
        body.program = "";
      }
      if (sortingData?.state === "All") {
        body.state = "";
        body.stateHead = "";
        body.rmName = "";
      }
      if (sortingData?.stateHead === "All") {
        if (sortingData?.state === "") {
          body.stateHead = "";
          body.rmName = "";
        } else if (sortingData?.state !== "") {
          body.stateHead = "";
        }
      }
      if (sortingData?.rmName === "All") {
        body.rmName = "";
      }
      if (loggedInID !== "13") {
        if (sortingData?.program?.length > 0) {
          body.program = JSON.stringify(body.program);
        } else {
          body.program = "";
        }
      }
      getDashboardBusinessDetailsApi(body).then((data) => {
        setBusinessData(data?.data);
      });
    },
    [getDashboardBusinessDetailsApi, sortingData, loggedInID]
  );

  useEffect(() => {
    if (homeActiveTab === "Business") {
      businessDetailsApiCall(1);
    }
  }, [businessDetailsApiCall, homeActiveTab]);

  const handleHomeTab = (page) => {
    homeApiCall(page);
  };

  const handleMemberTab = (page) => {
    memberApiCall(page);
  };

  const handleFIGTab = (page) => {
    figApiCall(page);
  };

  const handleKYMTab = (page) => {
    kymApiCall(page);
  };

  const handleBMSTab = (page) => {
    bmsApiCall(page);
  };

  useEffect(() => {
    if (searchData.length === 0) {
      setSearchData("");
    }
  }, [searchData]);

  const handleTab = (title) => {
    setActiveTab(title);
    // setSortingData({
    //   program: "",
    //   state: "",
    //   stateHead: "",
    //   startDate: "",
    //   endDate: "",
    // });
    // setSearchData("");
  };

  const resetFilters = () => {
    setSortingData({
      program: "",
      state: "",
      stateHead: "",
      rmName: "",
      startDate: "",
      endDate: "",
      search: "",
    });
    setSelected([]);
    getStateHead("");
    getRMListMethod("");
    setSelectedStateHead("");
  };

  const downloadCSV = () => {
    let body = {
      program:
        loggedInID === "13"
          ? JSON.stringify(["FPO_Bazaar"])
          : sortingData?.program !== ""
          ? sortingData?.program
          : "",
      state: sortingData?.state !== "" ? sortingData?.state : "",
      stateHead: sortingData?.stateHead !== "" ? sortingData?.stateHead : "",
      rmName: sortingData?.rmName !== "" ? sortingData?.rmName : "",
      startDate:
        sortingData?.startDate !== ""
          ? moment(sortingData?.startDate).format("YYYY-MM-DD")
          : "",
      endDate:
        sortingData?.endDate !== ""
          ? moment(sortingData?.endDate).format("YYYY-MM-DD")
          : "",
      search: sortingData?.search !== "" ? sortingData?.search : "",
    };
    if (sortingData?.program === "All") {
      body.program = "";
    }
    if (sortingData?.state === "All") {
      body.state = "";
      body.stateHead = "";
      body.rmName = "";
    }
    if (sortingData?.stateHead === "All") {
      if (sortingData?.state === "") {
        body.stateHead = "";
        body.rmName = "";
      } else if (sortingData?.state !== "") {
        body.stateHead = "";
      }
    }
    if (sortingData?.rmName === "All") {
      body.rmName = "";
    }
    if (loggedInID !== "13") {
      if (sortingData?.program?.length > 0) {
        body.program = JSON.stringify(body.program);
      } else {
        body.program = "";
      }
    }
    let api = "";
    let downloadName = "";
    switch (activeTab) {
      case "Home":
        api = getDownloadBusinessHomeDataApi;
        downloadName = "home";
        break;
      case "Members":
        api = getDownloadBusinessMembersDataApi;
        downloadName = "member";
        break;
      case "FIG":
        api = getDownloadBusinessFIGDataApi;
        downloadName = "fig";
        break;
      case "KYM":
        api = getDownloadBusinessKYMDataApi;
        downloadName = "kym";
        break;
      case "BMS":
        api = getDownloadBusinessBMSDataApi;
        downloadName = "bms";
        break;
      default:
        return null;
    }
    api(body)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `business-${downloadName}-report.csv`);
        document.body.appendChild(link);
        link.click();
        setKymLoading(false);
      })
      .catch(() => setKymLoading(false));
  };

  useEffect(() => {
    let prodramValue = [];
    selected.forEach((val) => prodramValue.push(val.value));
    setSortingData((prevState) => {
      return {
        ...prevState,
        program: prodramValue,
      };
    });
  }, [selected]);

  return (
    <React.Fragment>
      {kymLoading ? (
        <CommonPageLoader />
      ) : (
        <div className="dashboard-home dashboard-business">
          <div className="d-flex align-items-start justify-content-between mt-5 mb-5 flex-wrap w-100">
            {loggedInID === "13" ? null : (
              <div className="d-flex flex-column">
                <div className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20">
                  Program
                </div>
                <MultiSelect
                  options={loggedInID === "14" ? programListsOne : programLists}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                  overrideStrings={{
                    selectSomeItems: "Select",
                    allItemsAreSelected: "All",
                  }}
                />
              </div>
            )}
            <NormalSelect
              label="State"
              placeholder="Select"
              name="state"
              value={sortingData?.state}
              options={stateOptions}
              handleChange={handleInput}
            />
            {loggedInID !== "13" && (
              <NormalSelect
                label="State Head"
                placeholder="Select"
                name="stateHead"
                value={sortingData?.stateHead}
                options={stateHeadOptions}
                handleChange={handleInput}
              />
            )}
            <NormalSelect
              label="RM"
              placeholder="Select"
              name="rmName"
              value={sortingData?.rmName}
              options={rmNameOptions}
              handleChange={handleInput}
            />
            <NormalDate
              name="startDate"
              placeholder="YYYY-MM-DD"
              mindate={false}
              maxdate={false}
              timeInput={false}
              label={"Date Range"}
              value={sortingData?.startDate}
              onChange={handleInput}
              isIcon={true}
              className="date-class form-control w-100"
            />
            <NormalDate
              name="endDate"
              placeholder="YYYY-MM-DD"
              mindate={false}
              maxdate={false}
              timeInput={false}
              label={""}
              value={sortingData?.endDate}
              onChange={handleInput}
              isIcon={true}
              disabled={
                sortingData?.startDate === "" ||
                sortingData?.startDate === undefined
              }
              className="date-class form-control w-100 mt-32"
            />
          </div>
          <div className="row member-card-main justify-content-between">
            <div className="col-md-2 member-card">
              <h4>FPO</h4>
              <h2>{businessData?.fpo}</h2>
            </div>
            <div className="col-md-2 member-card">
              <h4>Member%</h4>
              <h2>{businessData?.member}</h2>
            </div>
            <div className="col-md-2 member-card">
              <h4>FIG%</h4>
              <h2>{businessData?.fig}</h2>
            </div>
            <div className="col-md-2 member-card">
              <h4>KYM%</h4>
              <h2>0</h2>
            </div>
            <div className="col-md-2 member-card">
              <h4>BMS%</h4>
              <h2>0</h2>
            </div>
          </div>
          <div className="reset-button mt-4">
            <NormalButton
              label="Reset"
              mainbg={true}
              onClick={() => {
                resetFilters();
              }}
            />
          </div>
          <div className="reset-button mt-4">
            <NormalButton
              label="Download Report"
              mainbg={true}
              onClick={() => downloadCSV("report")}
            />
          </div>
          <div className="inside-tab">
            <div className="common-navlink">
              <div className="filled-tabs">
                <div className="tabs-block">
                  <Nav tabs>
                    {Titles.map((item, index) => {
                      return (
                        <NavItem>
                          <NavLink
                            key={index}
                            className={classnames({
                              active: activeTab === item?.title,
                            })}
                            onClick={() => {
                              handleTab(item?.title);
                            }}
                          >
                            {item?.title}
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                </div>
              </div>
            </div>
            <InputSearch
              placeholder={"Search by FPO"}
              value={sortingData?.search}
              onChange={handleInput}
              name="search"
            />
          </div>
          <div className="dashboard">
            {loading ? (
              <CommonPageLoader />
            ) : (
              <TabContent activeTab={activeTab}>
                <TabPane className="homeTab" tabId="Home">
                  {homeData?.length > 0 ? (
                    <TableWrapper
                      headerDetails={homeTabHeader}
                      pageMeta={homeTabPageMeta}
                      queryHandler={handleHomeTab}
                      toolTip={true}
                    >
                      {homeData?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{item?.fpoId}</td>
                              <td>{item?.fpoName}</td>
                              <td align="center">
                                {item?.grading?.isGrading ? (
                                  <img src={tickIcon} alt="tickIcon" />
                                ) : (
                                  <img src={closeIcon} alt="closeIcon" />
                                )}
                                <br />
                                {item?.grading?.isGrading
                                  ? `(${moment(item?.grading?.createdAt).format(
                                      "DD-MM-YYYY"
                                    )})`
                                  : ""}
                              </td>
                              <td align="center">
                                {item?.engagement?.isEngagement ? (
                                  <img src={tickIcon} alt="tickIcon" />
                                ) : (
                                  <img src={closeIcon} alt="closeIcon" />
                                )}
                                <br />
                                {item?.engagement?.isEngagement
                                  ? `(${moment(
                                      item?.engagement?.createdAt
                                    ).format("DD-MM-YYYY")})`
                                  : ""}
                              </td>
                              <td
                                className={item?.member > 100 ? "red-bg" : ""}
                              >
                                {item?.member}%
                              </td>
                              <td className={item?.fig > 100 ? "red-bg" : ""}>
                                {item?.fig}%
                              </td>
                              <td>{item?.kym}%</td>
                              <td>0</td>
                            </tr>
                            <tr className="trans-bg">
                              <td className="empty-height" height="12"></td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </TableWrapper>
                  ) : (
                    <div className="text-center fs-18 text-black py-5">
                      No records found !!!
                    </div>
                  )}
                </TabPane>
                <TabPane className="memberTab" tabId="Members">
                  {memberData?.length > 0 ? (
                    <TableWrapper
                      headerDetails={memberTabHeader}
                      pageMeta={memberTabPageMeta}
                      queryHandler={handleMemberTab}
                      toolTip={true}
                    >
                      {memberData?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{item?.fpoId}</td>
                              <td>{item?.fpoName}</td>
                              <td>{item?.memberShipBase}</td>
                              <td>{item?.membersuploaded}</td>
                              <td>{item?.memberWithNVP}</td>
                              <td
                                className={
                                  item?.memberPercentage > 100 ? "red-bg" : ""
                                }
                              >
                                {item?.memberPercentage}%
                              </td>
                            </tr>
                            <tr className="trans-bg">
                              <td className="empty-height" height="12"></td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </TableWrapper>
                  ) : (
                    <div className="text-center fs-18 text-black py-5">
                      No records found !!!
                    </div>
                  )}
                </TabPane>
                <TabPane className="figTab" tabId="FIG">
                  {figData?.length > 0 ? (
                    <TableWrapper
                      headerDetails={figTabHeader}
                      pageMeta={figTabPageMeta}
                      queryHandler={handleFIGTab}
                      toolTip={true}
                    >
                      {figData?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{item?.fpoId}</td>
                              <td>{item?.fpoName}</td>
                              <td>{item?.memberShipBase}</td>
                              <td>{item?.figUploaded}</td>
                              <td>{item?.figMemberMapping}</td>
                              <td
                                className={
                                  item?.figPercentage > 100 ? "red-bg" : ""
                                }
                              >
                                {item?.figPercentage}%
                              </td>
                            </tr>
                            <tr className="trans-bg">
                              <td className="empty-height" height="12"></td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </TableWrapper>
                  ) : (
                    <div className="text-center fs-18 text-black py-5">
                      No records found !!!
                    </div>
                  )}
                </TabPane>
                <TabPane className="kymTab" tabId="KYM">
                  {kymData?.length > 0 ? (
                    <TableWrapper
                      headerDetails={kymTabHeader}
                      pageMeta={kymTabPageMeta}
                      queryHandler={handleKYMTab}
                      toolTip={true}
                    >
                      {kymData?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{item?.fpoId}</td>
                              <td>{item?.fpoName}</td>
                              <td>{item?.memberShipBase}</td>
                              <td>{item?.kymsDone}</td>
                              <td>{item?.uploadedFields}</td>
                              <td>{item?.requiredFields}</td>
                              <td
                                className={
                                  item?.kymPercentage > 100 ? "red-bg" : ""
                                }
                              >
                                {item?.kymPercentage}%
                              </td>
                              <td
                                className={
                                  item?.kymsYetToBeStarted < 0 ? "red-bg" : ""
                                }
                              >
                                {item?.kymsYetToBeStarted}
                              </td>
                            </tr>
                            <tr className="trans-bg">
                              <td className="empty-height" height="12"></td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </TableWrapper>
                  ) : (
                    <div className="text-center fs-18 text-black py-5">
                      No records found !!!
                    </div>
                  )}
                </TabPane>
                <TabPane className="bmsTab" tabId="BMS">
                  {bmsData?.length > 0 ? (
                    <TableWrapper
                      headerDetails={bmsTabHeader}
                      pageMeta={bmsTabPageMeta}
                      queryHandler={handleBMSTab}
                    >
                      {bmsData?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{item?.fpoId}</td>
                              <td>{item?.fpoName}</td>
                              <td align="center">
                                {item?.setupDone === 0 ? (
                                  <img src={tickIcon} alt="closeIcon" />
                                ) : (
                                  <img src={closeIcon} alt="tickIcon" />
                                )}
                              </td>
                              <td>
                                {numberFormatChange(item?.totalPurchaseAmount)}{" "}
                                ({item?.totalPurchaseCount})
                              </td>
                              <td>
                                {numberFormatChange(item?.totalSaleAmount)} (
                                {item?.totalSaleCount})
                              </td>
                              <td>
                                {numberFormatChange(
                                  item?.totalOtherTransactionAmount
                                )}{" "}
                                ({item?.totalOtherTransactionCount})
                              </td>
                            </tr>
                            <tr className="trans-bg">
                              <td className="empty-height" height="12"></td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </TableWrapper>
                  ) : (
                    <div className="text-center fs-18 text-black py-5">
                      No records found !!!
                    </div>
                  )}
                </TabPane>
              </TabContent>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      allStateApi: allState,
      getDistrictApiwithStateApi: getDistrictApiwithState,
      getDashboardBusinessHomeDetailsApi: getDashboardBusinessHomeDetails,
      getDashboardBusinessMemberDetailsApi: getDashboardBusinessMemberDetails,
      getDashboardBusinessFIGDetailsApi: getDashboardBusinessFIGDetails,
      getDashboardBusinessDetailsApi: getDashboardBusinessDetails,
      getDashboardBusinessStateHeadDetailsApi:
        getDashboardBusinessStateHeadDetails,
      getgetRMListDetailsApi: getgetRMListDetails,
      getDashboardBusinessKYMDetailsApi: getDashboardBusinessKYMDetails,
      getDashboardBusinessBMSDetailsApi: getDashboardBusinessBMSDetails,
      getDownloadBusinessHomeDataApi: getDownloadBusinessHomeData,
      getDownloadBusinessMembersDataApi: getDownloadBusinessMembersData,
      getDownloadBusinessFIGDataApi: getDownloadBusinessFIGData,
      getDownloadBusinessKYMDataApi: getDownloadBusinessKYMData,
      getDownloadBusinessBMSDataApi: getDownloadBusinessBMSData,
    },
    dispatch
  );
};

let component = DashboardBusinessClass;
export const DashboardBusiness = connect(
  null,
  mapDispatchToProps
)(withRouter(component));
