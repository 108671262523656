import React, { Component } from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { fileUpload } from "action/CourseAct";

import {
  NormalInput,
  NormalDate,
  NormalButton,
  DragFileUploadForCompliance,
  CommonPageLoader,
} from "component/common";

import { history } from "service/helpers";

import SimpleReactValidator from "simple-react-validator";

import { ComplianceCalender } from "../ComplianceCalender";

import "./style.scss";

import moment from "moment";

import { Toast } from "service/toast";

import {
  getComplianceTaskById,
  updateFpoTask,
  addFpoTask,
} from "action/ComplianceCalenderAct";

export class ComplianceCalenderAddClass extends Component {
  state = {
    formFields: {
      date: "",
      service: "",
      supportingLink: "",
      uploadExcel: "",
      uploadExcelFileName: "",
    },
    isImageUploading: false,
    isLoading: false,
  };

  //Validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-16 pb-3">{message}</span>
    ),
  });

  //Lifecycles
  componentDidMount() {
    let { id } = this.props;
    if (id) {
      this._getFpoTaskDetails(id);
    }
  }

  _getFpoTaskDetails = (id) => {
    //Get Task Detials
    this.setState({ isLoading: true });
    this.props.getComplianceTaskById(id).then((data) => {
      this.setState({
        formFields: {
          date: new Date(data.dueDate),
          service: data.taskName,
          supportingLink:
            data.supportingLink !== null ? data.supportingLink : "",
          uploadExcel: data.fileUrl !== null ? data.fileUrl : "",
          uploadExcelFileName: data.fileName !== null ? data.fileName : "",
        },
        isLoading: false,
      });
    });
  };

  handleInput = ({ target: { name, value } }) => {
    //handle inputs

    let { formFields } = this.state;

    formFields[name] = value;

    this.setState({
      formFields,
    });
  };

  handleSubmit = () => {
    let { formFields, isImageUploading } = this.state;

    let {
      date,
      service,
      supportingLink,
      uploadExcel,
      uploadExcelFileName,
    } = formFields;

    let { id } = this.props;

    if (this.validator.allValid()) {
      if (!isImageUploading) {
        if (uploadExcel !== "" && supportingLink !== "") {
          Toast({
            type: "error",
            message: "Please enter either supporting link or upload file",
          });
        } else if (uploadExcel === "" && supportingLink === "") {
          Toast({
            type: "error",
            message: "Please enter supporting link or upload file",
          });
        } else {
          this.setState({ isLoading: true });
          let body = {
            taskName: service,
            dueDate: moment(date).format("YYYY-MM-DD"),
            fpoTasksId: "",
            supportingLink: supportingLink !== "" ? supportingLink : null,
            fileName: uploadExcelFileName !== "" ? uploadExcelFileName : null,
            fileUrl: uploadExcel !== "" ? uploadExcel : null,
          };
          if (id) {
            body.fpoTasksId = id;
            this.props.updateFpoTask(body).then(() => {
              history.push("/admin/compliance-calender");
              this.setState({ isLoading: false });
            });
          } else {
            delete body.fpoTasksId;
            this.props.addFpoTask(body).then(() => {
              history.push("/admin/compliance-calender");
              this.setState({ isLoading: false });
            });
          }
        }
      } else {
        Toast({ type: "error", message: "Please wait file is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleFileUpload = ({ target: { name, value } }) => {
    //upload file
    let { formFields } = this.state;
    if (value !== undefined) {
      //uploading loader
      this.setState({ isImageUploading: true });

      formFields.uploadExcel = "";
      formFields.uploadExcelFileName = "";

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            "video-upload"
          ).innerText = `Uploading ${percentCompleted}%`;
        },
      };

      const formData = new FormData();
      formData.append("file", value);

      this.props.fileUpload(formData, config).then((data) => {
        if (data !== undefined) {
          formFields.uploadExcel = data[0].fullUrl;
          formFields.uploadExcelFileName = data[0].fileName;
          this.setState({
            formFields,
            isImageUploading: false,
          });
        } else {
          formFields.uploadExcel = "";
          formFields.uploadExcelFileName = "";
          this.setState({
            formFields,
            isImageUploading: false,
          });
        }
      });
    }
  };

  handleRemoveFile = () => {
    let { formFields } = this.state;
    formFields.uploadExcel = "";
    formFields.uploadExcelFileName = "";
    this.setState({
      formFields,
    });
  };

  render() {
    let { formFields, isLoading, isImageUploading } = this.state;

    let {
      date,
      service,
      supportingLink,
      uploadExcel,
      uploadExcelFileName,
    } = formFields;

    let { id } = this.props;

    return (
      <div className="compliance-calender">
        <div
          className="d-flex cursor-pointer pt-2 mt-1 mb-4 back-area"
          onClick={() => history.goBack()}
        >
          <i className="icon-arrow-left fs-24"></i>
          <h5 className="heading-20 pl-4">{id ? "Update" : "Create"} task</h5>
        </div>
        <div className="compliance-calender-form">
          {isLoading ? (
            <CommonPageLoader />
          ) : (
            <div className="row">
              <div className="col-7">
                <h5 className="fs-19 text-black font-Gilroy-Bold line-height-22 mb-0 mt-2">
                  {"Details"}
                </h5>
                <div className="row">
                  <div className="col-12">
                    <NormalDate
                      value={date}
                      name="date"
                      placeholder="DD/MM/YYYY"
                      onChange={this.handleInput}
                      mindate={false}
                      maxdate={false}
                      timeInput={false}
                      label={"Date"}
                      required={true}
                    />
                    {this.validator.message("date", date, "required")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <NormalInput
                      label="Service"
                      type="text"
                      onChange={(e) => this.handleInput(e)}
                      value={service}
                      placeholder="Enter here"
                      name="service"
                      required={true}
                    />
                    {this.validator.message(
                      "service",
                      service,
                      "required|max:50"
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <NormalInput
                      label="Supporting Link"
                      type="text"
                      onChange={(e) => this.handleInput(e)}
                      value={supportingLink}
                      placeholder="Enter here"
                      name="supportingLink"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p className="fs-17 line-height-20 text-black text-center py-4 my-1">
                      Or
                    </p>
                  </div>
                </div>
                <div className="row excel-upload-area">
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20">
                          {"Upload file"}
                        </p>
                      </div>
                      {uploadExcel !== "" ? (
                        <div>
                          <p
                            className="fs-16 text-orange cursor-pointer fw-500 pb-2 mb-1 line-height-20"
                            onClick={() => this.handleRemoveFile()}
                          >
                            {"Remove file"}
                            <i className="icon-close ml-2 fs-12"></i>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="excel-container d-flex justify-content-center align-items-center">
                      <DragFileUploadForCompliance
                        className="form-control dotted-input d-flex align-items-center justify-content-center"
                        value={uploadExcel}
                        name="imageURL"
                        children={
                          isImageUploading ? (
                            <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100">
                              <div id="video-upload"></div>
                            </div>
                          ) : uploadExcel !== "" ? (
                            uploadExcelFileName
                          ) : (
                            "Drag & Drop or upload file"
                          )
                        }
                        handleFileUpload={([file]) =>
                          this.handleFileUpload({
                            target: {
                              name: "video",
                              value: file,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <ComplianceCalender date={date ? [date] : []} />
              </div>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center add-task-buttons pb-4">
          <div className="ml-auto d-flex">
            <NormalButton
              label="Cancel"
              outline={true}
              className="px-4 mr-2 border-none"
              onClick={() => history.goBack()}
            />
            <NormalButton
              label={id ? "Update" : "Publish"}
              mainbg={true}
              className="px-4"
              onClick={this.handleSubmit.bind(this, true)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFpoTask,
      addFpoTask,
      getComplianceTaskById,
      fileUpload,
    },
    dispatch
  );
};

let component = ComplianceCalenderAddClass;

export const ComplianceCalenderAdd = connect(
  null,
  mapDispatchToProps
)(component);
