import { dashboard } from "service/apiVariables";

import { addQuery } from "service/helperFunctions";

// Get list of Faq
export const getRequestFormList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getRequestFormList);

    return new Promise((resolve, reject) => {
      api({ ...dashboard.getRequestFormList })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

// Request form reject user
export const requestFormRejectUser =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...dashboard.rejectUser, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//request Form Accept User
export const requestFormAcceptUser =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...dashboard.acceptUser, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Dashboard Details

// export const getDashboardDetails =
//   () =>
//   (dispatch, getState, { api, Toast }) => {
//     return new Promise((resolve, reject) => {
//       api({ ...dashboard.getDashboardDetails })
//         .then((data) => {
//           resolve(data);
//         })
//         .catch(({ message }) => {
//           reject(Toast({ type: "error", message }));
//         });
//     });
//   };

export const getDashboardDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getDashboardDetails);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getDashboardDetails })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDashboardGraphDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getDashboardGraphDetails);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getDashboardGraphDetails })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDashboardBusinessHomeDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getDashboardBusinessHome);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getDashboardBusinessHome })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDashboardBusinessMemberDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getDashboardBusinessMember);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getDashboardBusinessMember })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDashboardBusinessFIGDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getDashboardBusinessFIG);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getDashboardBusinessFIG })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDashboardBusinessKYMDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getDashboardBusinessKYM);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getDashboardBusinessKYM })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDashboardBusinessBMSDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getDashboardBusinessBMS);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getDashboardBusinessBMS })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDashboardBusinessDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getDashboardBusiness);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getDashboardBusiness })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDashboardBusinessStateHeadDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getDashboardBusinessStateHead);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getDashboardBusinessStateHead })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getgetRMListDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getRMList);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getRMList })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDownloadBusinessHomeData =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.downloadBusinessHomeData);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.downloadBusinessHomeData })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDownloadBusinessMembersData =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.downloadBusinessMembersData);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.downloadBusinessMembersData })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDownloadBusinessFIGData =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.downloadBusinessFIGData);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.downloadBusinessFIGData })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDownloadBusinessKYMData =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.downloadBusinessKYMData);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.downloadBusinessKYMData })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getDownloadBusinessBMSData =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.downloadBusinessBMSData);
    return new Promise((resolve, reject) => {
      api({ ...dashboard.downloadBusinessBMSData })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Dashboard Details for Acadamy

export const getDashboardDetailsforAcadamy =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getDashboardDetailsforAcadamy);

    return new Promise((resolve, reject) => {
      api({ ...dashboard.getDashboardDetailsforAcadamy })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Dashboard Details for user

export const getDashboardDetailsforuser =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getDashboardDetailsforuser })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Top Performance Of FPO
export const getTopPerformanceOfFPO =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getTopPerformanceOfFPO })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getRoboCallRequestCount =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...dashboard.getRoboCallRequestCount })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getRoboCallRequestList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, dashboard.getRoboCallRequestList);

    return new Promise((resolve, reject) => {
      api({ ...dashboard.getRoboCallRequestList })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//request Form Accept User
export const acceptRoboCall =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...dashboard.acceptRoboCall, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//request Form Accept User
export const rejectRoboCall =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...dashboard.rejectRoboCall, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
