import React, { Component } from 'react';
import "./ViewCourseDetails.scss"
import { connect } from 'react-redux'
import { NormalButton } from 'component/common';
import certifyingImage from "assets/svg/certifyingImage.svg"
import { history } from "service/helpers"

export class ViewCourseDetailsClass extends Component {
  state = {
    lessonList: [
      { lessonName: 'Introduction', time: '3: 03' },
      { lessonName: 'Introduction', time: '3: 03' },
      { lessonName: 'Introduction', time: '3: 03' },
      { lessonName: 'Introduction', time: '3: 03' },
      { lessonName: 'Introduction', time: '3: 03' },
      { lessonName: 'Introduction', time: '3: 03' },
      { lessonName: 'Introduction', time: '3: 03' },
    ],
    blogdetails: [
      { blogName: 'Lorem ipsum', blogContent: 'Ea nulla minim do ut laboris officia ut pariatur' },
      { blogName: 'Lorem ipsum', blogContent: 'Ea nulla minim do ut laboris officia ut pariatur' },
      { blogName: 'Lorem ipsum', blogContent: 'Ea nulla minim do ut laboris officia ut pariatur' }
    ]
  };

  render() {

    let { lessonList, blogdetails } = this.state
    let userName = "Sharma"

    return (
      <>
        <div className="course-management pl-2">
          <div className="d-flex justify-content-between align-items-center view-course-management-heading">
            <div className="d-flex cursor-pointe back-arear" onClick={() => history.goBack()}>
              <i className="icon-arrow-left fs-24"></i>
              <h5 className="heading-20 ml-1 pl-3">Course details</h5>
            </div>
            <div className="">
              <NormalButton label="Edit" outline={true} className="px-4" />
            </div>
          </div>
          <div className="d-flex">
            <div className="col-8 pl-0 pr-1">
              <video width="100%" height="433" controls>
                <source src="mov_bbb.mp4" type="video/mp4" />
                <source src="mov_bbb.ogg" type="video/ogg" />
                    Your browser does not support HTML video.
              </video>
              <div className="d-flex mt-2 mb-3">
                <div className="col-9 px-0">
                  <h5 className="course-head mb-2 fs-20">Labore nostrud Lorem.</h5>
                  <p className="mb-0 course-subtext fs-16">Ea nulla minim do ut laboris officia ut pariatur ipsum. Ipsum aute sit minim exercitation pariatur commodo aliquip esse cillum minim deserunt labore</p>
                </div>
                <div className="col-3 d-flex flex-column justify-content-center">
                  <img src={certifyingImage} alt="image" className="certifying-image mb-2" />
                  <h5 className="text-black font-Gilroy-Medium fs-14 text-center">Certifying Institute</h5>
                </div>
              </div>
              <div className="py-2 d-flex align-items-center border-bottom-c4">
                <p className="mb-0 user-picture">{userName.charAt(0)}</p>
                <h5 className="pl-3 mb-0 fs-16 text-black font-Gilroy-Medium">{userName}</h5>
              </div>
              <div className="course-benifits">
                <h4 className="fs-20 mb-2 font-Gilroy-Bold">Benefits</h4>
                <p className="font-Gilroy-Medium text-black-50 line-height-27">Ea nulla minim do ut laboris officia ut pariatur ipsum. Ipsum aute sit minim exercitation pariatur commodo aliquip esse cillum minim deserunt labore nostrud Lorem.</p>
              </div>
            </div>
            <div className="col-4 pl-3 pr-0">
              <div className="course-detail-card">
                <h5 className="text-black font-Gilroy-Bold fs-20 pl-3 ml-1 pb-3 mb-2">9 lessons . 5 hours</h5>
                {lessonList.map((list) => (
                  <div className="d-flex course-detail-list justify-content-between w-100 align-items-center cursor-pointer">
                    <div className="d-flex align-items-center h-100">
                      <span className="mb-0 border-circle mx-4"></span>
                      <p className="mb-0 text-black-50 font-Gilroy-Medium fs-16">
                        {list.lessonName}
                      </p>
                    </div>
                    <p className="mb-0 pr-4 mr-1 text-black font-Gilroy-Medium fs-16">{list.time}</p>
                  </div>
                ))}
              </div>
              <div className="course-blog">
                <h5 className="text-black font-Gilroy-Bold fs-20 pb-3">Related Blogs</h5>
                {blogdetails.map((list) => (
                  <div className="d-flex mb-2">
                    <video width="180" height="100" controls>
                      <source src="mov_bbb.mp4" type="video/mp4" />
                      <source src="mov_bbb.ogg" type="video/ogg" />
                     Your browser does not support HTML video.
                   </video>
                    <div className="pl-3 ml-1 d-flex flex-column justify-content-end">
                      <h6 className="mb-1 font-Gilroy-Bold text-grey fs-20">{list.blogName}</h6>
                      <p className="mb-2 fs-14 text-grey font-Gilroy">{list.blogContent}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

let component = ViewCourseDetailsClass;

export const ViewCourseDetails = connect(null, null)(component)