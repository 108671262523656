import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import ViewMappedFPO from "./ViewMappedFPO";
import MapFPO from "./MapFPO";
import "./style.scss";

const tabDetails = [
  { title: "Map FPO", id: "1" },
  { title: "View mapped FPOs", id: "2" },
];

const RMFPOMapping = () => {
  const [activeTab, setActiveTab] = useState("1");

  return (
    <div className="role-list">
      <div className="d-flex justify-content-center mb-2">
        <div className="scroll-x">
          <Nav className="tab-sammunaty">
            {tabDetails.map(({ title, id }, index) => (
              <NavItem key={index}>
                <NavLink
                  className={(classnames({ active: activeTab === id }), "p-0")}
                  onClick={() => setActiveTab(id)}
                >
                  <button className={`btn ${activeTab === id ? "active" : ""}`}>
                    {title}
                  </button>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <MapFPO />
        </TabPane>
        <TabPane tabId="2">
          <ViewMappedFPO />
        </TabPane>
      </TabContent>
    </div>
  );
};

export const RoleManagementList = RMFPOMapping;
